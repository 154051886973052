import { getAuth, OAuthProvider, signInWithPopup } from 'firebase/auth'
import { useRecoilState } from 'recoil'
import { useEffect } from 'react'
import { useLocation, useNavigate, Location } from 'react-router-dom'
import { fbUserObjectState } from '../../../store/store'
import firebaseApp from '../../../firebase'
import { MUIComponents } from '../../muiComponents'
import headerLogo from '../../../assets/headerLogo.png';

interface LocationState extends Location {
  state: {
    from: {
      pathname: string
      search: string
    }
  }
}

const Login = () => {
  const [fbUserObject, setFbUserObject] = useRecoilState(fbUserObjectState)

  const navigate = useNavigate()
  const location = useLocation()
  const originalUrlParams =
    (location as LocationState).state?.from?.search || ''
  const originalLocation =
    (location as LocationState).state?.from?.pathname + originalUrlParams || '/'

  useEffect(() => {
    if (fbUserObject?.uid) {
      navigate(originalLocation, { replace: true })
    }
  }, [fbUserObject])

  const handleLogin = () => {
    //Firebase auth provider setup
    const authProvider = new OAuthProvider('microsoft.com')
    authProvider.setCustomParameters({
      prompt: 'consent',
      tenant: '96bfc52f-3442-469b-8fd4-452845d29552',
    })

    //Auth via popup
    const auth = getAuth(firebaseApp)
    signInWithPopup(auth, authProvider)
      .then(async (res) => {
        //const credential = OAuthProvider.credentialFromResult(res); //These are the azure credentials if needed
        console.log('user is', res)
        // setFbUserObject(res.user)
        setFbUserObject({ ...res.user, accessToken: await res.user.getIdToken(true) })
      })
      .catch((err) => {
        //Temp
        console.log(err)
      })
  }

  return (
    <div style={{ width: "100%", height: "100vh", backgroundColor: "#E2EAEC" }}>
      <div style={{ paddingTop: "14rem", paddingBottom: "1.5rem" }}>
        <img src={headerLogo} style={{ width: 225 }} alt="Logo" />
      </div>
      <MUIComponents.Buttons.Button
        id="login"
        variant="text"
        onClick={handleLogin}
        style={{ border: "1.5px solid rgb(5, 81, 96)", width: 112 }}
      >
        Aanmelden
      </MUIComponents.Buttons.Button>
    </div>
  )
}

export default Login