import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table'
import MuiTableBody from '@mui/material/TableBody'
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@mui/material/TableCell'
import MuiTableContainer from '@mui/material/TableContainer'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow'
import MuiTableSortLabel, {
    TableSortLabelProps as MuiTableSortLabelProps
} from '@mui/material/TableSortLabel'
import { useEffect, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { MUIComponents } from '.'
import { noop } from '../../utils/utils'
import { PaginationProps } from './Pagination'
import useComponentVisible from '../../hooks/useComponentVisible'
import { AlertProps, LinearProgressProps } from './types'

type RowData = Record<string, string | number>

const descendingComparator = (a: RowData, b: RowData, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

const getComparator = (order: MuiTableSortLabelProps['direction'], orderBy: string) =>
    order === 'desc'
        ? (a: RowData, b: RowData) => descendingComparator(a, b, orderBy)
        : (a: RowData, b: RowData) => -descendingComparator(a, b, orderBy)

// you can declare custom props in here
interface TableProps extends MuiTableProps {
    selection?: MuiTableRowProps['selected']
    hover?: MuiTableRowProps['hover'] | string
    setPagination?: (pagination: { page: PaginationProps['page']; size: number }) => void
    onChangeSelection?: (selection: (number | string)[]) => void
    pagination?: PaginationProps
    rows: RowData[]
    columns: {
        customComponent?: (row: RowData) => JSX.Element
        dataKey: string
        extraStyling?: CSSProperties
        align?: MuiTableCellProps['align']
        numeric?: boolean
        disablePadding?: boolean
    }[]
    loading: boolean
    alert?: AlertProps
    linearLoaderColor?: LinearProgressProps['color']
    tableHeaders: {
        sorting?: {
            sort: boolean
            sortKey: string
        }
        title: string
        extraStyling?: CSSProperties
        align?: MuiTableCellProps['align']
        numeric?: boolean
        disablePadding?: boolean
        filtering?: {
            filter: boolean
            filterKey: string
        }
    }[]
    showPagination?: boolean
    headerStyling?: CSSProperties
}

export const Table = ({
    loading = false,
    selection = false,
    setPagination = noop,
    onChangeSelection = noop,
    tableHeaders = [],
    showPagination = true,
    linearLoaderColor = 'secondary',
    alert = {},
    // ! IMPORTANT: deze default headerStyling is specifiek voor F&F Table header styling, bij de boilerplate Table wrapper is dit default een leeg object
    headerStyling = { backgroundColor: 'rgba(20, 88, 100, 0.5) !important' },
    ...props
}: TableProps) => {
    const [localSortConfig, setLocalSortConfig] = useState<{
        sortBy: string
        direction: MuiTableSortLabelProps['direction']
    }>({
        sortBy: '',
        direction: undefined
    })
    const [selected, setSelected] = useState<(number | string)[]>([])
    const [filters, setFilters] = useState<{
        [key: string]: {
            filtering?: boolean
            label?: string
            key?: string
            value?: string
        }
    }>({})
    const [activeFilter, setActiveFilter] = useState<string | undefined>(undefined)
    const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    const handleSort = (colTitle: string) => {
        if (colTitle === localSortConfig.sortBy) {
            if (localSortConfig.direction === 'asc')
                setLocalSortConfig({ ...localSortConfig, direction: 'desc' })
            else setLocalSortConfig({ sortBy: '', direction: 'asc' })
        } else {
            setLocalSortConfig({ sortBy: colTitle, direction: 'asc' })
        }
    }

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (event.target.checked ?? checked) {
            const newSelected = props.rows.map((n) => n.id)
            setSelected(newSelected)
            onChangeSelection(newSelected)
            return
        }
        setSelected([])
        onChangeSelection([])
    }

    const isSelected = (id: string) => selected.includes(id)

    useEffect(() => {
        tableHeaders.forEach((header) => {
            if (header.filtering?.filter) {
                setFilters((oldFilters) => ({
                    ...oldFilters,
                    [header.filtering?.filterKey as string]: {
                        key: header.filtering?.filterKey,
                        value: undefined,
                        filtering: false,
                        label: header.title
                    }
                }))
            }
            return
        })
    }, [])

    const columnLength = props.columns.length ?? 0

    return (
        <>
            <StyledTableContainer>
                <StyledTable {...props} aria-label="simple table with sorting and pagination">
                    <StyledTableHead id="TableHeader">
                        <StyledTableRowHeader sx={headerStyling}>
                            {selection && (
                                <StyledTableCell
                                    style={{ borderTopLeftRadius: 25 }}
                                    padding="checkbox"
                                >
                                    <MUIComponents.Checkbox
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < props.rows.length
                                        }
                                        checked={
                                            props.rows.length > 0 &&
                                            selected.length === props.rows.length
                                        }
                                        onChange={handleSelectAll}
                                        inputProps={{
                                            'aria-label': 'select all desserts'
                                        }}
                                        colorToOverwrite="white"
                                    />
                                </StyledTableCell>
                            )}
                            {tableHeaders.map((header) => (
                                <StyledTableCell
                                    key={header.title}
                                    align={header.align ?? header.numeric ? 'right' : 'left'}
                                    padding={header.disablePadding ? 'none' : 'normal'}
                                    header="true"
                                    {...(header.extraStyling && { style: header.extraStyling })}
                                >
                                    <div>
                                        {header.filtering?.filter && (
                                            <MUIComponents.Badge
                                                color={
                                                    filters[header.filtering?.filterKey]?.filtering
                                                        ? 'success'
                                                        : 'error'
                                                }
                                                variant="dot"
                                            >
                                                <MUIComponents.Buttons.IconButton
                                                    onClick={(e) => {
                                                        console.log('e is', e)
                                                        setCoordinates({
                                                            x: e.clientX,
                                                            y: e.clientY
                                                        })

                                                        setIsComponentVisible(true)

                                                        setActiveFilter(header.filtering?.filterKey)
                                                    }}
                                                    sx={{
                                                        marginRight: '5px',
                                                        color: 'white'
                                                    }}
                                                    id={`FilterButton_${header.filtering?.filterKey}`}
                                                >
                                                    <MUIComponents.Icons.FilterListIcon
                                                        sx={{
                                                            width: '15px',
                                                            height: '15px'
                                                        }}
                                                    />
                                                </MUIComponents.Buttons.IconButton>
                                            </MUIComponents.Badge>
                                        )}

                                        {header.sorting?.sort ? (
                                            <MuiTableSortLabel
                                                active={
                                                    localSortConfig.sortBy ===
                                                    header.sorting?.sortKey
                                                }
                                                direction={localSortConfig.direction}
                                                onClick={() =>
                                                    handleSort(header.sorting?.sortKey ?? '')
                                                }
                                            >
                                                {header.title}
                                            </MuiTableSortLabel>
                                        ) : (
                                            header.title
                                        )}
                                    </div>
                                </StyledTableCell>
                            ))}
                        </StyledTableRowHeader>
                    </StyledTableHead>
                    <StyledTableBody>
                        {loading && (
                            <StyledTableRow>
                                <StyledTableCell
                                    colSpan={selection ? columnLength + 1 : columnLength}
                                    padding="none"
                                >
                                    <MUIComponents.LinearLoader color={linearLoaderColor} />
                                </StyledTableCell>
                            </StyledTableRow>
                        )}

                        {props.rows.length > 0 ? (
                            props.rows
                                .slice()
                                .sort(
                                    getComparator(localSortConfig.direction, localSortConfig.sortBy)
                                )
                                .filter((row) => {
                                    if (
                                        filters[activeFilter as string]?.key &&
                                        filters[activeFilter as string]?.value &&
                                        filters[activeFilter as string]?.filtering
                                    ) {
                                        return String(
                                            row[filters[activeFilter as string].key as string]
                                        )
                                            .toLowerCase()
                                            .includes(
                                                filters[
                                                    activeFilter as string
                                                ].value?.toLowerCase() as string
                                            )
                                    }
                                    return row
                                })
                                .map((row, rowIndex) => {
                                    const isItemSelected = isSelected(String(row.id))

                                    return (
                                        <StyledTableRow
                                            hover={Boolean(props.hover)}
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                            key={rowIndex}
                                            onClick={() => {
                                                const newlySelected = selected.includes(row.id)
                                                    ? selected.filter(
                                                        (selection) => selection !== row.id
                                                    )
                                                    : selected.concat(row.id)

                                                onChangeSelection(newlySelected)
                                                setSelected(newlySelected)
                                            }}
                                        >
                                            {selection && (
                                                <StyledTableCell padding="checkbox">
                                                    <MUIComponents.Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-label': 'select all desserts'
                                                        }}
                                                    />
                                                </StyledTableCell>
                                            )}

                                            {props.columns.map((column, columnIndex) => (
                                                <StyledTableCell
                                                    style={column.extraStyling}
                                                    key={columnIndex}
                                                    align={
                                                        column.align ?? column.numeric
                                                            ? 'right'
                                                            : 'left'
                                                    }
                                                    padding={
                                                        column.disablePadding ? 'none' : 'normal'
                                                    }
                                                >
                                                    {column.customComponent
                                                        ? column.customComponent(row)
                                                        : row[column.dataKey]}
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    )
                                })
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={selection ? columnLength + 1 : columnLength} style={{ height: 150 }}>
                                    <MUIComponents.Alert open={true} {...alert} />
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </StyledTableBody>
                </StyledTable>
            </StyledTableContainer>
            {showPagination && (
                <MUIComponents.Pagination
                    count={props.pagination?.count}
                    page={props.pagination?.page}
                    onChange={(e, page) => {
                        console.log('page change is', page)
                        setPagination({ page, size: 15 })
                    }}
                    style={{
                        width: 'fit-content',
                        margin: 'auto',
                        marginBottom: 25,
                        marginTop: 15
                    }}
                    shape="rounded"
                    {...props.pagination}
                />
            )}
            <div ref={ref}>
                {isComponentVisible && (
                    <MUIComponents.Styling.Paper
                        sx={{
                            width: '180px',
                            position: 'absolute',
                            top: `${coordinates.y + 33}px`,
                            left: `${coordinates.x}px`,
                            padding: '10px',
                            zIndex: 1
                        }}
                        id="filterModal"
                    >
                        <MUIComponents.TextFields.TextField
                            id="filterModal"
                            label={`Filter on ${filters[activeFilter as string]?.label ?? '...'}`}
                            name="filterValue"
                            size="small"
                            autoFocus={false}
                            required={false}
                            margin="dense"
                            value={filters[activeFilter as string]?.value}
                            InputProps={{ style: { fontSize: 15 } }}
                            InputLabelProps={{ style: { fontSize: 15 } }}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setFilters({
                                        ...filters,
                                        [activeFilter as string]: {
                                            ...filters[activeFilter as string],
                                            value: e.target.value
                                        }
                                    })
                                }
                            }}
                            onKeyDown={(e) => {
                                if (['Enter', 'NumpadEnter'].includes(e.code || e.key)) {
                                    setFilters({
                                        ...filters,
                                        [activeFilter as string]: {
                                            ...filters[activeFilter as string],
                                            filtering: true
                                        }
                                    })

                                    setIsComponentVisible(false)
                                }
                            }}
                        />
                        <ButtonWrapper>
                            <MUIComponents.Buttons.Button
                                onClick={(e) => {
                                    setFilters({
                                        ...filters,
                                        [activeFilter as string]: {
                                            ...filters[activeFilter as string],
                                            filtering: true
                                        }
                                    })
                                    setIsComponentVisible(false)
                                }}
                                id="filterModal"
                            >
                                Zoeken
                            </MUIComponents.Buttons.Button>
                            <MUIComponents.Buttons.Button
                                onClick={(e) => {
                                    setFilters({
                                        ...filters,
                                        [activeFilter as string]: {
                                            ...filters[activeFilter as string],
                                            value: undefined,
                                            filtering: false
                                        }
                                    })

                                    setIsComponentVisible(false)
                                }}
                                id="filterModal"
                            >
                                Reset
                            </MUIComponents.Buttons.Button>
                        </ButtonWrapper>
                    </MUIComponents.Styling.Paper>
                )}
            </div>
        </>
    )
}

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

interface TableCellProps extends MuiTableCellProps {
    // you can declare custom props in here
    header?: string
}

const StyledTable = styled(MuiTable)``

const StyledTableBody = styled(MuiTableBody)``

const StyledTableCell = styled(MuiTableCell) <TableCellProps>`
    /* background-color: ${(props) => props.theme.palette.primary.main} !important; */
    color: ${(props) =>
        props.header ? props.theme.palette.primary.contrastText : undefined} !important;
`

const StyledTableContainer = styled(MuiTableContainer)``

const StyledTableHead = styled(MuiTableHead)``

const StyledTableRow = styled(MuiTableRow)`
    &:last-child td,
    &:last-child th {
        border: 0;
    }
`

const StyledTableRowHeader = styled(MuiTableRow)`
    background-color: ${(props) => props.theme.palette.primary.main} !important;

    ${(props) => ({ ...props.sx })}

    > * {
        &:first-child {
            border-radius: 2px 0 0 0;
        }
    }

    > * {
        &:last-child {
            border-radius: 0 2px 0 0;
        }
    }
`

// EXAMPLE of this component with props
/*  <MUIComponents.Table
                setPagination={(props) =>
                    setPagination({ page: props.page ?? 1, size: props.size ?? 15 })
                }
                pagination={{ count: 10, page: pagination.page }}
                onChangeSelection={(selected) => {
                    console.log('selected', selected)
                }}
                loading={false}
                hover="true"
                selection={true}
                style={{
                    marginTop: 50,
                    marginBottom: 10,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '75%',
                    minWidth: 650,
                    maxWidth: 1500,
                    background: 'white',
                    borderRadius: 25
                }}
                columns={[
                    {
                        dataKey: 'firstName'
                    },
                    {
                        dataKey: 'age',
                        align: 'right'
                    },
                    {
                        dataKey: 'status',
                        align: 'right'
                    },
                    {
                        dataKey: 'id',
                        align: 'right'
                    },
                    {
                        dataKey: 'lastName',
                        align: 'right'
                    }
                ]}
                tableHeaders={[
                    { title: 'firstName', filtering: { filter: true, filterKey: 'firstName' } }, // extraStyling: { borderTopLeftRadius: 25 }
                    {
                        title: 'age',
                        filtering: { filter: true, filterKey: 'age' },
                        align: 'right',
                        sorting: { sort: true, sortKey: 'age' }
                    },
                    {
                        title: 'status',
                        align: 'right',
                        filtering: { filter: true, filterKey: 'status' }
                    },
                    { title: 'id', align: 'right', filtering: { filter: false, filterKey: 'id' } },
                    {
                        title: 'lastName',
                        extraStyling: { borderTopRightRadius: 25 },
                        align: 'right',
                        filtering: { filter: true, filterKey: 'lastName' }
                    }
                ]}
                rows={[
                    { firstName: 'Mike', age: 12, status: 'active', id: '12', lastName: 'Meyers' },
                    { firstName: 'Tom', age: 14, status: 'disabled', id: '14', lastName: 'Nahooy' },
                    {
                        firstName: 'Brent',
                        age: 15,
                        status: 'active',
                        id: '15',
                        lastName: 'Bastianen'
                    },
                    {
                        firstName: 'Brent',
                        age: 16,
                        status: 'disabled',
                        id: '16',
                        lastName: 'Van Tilborg'
                    }
                ]}
            /> */