import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'
import styled from 'styled-components'
import { noop } from '../../utils/utils'

interface CheckboxProps extends MuiCheckboxProps {
    // you can declare custom props in here
    colorToOverwrite?: string
}

const StyledCheckbox = styled(MuiCheckbox) <CheckboxProps>`
    color: ${({ colorToOverwrite, color, ...props }) =>
        color ?? colorToOverwrite ?? props.theme.palette.primary.main} !important;
`

export const Checkbox = ({
    // assign default values here
    checked = false,
    onChange = noop,
    disabled = false,
    disableRipple = false,
    required = false,
    size = 'small',
    colorToOverwrite = undefined,
    ...props
}: CheckboxProps) => (
    <StyledCheckbox
        disableRipple={disableRipple}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        required={required}
        size={size}
        colorToOverwrite={colorToOverwrite}
        {...props}
    />
)

// Example of this component with props:
{
    /* <MUIComponents.Checkbox
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < props.rows.length
                                        }
                                        checked={
                                            props.rows.length > 0 &&
                                            selected.length === props.rows.length
                                        }
                                        onChange={handleSelectAll}
                                        inputProps={{
                                            'aria-label': 'select all desserts'
                                        }}
                                        colorToOverwrite="white"
                                    /> */
}