import MuiBadge, { BadgeProps } from '@mui/material/Badge'
import styled, { css } from 'styled-components'

const StyledBadge = styled(MuiBadge)`
    & .MuiBadge-badge {
        background-color: ${(props) =>
        props.variant === 'dot' ? props.color : props.theme.palette.primary.main} !important;

        ${(props) =>
        props.variant === 'dot' &&
        css`
                top: 5px;
                right: 10px;
            `}
    }
`

export const Badge = ({
    children,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'right'
    },
    max = 999,
    ...props
}: BadgeProps) => (
    <StyledBadge max={max} anchorOrigin={anchorOrigin} {...props}>
        {children}
    </StyledBadge>
)

// Example of this component with props:
{
    /* <MUIComponents.Badge showZero color="secondary" badgeContent={0}>
  <MailIcon />
</MUIComponents.Badge> */
}