import MuiSlider, { SliderProps } from '@mui/material/Slider'
import styled from 'styled-components'

const StyledSlider = styled(MuiSlider)`
    color: ${(props) => props.theme.palette.primary.main} !important;
    margin-left: 10px;
    width: 95% !important;
`

// Important: you can use a range slider too (the only difference is that the value needs to be an array of numbers instead of just a number)
export const Slider = ({ size = 'medium', valueLabelDisplay = 'auto', ...props }: SliderProps) => (
    <StyledSlider size={size} valueLabelDisplay={valueLabelDisplay} {...props} />
)

// Example of this component with props:
{
    /* <MUIComponents.Slider
                onChange={(e, newValue) => {
                    setFoo(newValue)
                }}
                value={foo}
                step={5}
                marks
                min={10}
                max={100}
            /> */
}