import { Button, Divider } from '@mui/material'

const ConfirmationDialog = ({ setAnchorEl, callback }: {
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null | undefined>>,
    callback: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | null) | undefined
}) => {

    const close = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) e.preventDefault()
        setAnchorEl(null)
    }

    const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        callback?.(e)
        close()
    }

    return (
        <div style={{ width: 270, padding: 10, paddingTop: 20, borderLeft: '5px solid #11545A', overflow: 'auto' }}>
            <p>Bent u zeker?</p>

            <Divider style={{ marginTop: 2, marginBottom: 15 }} />

            <span style={{ float: 'right', marginBottom: 10 }}>
                <Button size="small" style={{ marginRight: 5, color: 'rgba(20, 88, 100, 0.5)', border: '1px solid rgba(20, 88, 100, 0.5)' }} variant="outlined" onClick={(e) => close(e)}>Annuleren</Button>
                <Button size="small" style={{ backgroundColor: 'rgb(110, 152, 158)', color: 'white' }} variant="contained" onClick={(e) => handleConfirm(e)}>Bevestigen</Button>
            </span>
        </div>
    )
}

export default ConfirmationDialog