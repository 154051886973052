import { Grid } from '@mui/material';
import { MUIComponents } from '../../../muiComponents';
import { CardValuesMostUsedWebsites } from "../../../../types/index"

const MostUsedWebsites = () => {

    const values: CardValuesMostUsedWebsites[] = [{
        name: "Tijdsregistratie",
        url: "https://focus.teamleader.eu/",
        icon: <MUIComponents.Icons.AccessAlarmIcon style={{ marginLeft: 10, fontSize: 25 }} />
    }, {
        name: "HR",
        url: "https://start.officient.io/",
        icon: <MUIComponents.Icons.BusinessIcon style={{ marginLeft: 10, fontSize: 25 }} />
    }, {
        name: "Document Management",
        url: "https://fourfive.sharepoint.com/",
        icon: <MUIComponents.Icons.DescriptionIcon style={{ marginLeft: 10, fontSize: 25 }} />
    }]

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={6} columns={{ xs: 4, sm: 8, md: 12 }} style={{ paddingBottom: '3em', paddingTop: "3em" }}>
            {
                values.map((value) =>
                    <Grid key={value.name} item xs={4} sm={4} md={4} >
                        <a href={value.url} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }} >
                            <MUIComponents.Buttons.Button
                                size='small'
                                variant='outlined'
                                style={{
                                    border: "1px solid #8EACB1", color: "white", width: "260px",
                                    fontSize: "14px", paddingTop: "1em", paddingBottom: "1em", backgroundColor: "#005C6C", fontFamily: 'BasierCircle, Arial, sans-serif',
                                }}
                            >{value?.name} {value.icon}
                            </MUIComponents.Buttons.Button>
                        </a>
                    </Grid>)
            }
        </Grid>
    )
}

export default MostUsedWebsites

//style={{ backgroundImage: `url(${backgroundHome})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: 'center'}}