import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { MUIComponents } from '../components/muiComponents';
import firebaseApp from '../firebase';
import { collectedUserInfoState } from '../store/store';

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    /*const location = useLocation();

    const fbUserObject = useRecoilValue(fbUserObjectState);

    // userObject needs a uid, without these you cannot be considered logged in.
    if (fbUserObject?.uid) {
        return children
    }

    return <Navigate state={{ from: location }} to="/login" replace={true} />*/


    const location = useLocation()
    const [pendingAuth, setPendingAuth] = useState<boolean>(true)
    const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false)
    const hasCollecteduserInfo = useRecoilValue(collectedUserInfoState)

    const loader = (
        <MUIComponents.Styling.Box style={{ width: '100%', textAlign: 'center', paddingTop: 50, height: "100vh", backgroundColor: "#E2EAEC" }}>
            <MUIComponents.Loader />
            <MUIComponents.Typography itemType='p'>Authorizeren...</MUIComponents.Typography>
        </MUIComponents.Styling.Box>
    )

    // Check if user is logged in on firebase Auth level
    // userObject set is an App(.tsx) based async logic so won't always be available on refresh yet
    useEffect(() => {
        const auth = getAuth(firebaseApp)

        onAuthStateChanged(auth, async (user) => {
            if (!user) {
                setIsLoggedOut(true)
            }

            setPendingAuth(false)
        })
    }, [])

    if (isLoggedOut) return <Navigate state={{ from: location }} to="/login" replace={true} />

    if (pendingAuth /* || !hasCollecteduserInfo */) return loader

    return children
}