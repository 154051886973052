
import { CardValue } from '../../../../types/index'
import * as logoImages from "./cardLogoImages"

export const websiteCardValues: CardValue[] = [{
    id: 1,
    name: "KBO (opzoekingen over ondernemingen)",
    link: "https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html",
    category: "Ondernemingsinfo",
    logo: logoImages.KruispuntbankLogo
}, {
    id: 2,
    name: "Territoriale bevoegdheid van ondernemingsrechtbanken",
    link: "https://territoriale-bevoegdheid.just.fgov.be/cgi-main/competence-territoriale.pl",
    category: "Ondernemingsinfo",
    logo: "TB"
}, {
    id: 3,
    name: "Statutendatabank",
    link: "https://statuten.notaris.be/stapor_v1/search",
    category: "Ondernemingsinfo",
    logo: logoImages.FednotLogo
}, {
    id: 4,
    name: "Drimble (voor Nederlandse vennootschappen)",
    link: "https://drimble.nl/bedrijf/",
    category: "Ondernemingsinfo",
    logo: logoImages.DrimbleLogo
}, {
    id: 5,
    name: "GBA burger",
    link: "https://www.gegevensbeschermingsautoriteit.be/burger",
    category: "GDPR",
    logo: logoImages.GegevensbeschermingLogo
}, {
    id: 6,
    name: "GBA professioneel",
    link: "https://www.gegevensbeschermingsautoriteit.be/professioneel",
    category: "GDPR",
    logo: logoImages.GegevensbeschermingLogo
}, {
    id: 7,
    name: "GBA toolbox",
    link: "https://www.gegevensbeschermingsautoriteit.be/professioneel/eerstehulp-avg/toolbox",
    category: "GDPR",
    logo: logoImages.GegevensbeschermingLogo
}, {
    id: 8,
    name: "EDPB (European Data Protection Board)",
    link: "https://edpb.europa.eu/edpb_en",
    category: "GDPR",
    logo: logoImages.EDPBLogo
}, {
    id: 9,
    name: "Belgisch Staatsblad",
    link: "https://www.ejustice.just.fgov.be/cgi/welcome.pl",
    category: "Ondernemingsinfo",
    logo: logoImages.StaatsbladLogo
}, {
    id: 10,
    name: "Practical law",
    link: "https://uk.practicallaw.thomsonreuters.com/Browse/Home/PracticalLaw?transitionType=Default&contextData=(sc.Default)",
    category: "Kennis",
    logo: "PL"
}, {
    id: 11,
    name: "Google",
    link: "https://www.google.com",
    category: "Kennis",
    logo: logoImages.GoogleLogo
}, {
    id: 12,
    name: "Jura",
    link: "https://jura-kluwer-be.kuleuven.e-bronnen.be/secure/Home.aspx",
    category: "Kennis",
    logo: "Ju"
}, {
    id: 13,
    name: "De Tijd",
    link: "https://www.tijd.be/",
    category: "Kennis",
    logo: logoImages.DeTijdLogo
}, {
    id: 14,
    name: "LinkedIn",
    link: "https://www.linkedin.com/login/nl",
    category: "Kennis",
    logo: logoImages.LinkedinLogo
}, {
    id: 15,
    name: "Deepl",
    link: "https://www.deepl.com/translator",
    category: "Vertalingen",
    logo: logoImages.DeeplLogo
}, {
    id: 16,
    name: "Vertalen.nu",
    link: "https://www.vertalen.nu/",
    category: "Vertalingen",
    logo: "VN"
}, {
    id: 17,
    name: "Docusign",
    link: "https://account.docusign.com/",
    category: "Digitaal",
    logo: logoImages.DocuSignLogo
}, {
    id: 18,
    name: "Epona",
    link: "https://fourfive.sharepoint.com/",
    category: "Admin",
    logo: logoImages.eponaLogo
}, {
    id: 19,
    name: "Teamleader",
    link: "https://focus.teamleader.eu/",
    category: "Admin",
    logo: logoImages.teamleaderLogo
}, {
    id: 20,
    name: "Officient",
    link: "https://start.officient.io/",
    category: "Admin",
    logo: logoImages.officientLogo
}, {
    id: 21,
    name: "Privaat Luik voor advocaten",
    link: "https://privaatluik.advocaat.be/",
    category: "Admin",
    logo: logoImages.PrivaatLuikLogo
}, {
    id: 22,
    name: "WeTransfer",
    link: "https://fourfive.wetransfer.com/",
    category: "Admin",
    logo: logoImages.WeTransfer
}, {
    id: 23,
    name: "Offery",
    link: "https://www.offery.nl/",
    category: "Vennoten",
    logo: logoImages.Offery
}, {
    id: 24,
    name: "Exact Online",
    link: "https://start.exactonline.be/",
    category: "Vennoten",
    logo: logoImages.Exact
}, {
    id: 25,
    name: "Linguee",
    link: "https://www.linguee.com/",
    category: "Vertalingen",
    logo: logoImages.LingueeLogo
}, {
    id: 26,
    name: "KBO Internationaal (aan te passen)",
    link: "https://www.google.com",
    category: "Ondernemingsinfo",
    logo: logoImages.GoogleLogo
}, {
    id: 27,
    name: "Companyweb",
    link: "https://www.companyweb.be/nl",
    category: "Ondernemingsinfo",
    logo: logoImages.CompanyWeb
}, {
    id: 28,
    name: "Globaal starten",
    link: "startButton",
    category: "Facturen",
    logo: "Gl"
}, {
    id: 29,
    name: "Anneleen VE",
    link: "AnneleenButton",
    category: "Facturen",
    logo: "An"
}, {
    id: 30,
    name: "Tessa G",
    link: "TessaButton",
    category: "Facturen",
    logo: "Te"
}, {
    id: 31,
    name: "Dimitri C",
    link: "DimitriButton",
    category: "Facturen",
    logo: "Di"
}, {
    id: 32,
    name: "Ahmed T",
    link: "AhmedButton",
    category: "Facturen",
    logo: "Ah"
}, {
    id: 33,
    name: "Openingsfiche",
    link: "https://forms.office.com/Pages/ResponsePage.aspx?id=L8W_lkI0m0aP1EUoRdKVUouKatbLW_5HuZYMAM1svahUOVE5NDBOVzlKMklYSEdMUVdPMk5UMkpCWC4u&r2c2c50b3a75a4140880c2fbdfb7a8b86=%2214%20dagen%20factuurdatum%22&rae48b540994147b6a0bd817dfbf969b2=%2210%25%22",
    category: "Admin",
    logo: logoImages.MicrosoftFormsLogo
}]