import styled from 'styled-components'
import Stack from '@mui/material/Stack'
import MuiStepper, { StepperProps as MuiStepperProps } from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { noop } from '../../utils/utils'
import { MUIComponents } from '.'

// TODO: styling for mobile
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 18
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1
    }
}))

const ColorlibStepIconRoot = styled('div')<SxProps<Theme> & { ownerState: OwnerState }>(
    ({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.stepClickIsEnabled &&
            !ownerState.disableAllStepClick && { cursor: 'pointer' }),
        ...(ownerState.active && {
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            backgroundColor: theme.palette.primary.main
        }),
        ...(ownerState.completed && {
            backgroundColor: theme.palette.primary.main
        })
    })
);

interface Props {
    stepClick?: (icon: number) => void
    active?: boolean
    className?: string
    completed?: boolean
    icon: number
    icons: { [key: number]: JSX.Element }
    disableStepClick?: number
    disableAllStepClick?: boolean
}
interface OwnerState {
    active: boolean | undefined;
    completed: boolean | undefined;
    stepClickIsEnabled?: boolean;
    disableAllStepClick?: boolean;
}

const ColorlibStepIcon = ({
    active,
    completed,
    className,
    icon,
    icons,
    disableStepClick,
    disableAllStepClick,
    ...props
}: Props) => (
    <ColorlibStepIconRoot
        onClick={() => {
            if (icon === disableStepClick || disableAllStepClick) {
                return
            }
            props.stepClick?.(icon - 1)
        }}
        ownerState={{
            completed,
            active,
            stepClickIsEnabled: icon !== disableStepClick,
            disableAllStepClick
        }}
        className={className}
    >
        {icons[icon]}
    </ColorlibStepIconRoot>
)

interface StepperProps extends MuiStepperProps {
    // you can declare custom props in here
    steps: string[]
    icons: { [key: number]: JSX.Element }
    stepClick?: (icon: number) => void
    loading?: boolean
    disableStepClick?: number
    disableAllStepClick?: boolean
}

// icons prop in this format: {
//     1: <MUIComponents.Icons.AccountCircleIcon />,
//     2: <MUIComponents.Icons.ContactMailIcon />,
//     3: <MUIComponents.Icons.CheckCircleIcon />
// }

// TODO: mobile -> styling steps with accordion dan heb je ook niet zoveel white space
export const Stepper = ({
    alternativeLabel,
    activeStep,
    orientation,
    steps,
    icons,
    stepClick = noop,
    loading = false,
    disableStepClick,
    disableAllStepClick = false
}: StepperProps) => (
    <Stack sx={{ width: '100%' }} spacing={4}>
        <MuiStepper
            alternativeLabel={alternativeLabel}
            activeStep={activeStep}
            orientation={orientation}
            connector={loading ? null : <ColorlibConnector />}
        >
            {steps.map((step, i) => (
                <Step key={i}>
                    {loading ? (
                        <MUIComponents.Skeleton
                            sx={{ margin: 'auto' }}
                            width={50}
                            height={50}
                            variant="circular"
                        />
                    ) : (
                        <StepLabel
                            StepIconComponent={(props) =>
                                ColorlibStepIcon({
                                    ...props,
                                    icon: Number(props.icon),
                                    icons,
                                    stepClick,
                                    disableStepClick,
                                    disableAllStepClick
                                })
                            }
                        >
                            {step}
                        </StepLabel>
                    )}
                </Step>
            ))}
        </MuiStepper>
    </Stack>
)

// Example of this component with props:
{
    /* <MUIComponents.Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    steps={['Fietsgegevens', 'Accessoires', 'Kledij', 'Bevestiging berekening']}
                    icons={{
                        1: <MUIComponents.Icons.DirectionsBikeIcon />,
                        2: <MUIComponents.Icons.SportsMotorsportsIcon />,
                        3: <MUIComponents.Icons.CheckroomIcon />,
                        4: <MUIComponents.Icons.CheckCircleIcon />
                    }}
                /> */
}