import * as React from 'react'
import styled, { CSSProperties } from 'styled-components'
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card'
import CardHeader, { CardHeaderProps as MuiCardHeaderProps } from '@mui/material/CardHeader'
import CardMedia, { CardMediaProps as MuiCardMediaProps } from '@mui/material/CardMedia'
import CardContent, { CardContentProps as MuiCardContentProps } from '@mui/material/CardContent'
import CardActions, { CardActionsProps as MuiCardActionsProps } from '@mui/material/CardActions'
import { IconButtonProps } from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import { MUIComponents } from '.'
import { noop } from '../../utils/utils'

interface ExpandProps extends IconButtonProps {
    // you can declare custom props in here
    expand: boolean
}

const ExpandMore = styled((props: ExpandProps) => <MUIComponents.Buttons.IconButton {...props} />)(
    ({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    })
)

const StyledCard = styled(MuiCard)`
    & .MuiCardHeader-action {
        align-self: unset;
    }
`

interface CardProps extends Omit<MuiCardProps, 'title'> {
    // you can declare custom props in here
    maxWidth?: number
    avatar?: boolean
    subheader?: MuiCardHeaderProps['subheader']
    image?: MuiCardMediaProps['image']
    src?: MuiCardMediaProps['src']
    expanding?: boolean
    details?: MuiCardContentProps['children']
    expandableContent?: MuiCardContentProps['children']
    actions?: MuiCardActionsProps['children']
    initials?: string
    loading?: boolean
    menuActions?: { label: string; value: string; onClick: () => void }[]
    disableMenuActions?: boolean
    onCardClick?: () => void
    style?: CSSProperties
    subHeaderColor?: CSSProperties['color']
    subHeaderStyle?: CSSProperties
    title?: (string | JSX.Element) & React.ReactNode
}

export const Card = ({
    maxWidth = 500,
    avatar = false,
    title = '',
    subheader = '',
    expanding = false,
    loading = false,
    menuActions = [
        {
            label: 'Wijzig (DOET NOG NIETS)',
            value: 'Wijzig',
            onClick: noop
        },
        {
            label: 'Verwijder (DOET NOG NIETS)',
            value: 'Verwijder',
            onClick: noop
        },
        {
            label: 'Kopieer (DOET NOG NIETS)',
            value: 'Kopieer',
            onClick: noop
        }
    ],
    disableMenuActions = false,
    onCardClick = noop,
    ...props
}: CardProps) => {
    const [expanded, setExpanded] = React.useState(false)

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null)
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    return (
        <StyledCard onClick={onCardClick} style={props.style} sx={{ maxWidth }}>
            <CardHeader
                avatar={
                    avatar &&
                    (loading ? (
                        <MUIComponents.Skeleton width={40} height={40} variant="circular" />
                    ) : (
                        <MUIComponents.Avatar icon={props.initials} />
                    ))
                }
                style={props.subHeaderStyle}
                action={
                    disableMenuActions ? undefined : loading ? (
                        <MUIComponents.Skeleton
                            style={{ marginLeft: '16px' }}
                            width={40}
                            height={40}
                            variant="circular"
                        />
                    ) : (
                        <MUIComponents.Buttons.IconButton
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget)
                            }}
                            aria-label="settings"
                        >
                            <MUIComponents.Icons.MoreVertIcon />
                        </MUIComponents.Buttons.IconButton>
                    )
                }
                title={loading ? <MUIComponents.Skeleton width="100%" height={20} /> : title}
                subheader={
                    loading ? <MUIComponents.Skeleton width="100%" height={20} /> : subheader
                }
                subheaderTypographyProps={{ color: props.subHeaderColor }}
            />
            <MUIComponents.Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                menuItems={menuActions.map((action) => ({
                    label: action.label,
                    value: action.value
                }))}
                itemClick={(item) => {
                    menuActions.find((action) => action.label === item.label)?.onClick()
                }}
            />
            {props.image ||
                (props.src &&
                    (loading ? (
                        <MUIComponents.Skeleton width="100%" />
                    ) : (
                        <CardMedia
                            component="img"
                            height="200"
                            image={props.image}
                            src={props.src}
                            alt="Card image"
                        />
                    )))}
            <CardContent>
                {props.details &&
                    (loading ? (
                        <React.Fragment>
                            <MUIComponents.Skeleton height={85} width="100%" />
                        </React.Fragment>
                    ) : (
                        props.details
                    ))}
            </CardContent>

            <CardActions sx={{ alignSelf: 'unset ! important' }} disableSpacing>
                {props.actions && (loading ? <MUIComponents.Skeleton /> : props.actions)}
                {expanding && (
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <MUIComponents.Icons.ExpandMoreIcon />
                    </ExpandMore>
                )}
            </CardActions>

            {expanding && (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>{props.expandableContent}</CardContent>
                </Collapse>
            )}
        </StyledCard>
    )
}

// Example of this component with props:
{
    /* <MUIComponents.Card
    title={calculation?.friendly_name ?? '/'}
    subheader={`Gecreëerd op ${day} ${capitalize(month)}`}
    content={
        <>
            <MUIComponents.Typography variant="body1" style={{ textAlign: 'unset' }}>
                Fiets merk: {calculation?.bikeBrand?.name}
            </MUIComponents.Typography>
            <MUIComponents.Typography variant="body1" style={{ textAlign: 'unset' }}>
                Fiets type: {calculation?.bikeType?.name}
            </MUIComponents.Typography>
        </>
    }
    loading={loading}
/> */
}