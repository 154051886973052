import { Button, Divider, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { alertObjState } from '../../../../store/store';
import { ButtonMouseEvent } from '../../../../types';
import { MUIComponents } from '../../../muiComponents';

const MetaDataModal = ({ setShowMetaModal, elasticfile, refresh, updateDocument }: {
    setShowMetaModal: React.Dispatch<React.SetStateAction<boolean>>,
    elasticfile: any,
    refresh: (shouldRefresh: boolean) => Promise<void>,
    updateDocument: (documentInput: any) => Promise<void>
}) => {

    //Constants
    //TODO: datastore
    const OPTIONS: { [key: string]: { label: string, value: string }[] } = {
        //TODO: this also exists within GlobalFilter => Place somewhere and reuse!
        ff_taal: [{ label: "Geen", value: "" }, { value: "NL", label: "Nederlands" }, { value: "EN", label: "Engels" }, { value: "FR", label: "Frans" }, { value: "DE", label: "Duits" }],
        ff_team: [{ label: "Geen", value: "" }, { label: "M&A", value: "M&A" }, { label: "Commercial", value: "Commercial" }, { label: "Tech", value: "Tech" }, { label: "Corporate", value: "Corporate" }, { label: "Litige", value: "Litige" }],
        ff_partner: [{ label: "Geen", value: "" }, { label: "Anneleen", value: "Anneleen" }, { label: "Dimitri", value: "Dimitri" }, { label: "Tessa", value: "Tessa" }, { label: "Maxime", value: "Maxime" }],
        ff_point_of_view: [{ label: "Geen", value: "" }, { label: "Supplier", value: "Supplier" }, { label: "Vendor", value: "Vendor" }, { label: "Founder", value: "Founder" }, { label: "Investor", value: "Investor" }, { label: "Buyer", value: "Buyer" }, { label: "Seller", value: "Seller" }, { label: "Processor", value: "Processor" }, { label: "Controller", value: "Controller" }],
        ff_business: [{ label: "Geen", value: "" }, { label: "B2B", value: "B2B" }, { label: "B2C", value: "B2C" }],
        ff_state: [{ label: "Geen", value: "" }, { label: "Draft", value: "Draft" }, { label: "Markup", value: "Markup" }, { label: "Execution Copy", value: "Execution Copy" }, { label: "Template", value: "Template" },],
        ff_source: [{ label: "Geen", value: "" }, { label: "Four & Five", value: "Four & Five" }, { label: "3rd Party", value: "3rd Party" }]
    }

    //Global states
    const [_, setAlertObj] = useRecoilState(alertObjState);

    // States
    const [loading, setLoading] = useState<boolean>(false)
    const [ffValues, setFfValues] = useState<any>({})

    useEffect(() => {
        setFfValues({
            ff_team: elasticfile?.ff_team?.raw ?? '',
            ff_partner: elasticfile?.ff_partner?.raw ?? '',
            ff_point_of_view: elasticfile?.ff_point_of_view?.raw ?? '',
            ff_business: elasticfile?.ff_business?.raw ?? '',
            ff_state: elasticfile?.ff_state?.raw ?? '',
            ff_source: elasticfile?.ff_source?.raw ?? '',
            ff_dossier: elasticfile?.ff_dossier?.raw ?? '',
            ff_taal: elasticfile?.ff_taal?.raw ?? '',
            ff_do_not_use: elasticfile?.ff_do_not_use?.raw === "1" ? true : false,
        })
    }, [])

    const close = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) e.preventDefault()
        setShowMetaModal(false)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, prop: string) => {
        const value = (prop === "ff_do_not_use") ? e.target.checked : e.target.value
        changePropValueHandler(prop, value)
    }

    const handleSelectChange = (e: SelectChangeEvent<unknown>, prop: string) => {
        changePropValueHandler(prop, e.target.value)
    }

    const changePropValueHandler = (prop: string, value: any) => {
        setFfValues({
            ...ffValues,
            [prop]: value
        })
    }

    const handleConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setLoading(true)

        console.log(ffValues)
        //Update document & its meta props
        await updateDocument({
            ...ffValues,
            ff_do_not_use: ffValues?.ff_do_not_use ? 1 : 0,
            id: elasticfile?.id?.raw,
        })

        //Temp delay for elastic procession assurance
        await new Promise(r => setTimeout(r, 500))
        close()
        setLoading(false)
        refresh(false)
    }

    const content = (
        <>
            <Divider style={{ marginTop: 0, width: '100%', paddingLeft: -25 }} />
            <div style={{ width: 400, height: 400, overflow: 'auto', padding: 10, paddingRight: 10 }}>
                {loading && <MUIComponents.LinearLoader color="secondary" style={{ marginLeft: -24, marginRight: -24, marginTop: -10, marginBottom: 6 }} />}

                <div style={{ marginBottom: 30, paddingLeft: 15, paddingRight: 15 }}>
                    <MUIComponents.Select.SingleSelect sx={{ width: 37 }} disabled={loading} onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_state')} labelContent='Status' menuItems={OPTIONS.ff_state ?? []} size="small" value={ffValues?.ff_state ?? ''} />

                    <MUIComponents.Select.SingleSelect sx={{ width: 37 }} disabled={loading} onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_team')} labelContent='Team' menuItems={OPTIONS.ff_team ?? []} size="small" value={ffValues?.ff_team ?? ''} />

                    <MUIComponents.Select.SingleSelect sx={{ width: 37 }} disabled={loading} onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_partner')} labelContent='Partner' menuItems={OPTIONS.ff_partner ?? []} size="small" value={ffValues?.ff_partner ?? ''} />

                    <MUIComponents.Select.SingleSelect sx={{ width: 37 }} disabled={loading} onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_point_of_view')} labelContent='Point of view' menuItems={OPTIONS.ff_point_of_view ?? []} size="small" value={ffValues?.ff_point_of_view ?? ''} />

                    <MUIComponents.Select.SingleSelect sx={{ width: 375 }} disabled={loading} onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_taal')} labelContent='Taal' menuItems={OPTIONS.ff_taal ?? []} size="small" value={ffValues?.ff_taal ?? ''} />

                    <MUIComponents.Select.SingleSelect sx={{ width: 375 }} disabled={loading} onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_source')} labelContent='Bron' menuItems={OPTIONS.ff_source ?? []} size="small" value={ffValues?.ff_source ?? ''} />

                    <MUIComponents.Select.SingleSelect sx={{ width: 37 }} disabled={loading} onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_business')} labelContent='Business' menuItems={OPTIONS.ff_business ?? []} size="small" value={ffValues?.ff_business ?? ''} />

                    <p>Do not use: <MUIComponents.Switch disabled={loading} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, 'ff_do_not_use')} size="medium" checked={ffValues?.ff_do_not_use ?? false} /></p>
                </div>

                <div style={{ position: 'sticky', bottom: -10, background: 'white', margin: -10, height: 50, paddingBottom: 10, zIndex: 9000 }}>
                    <Divider style={{ marginBottom: 15 }} />
                    <span style={{ float: 'right', marginBottom: 15, paddingRight: 15 }}>
                        <Button size="small" style={{ marginRight: 5, color: '#11545A', border: '1px solid #11545A' }} variant="outlined" onClick={(e) => close(e)}>Annuleren</Button>
                        <Button size="small" style={{ backgroundColor: '#11545A', color: 'white' }} variant="contained" disabled={loading} onClick={(e) => handleConfirm(e)}>Bevestigen</Button>
                    </span>
                </div>
            </div>
        </>
    )

    return (
        <>
            <MUIComponents.Dialog
                contentStyle={{
                    borderLeft: '5px solid #11545A',
                    margin: 0,
                    padding: 0,
                    fontFamily: 'BasierCircle, Arial, sans-serif'
                }}
                actionsStyle={{ padding: 'unset' }}
                title='Eigenschappen'
                open
                scroll="paper"
                contentComponent={content}
                onClose={(e) => {
                    close(e as ButtonMouseEvent | undefined)
                }}
                fullScreen={false} />
        </>
    )
}

export default MetaDataModal