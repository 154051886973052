import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar'
import styled from 'styled-components'

const StyledAvatar = styled(MuiAvatar)`
    background-color: ${(props) => props.theme.palette.primary.main} !important;
`

interface AvatarProps extends MuiAvatarProps {
    // you can declare custom props in here
    icon?: JSX.Element | string
}

export const Avatar = ({ icon, ...props }: AvatarProps) => (
    <StyledAvatar {...props} sx={{ m: 1 }}>
        {icon}
    </StyledAvatar>
)

// Example of this component with props:
// <MUIComponents.Avatar icon={<MUIComponents.Icons.LockOutlinedIcon />} alt="avatar" />