import { CircularProgress } from '@mui/material'
import axios from 'axios';
import { useEffect, useState } from 'react';

const LikeToolTipContent = ({ likesArr, fbUserObject }: {
    likesArr: Array<string>,
    fbUserObject: any
}) => {

    const [users, setUsers] = useState<Array<string>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        collectUsers();
    }, [likesArr])

    const collectUsers = async () => {
        try {
            const res = await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                action: 'collectLikeUsers',
                uids: likesArr
            }, {
                headers: {
                    uid: fbUserObject?.uid ?? '',
                    'access-token': fbUserObject?.accessToken ?? '',
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            })

            setUsers(res?.data);
        } catch (err) {
            //TODO: messaging system
            console.log(err)
            alert("Kon de zoekactie niet uitvoeren, probeer opnieuw")
        } finally {
            setLoading(false)
        }
    }

    if (likesArr?.length <= 0)
        return (<></>);

    if (loading)
        return <CircularProgress style={{ margin: 5, width: 15, height: 15, color: 'rgb(17, 84, 90)' }} />

    return (
        <>
            <div>
                {
                    users?.map((like: string) => (
                        <p style={{ whiteSpace: 'nowrap' }} key={'key_' + like}>{like}</p>
                    ))
                }
            </div>
        </>
    )
}

export default LikeToolTipContent