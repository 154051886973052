import { Button, Divider } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'
import { ButtonMouseEvent } from '../../../../types'
import { WrapInToolTip } from '../../../../utils/ToolTipWrap'
import { MUIComponents } from '../../../muiComponents'

const BodyModal = ({
    elasticFile,
    setShowBodyModal,
    launch
}: {
    elasticFile: any
    setShowBodyModal: React.Dispatch<React.SetStateAction<boolean>>
    launch: (url: string, isDoc: boolean) => void
}) => {
    const stringURLManipulation = (value: string): string => {
        const x = value.split('/Files')[0]
        const y = value.split('.com')[1]

        return x + '/_layouts/15/embed.aspx?id=' + y + '&parent=' + y
    }

    const resolveEmbedUrl = (): string => {
        if (elasticFile?.extension?.raw === 'pdf') {
            return stringURLManipulation(elasticFile?.url?.raw)
            //return 'https://fourfive.sharepoint.com/sites/dms01/1383047/_layouts/15/embed.aspx?id=/sites/dms01/1383047/Files/Documents/Corporate/202212%20-%20Signed%20docs/ELYSIANNXT%20NV%20%20artikel%207%20180%20-%20DRAFT%2006122022%20-%20FF%20%288Dec22%29%20%281%29.doc.pdf&parent=/sites/dms01/1383047/Files/Documents/Corporate/202212%20-%20Signed%20docs/ELYSIANNXT%20NV%20%20artikel%207%20180%20-%20DRAFT%2006122022%20-%20FF%20%288Dec22%29%20%281%29.doc.pdf'
        } else {
            // Docs handling
            return elasticFile?.url?.raw?.split('&action')[0] + '&amp;action=embedview'
        }
    }

    // States
    const [srcURL, setsrcURL] = useState<string>(resolveEmbedUrl())
    const [onPreview, setOnPreview] = useState<boolean>(true)

    const close = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) e.preventDefault()
        setShowBodyModal(false)
    }

    const switchContentType = () => {
        setOnPreview(!onPreview)
    }

    const Iframe = ({ iframe }: { iframe: string }) => (
        <div
            style={{ margin: 'auto', width: '100%', height: '60vh' }}
            dangerouslySetInnerHTML={{ __html: iframe ?? '' }}
        />
    )

    const content = (
        <div style={{ width: '100%' }}>
            <Divider style={{ marginTop: 0, width: '100%', paddingLeft: -25 }} />

            {onPreview ? (
                <Iframe
                    iframe={`<iframe id="scaled-frame" width="100%" height="100%" scrolling="no" frameborder="no" allow="autoplay" src="${srcURL}"></iframe>`}
                />
            ) : (
                <ContentBodyWrapper>
                    <p style={{ lineHeight: 1.4, color: '#595959' }}>
                        {elasticFile?.body?.raw ?? '/'}
                    </p>
                </ContentBodyWrapper>
            )}
        </div>
    )

    const handleReset = () => {
        const original = srcURL
        setsrcURL('')

        setTimeout(() => {
            setsrcURL(original)
        }, 1000)
    }

    const actionsContent = (
        <div
            style={{
                width: '100vw',
                position: 'relative',
                bottom: 0,
                top: 8,
                marginLeft: -20,
                right: -10,
                background: 'white'
            }}
        >
            <Divider style={{ marginTop: 0, marginBottom: 15 }} />

            <span style={{ float: 'right', marginBottom: 10 }}>
                {onPreview &&
                    WrapInToolTip(
                        <MUIComponents.Buttons.IconButton
                            onClick={() => handleReset()}
                            style={{
                                color: '#11545A',
                                background: 'rgba(20, 88, 100, 0.1)',
                                marginRight: 10
                            }}
                        >
                            <MUIComponents.Icons.RefreshIcon />
                        </MUIComponents.Buttons.IconButton>,
                        'Preview herladen',
                        'bottom'
                    )}

                {WrapInToolTip(
                    <MUIComponents.Buttons.IconButton
                        onClick={() => switchContentType()}
                        style={{
                            color: ' #11545A',
                            background: 'rgba(20, 88, 100, 0.1)',
                            marginRight: 10
                        }}
                    >
                        {onPreview ? (
                            <MUIComponents.Icons.TextFieldsIcon />
                        ) : (
                            <MUIComponents.Icons.ArticleIcon />
                        )}
                    </MUIComponents.Buttons.IconButton>,
                    onPreview ? 'Tekstuele weergave tonen' : 'Document preview tonen',
                    'bottom'
                )}

                {WrapInToolTip(
                    <MUIComponents.Buttons.IconButton
                        onClick={() => launch(elasticFile?.url?.raw ?? '', (elasticFile?.extension?.raw ?? '') === "docx")}
                        style={{
                            color: '#11545A',
                            background: 'rgba(20, 88, 100, 0.1)',
                            marginRight: 10
                        }}
                    >
                        <MUIComponents.Icons.LaunchIcon />
                    </MUIComponents.Buttons.IconButton>,
                    'Extern openen',
                    'bottom'
                )}
                <Button
                    size="small"
                    style={{
                        marginRight: 15,
                        color: '#11545A',
                        border: '1px solid #11545A'
                    }}
                    variant="outlined"
                    onClick={(e) => close(e)}
                >
                    Sluiten
                </Button>
            </span>
        </div>
    )

    return (
        <>
            <MUIComponents.Dialog
                actionsContent={actionsContent}
                scroll="paper"
                onClose={(event, reason) => {
                    close(event as ButtonMouseEvent | undefined)
                }}
                title={elasticFile?.title?.raw ?? '/'}
                open={true}
                contentComponent={content}
                style={{ minWidth: '1000px' }}
                sx={{
                    '& .MuiPaper-root': { overflow: 'unset' },
                    '& .MuiDialogContent-root': { overflow: 'unset' },
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "650px"
                        },
                    },
                }}
            />
        </>
    )
}

export default BodyModal

const ContentBodyWrapper = styled.div`
    width: calc(100% - 25px);
    height: 60vh;
    padding: 10px;
    padding-right: 10px;
    overflow: auto;
`