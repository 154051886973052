import MuiInput, { InputProps as MuiInputProps } from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import styled from 'styled-components'
import { noop } from '../../utils/utils'

const StyledInput = styled(MuiInput) <{ border?: boolean }>`
    &.MuiInput-root {
        border-bottom: ${(props) =>
        props.border ? undefined : '2px solid' + props.theme.palette.primary.contrastText};
        color: ${(props) =>
        props.error ? props.theme.palette.error.main : props.theme.palette.divider};
    }

    &.Mui-focused {
        border-bottom: ${(props) =>
        props.border ? undefined : '2px solid' + props.theme.palette.primary.contrastText};
    }
`

interface InputProps extends MuiInputProps {
    // you can declare custom props in here
    startIcon?: JSX.Element
    endIcon?: JSX.Element
    border?: boolean
}

export const Input = ({
    onBlur = noop,
    onChange = noop,
    onKeyDown = noop,
    margin = 'dense',
    required = true,
    error = false,
    id = 'input',
    value = undefined,
    disabled = false,
    size = 'small',
    autoComplete = 'off',
    border = true,
    ...props
}: InputProps) => (
    <StyledInput
        {...(props.startIcon && {
            startAdornment: <InputAdornment position="start">{props.startIcon}</InputAdornment>
        })}
        {...(props.endIcon && {
            endAdornment: <InputAdornment position="end">{props.endIcon}</InputAdornment>
        })}
        fullWidth={false}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        margin={margin}
        required={required}
        error={error}
        value={value}
        disabled={disabled}
        size={size}
        disableUnderline={true}
        autoComplete={autoComplete}
        border={border}
        {...props}
    />
)

// Example of this component with props:
{
    /* <MUIComponents.Input
          id="input-with-icon-adornment"
          startIcon={<MUIComponents.Icons.CalculateIcon />}
          required={false}
          onChange={() => {console.log("changed")}}
          disabled
        /> */
}