import MuiList, { ListProps as MuiListProps } from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar, { ListItemAvatarProps } from '@mui/material/ListItemAvatar'
import ListSubheader from '@mui/material/ListSubheader'
import styled from 'styled-components'
import { MUIComponents } from '.'
import { ItemProps } from '../../types'
import { noop } from '../../utils/utils'

interface ListProps extends MuiListProps {
    // you can declare custom props in here
    content?: string
    icons: { [key: number]: JSX.Element }
    listItems: ItemProps[]
    setListItem?: (listItem: ItemProps) => void
    itemContent?: (
        title: string,
        subTitle: string,
        index: number,
        item: ItemProps['item']
    ) => JSX.Element
    listItemAvatarStyle?: ListItemAvatarProps['sx']
    listHeader?: string
    add?: boolean
    addFunction?: () => void
    deleteFunction?: (index: number) => void
    loading?: boolean
}

export const List = ({
    add = true,
    addFunction = noop,
    deleteFunction = noop,
    listItems = [],
    listItemAvatarStyle = { mt: 0 },
    itemContent = (title, _subTitle, _index) => (
        <MUIComponents.Typography
            sx={{
                alignSelf: 'center',
                margin: 'auto',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }}
            variant="body2"
            color="text.primary"
        >
            {title}
        </MUIComponents.Typography>
    ),
    loading = false,
    ...props
}: ListProps) => (
    <MuiList
        sx={{ width: '100%', ...props.sx }}
        {...(props.listHeader && {
            subheader: (
                <Wrapper>
                    <ListSubheader
                        sx={{ lineHeight: '40px' }}
                        component="div"
                        id="nested-list-subheader"
                    >
                        {props.listHeader}
                    </ListSubheader>
                    {add && (
                        <MUIComponents.Buttons.IconButton
                            onClick={addFunction}
                            edge="end"
                            aria-label="add"
                        >
                            <MUIComponents.Icons.AddCircleIcon />
                        </MUIComponents.Buttons.IconButton>
                    )}
                </Wrapper>
            )
        })}
        {...props}
    >
        {loading ? (
            <MUIComponents.Skeleton
                sx={{ margin: 'auto', marginBottom: '10px' }}
                width="100%"
                height={400}
                variant="rectangular"
            />
        ) : (
            listItems.map((child, index) => (
                <ListItem
                    key={index}
                    {...(child.delete && {
                        secondaryAction: (
                            <MUIComponents.Buttons.IconButton
                                onClick={() => deleteFunction(index)}
                                edge="end"
                                aria-label="delete"
                            >
                                <MUIComponents.Icons.DeleteIcon />
                            </MUIComponents.Buttons.IconButton>
                        )
                    })}
                    alignItems="flex-start"
                    sx={{ justifyContent: 'center', padding: 'unset' }}
                >
                    <ListItemAvatar sx={listItemAvatarStyle}>
                        <MUIComponents.Avatar icon={props.icons[child.avatar]} alt="avatar" />
                    </ListItemAvatar>
                    {itemContent(child.title, child.subTitle, index, child.item)}
                </ListItem>
            ))
        )}
    </MuiList>
)

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

// Example of this component with props:
{
    /* <MUIComponents.List
                icons={{
                    1: <MUIComponents.Icons.SportsMotorsportsIcon />
                }}
                listItems={props.data}
                itemContent={(title, subTitle, index) =>
                    ItemContent({
                        description: { error: false, value: title },
                        price: { error: false, value: subTitle },
                        row: index,
                        setData
                    })
                }
                listItemAvatarStyle={{ mt: 1 }}
                listheader="Voeg nieuwe kledij toe"
                addFunction={() => {return}}
                deleteFunction={() => {return}}
            /> */
}