import { Autocomplete, Button, Chip, Divider, FormControl, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { alertObjState, fbUserObjectState, roleState } from '../../../../store/store';
import { MUIComponents } from '../../../muiComponents';

const TagSelector = ({ setAnchorEl, updateDocument, selectedFile, submitNewTags, refresh, tagSuggestions, setTagSuggestions, collectSuggestions, deleteTagOption }: {
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null | undefined>>,
    updateDocument: (documentInput: any) => Promise<void>,
    selectedFile: any,
    submitNewTags: (tagsInput: Array<string>) => Promise<void>,
    refresh: (shouldRefresh: boolean) => Promise<void>,
    tagSuggestions: Array<string>,
    setTagSuggestions: (tagStringArray: Array<string>) => void,
    collectSuggestions: () => Promise<void>,
    deleteTagOption: (tagToDelete: string) => Promise<void>
}) => {

    //Global states
    const [_, setAlertObj] = useRecoilState(alertObjState);
    const fbUserObject = useRecoilValue(fbUserObjectState);
    const roleStateString = useRecoilValue(roleState);

    // States
    const [loading, setLoading] = useState<boolean>(false)
    const [activeTags, setActiveTags] = useState<string[]>([...selectedFile?.ff_tags?.raw ?? []])
    const [newTags, setNewTags] = useState<string[]>([])
    const [tagsInput, setTagsInput] = useState<string>("")
    const [tagAutoHasOptions, setTagAutoHasOptions] = useState<boolean>(false) // Check if there are suggestions available for the current input


    useEffect(() => {
        if (!tagSuggestions || tagSuggestions.length <= 0) {
            setLoading(true)
            suggestionHandler()
        }
    }, [])

    const suggestionHandler = async () => {
        await collectSuggestions()
        setLoading(false)
    }

    const close = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) e.preventDefault()
        setAnchorEl(null)
    }

    const handleChange = (_: object, value: any) => {
        setActiveTags(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        clearInput()
    };

    const handleConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setLoading(true)

        //Update document & its active tags
        await updateDocument({
            id: selectedFile?.id?.raw,
            ff_tags: activeTags
        })

        //TODO: submit w/ analytics data?
        const newTagsToSubmit = newTags.filter(tag => activeTags.includes(tag))
        if (newTagsToSubmit && newTagsToSubmit.length > 0)
            await submitNewTags(newTagsToSubmit)

        close()
        //Temp delay for elastic procession assurance
        await new Promise(r => setTimeout(r, 500));
        setLoading(false)
        refresh(false)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e?.preventDefault()

        const input = e?.target?.value
        setTagsInput(input?.toLowerCase())

        setTagAutoHasOptions(tagSuggestions.some(option => option.toLowerCase().includes(input.toLowerCase())))
    }

    const clearInput = () => {
        setTagsInput('')
    }

    const addNewTag = (e?: React.MouseEvent<HTMLButtonElement>) => {
        e?.preventDefault()
        //Add to tag options
        setTagSuggestions([...tagSuggestions, tagsInput])
        setNewTags([...newTags, tagsInput])
        //Add to selected tags
        setActiveTags([...activeTags, tagsInput]);

        clearInput()
    }

    const handleInputEnter = (e: React.KeyboardEvent) => {
        const hasExactMatch = tagSuggestions.some(option => option.toLowerCase() === tagsInput)
        //add new if no match and no options or no exact match
        if (e.key === "Enter" && tagsInput !== '' && (!tagAutoHasOptions || (tagAutoHasOptions && !hasExactMatch)))
            addNewTag()

        const filteredSuggestions = tagSuggestions.filter(option => option.toLowerCase().includes(tagsInput.toLowerCase()))
        if (e.key === "Enter" && filteredSuggestions.length === 1 && filteredSuggestions[0] === tagsInput) {
            //Only one tag left, should add on enter & exact match!
            setActiveTags([...activeTags, filteredSuggestions[0]])
            clearInput()
        }
    }

    const noOptionButton = (
        <>
            {tagsInput !== '' ?
                <MUIComponents.Buttons.IconButton id='TagSelector' style={{ padding: 5, marginLeft: 2 }} onClick={addNewTag}>
                    <MUIComponents.Icons.AddIcon style={{ width: 20, height: 20 }} />
                </MUIComponents.Buttons.IconButton> :
                <p>Geen verdere suggesties beschikbaar</p>
            }
        </>
    )

    const handleActiveTagDelete = (_: React.MouseEvent<HTMLButtonElement>, tag: string) => {
        setActiveTags(activeTags.filter(activeTag => activeTag !== tag))
    }

    const handleSuggestionDeletion = async (option: string) => {
        setLoading(true)
        await deleteTagOption(option)
        await collectSuggestions()
        setLoading(false)
    }

    return (
        <div style={{ width: 400, height: 'auto', padding: 10, paddingTop: 0, borderLeft: '5px solid #11545A', overflow: 'auto' }}>
            {loading && <MUIComponents.LinearLoader color="secondary" style={{ marginLeft: -10, marginRight: -10 }} />}

            <FormControl sx={{ m: 1, width: 375, marginTop: 2 }}>
                {/* TODO: Create this in MUI wrappers */}
                <Autocomplete
                    id="tags-outlined"
                    multiple
                    value={activeTags}
                    options={tagSuggestions.sort()}
                    getOptionLabel={(option: string) => option}
                    filterSelectedOptions
                    noOptionsText={noOptionButton}
                    disabled={loading}
                    onChange={handleChange}
                    renderOption={(props, option: string) => {
                        return (
                            <li style={{ width: '100%' }}>
                                <span {...props} style={{ width: 'calc(100% - 40px)', float: 'left' }}>{option}</span>
                                {roleStateString == 'admin' && <MUIComponents.Buttons.IconButton onClick={() => handleSuggestionDeletion(option)} style={{ width: 30, height: 30, float: 'right', margin: 0, marginRight: 5, marginTop: 0, color: 'rgba(255, 0, 0, 0.3)', background: 'rgba(20, 88, 100, 0.1)' }}><MUIComponents.Icons.DeleteIcon style={{ color: "#11545A" }} /></MUIComponents.Buttons.IconButton>}
                            </li>
                        )
                    }}
                    renderInput={(params) => {
                        params.inputProps.value = tagsInput

                        return (<TextField
                            {...params}
                            label="Tags"
                            onChange={handleInputChange}
                            onKeyDown={handleInputEnter}
                        />)
                    }}
                    renderTags={(tagValue: string[]) =>
                        tagValue.map((value: string, index: number) => (
                            <Chip
                                key={`${index}_${value}`}
                                label={value}
                                onDelete={(e) => handleActiveTagDelete(e, value)}
                                disabled={loading}
                                sx={{
                                    '& .MuiChip-deleteIcon': {
                                        color: 'lightgray',
                                    },
                                    '& .MuiChip-deleteIcon: hover': {
                                        color: 'rgb(223, 240, 239)',
                                    }
                                }}
                                style={{
                                    marginLeft: 2,
                                    marginTop: 2,
                                    backgroundColor: '#11545A',
                                    color: 'white'
                                }}
                            />
                        ))
                    }
                />
            </FormControl>

            <Divider style={{ marginTop: 2, marginBottom: 15 }} />

            <span style={{ float: 'right', marginBottom: 10 }}>
                <Button size="small" style={{ marginRight: 5, color: '#11545A', border: '1px solid #11545A' }} variant="outlined" onClick={(e) => close(e)}>Annuleren</Button>
                <Button size="small" style={{ backgroundColor: '#11545A', color: 'white' }} variant="contained" disabled={loading} onClick={(e) => handleConfirm(e)}>Bevestigen</Button>
            </span>
        </div>
    )
}

export default TagSelector