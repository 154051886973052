import MuiBottomNavigation, { BottomNavigationProps as MuiBottomNavigationProps } from '@mui/material/BottomNavigation'
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction'
import theme from '../../theme/theme'
import { noop } from '../../utils/utils'

interface BottomNavigationProps extends MuiBottomNavigationProps {
    // you can declare custom props in here
    items?: { label: string; icon: JSX.Element; value: string }[]
}

export const BottomNavigation = ({
    onChange = noop,
    sx = {
        width: 500
    },
    showLabels = false,
    items = [],
    ...props
}: BottomNavigationProps) => (
    <MuiBottomNavigation
        sx={{
            ...sx,
            '& .Mui-selected, .Mui-selected > svg': {
                color: `${theme.palette.primary.main} !important`
            }
        }}
        showLabels={showLabels}
        onChange={onChange}
        {...props}
    >
        {items.map((x) => (
            <MuiBottomNavigationAction
                key={x.label}
                label={x.label}
                value={x.value}
                icon={x.icon}
            />
        ))}
    </MuiBottomNavigation>
)

// Example of this component with props:
{
    /* <MUIComponents.BottomNavigation
                items={[
                    {
                        label: 'Home',
                        value: 'Home',
                        icon: <MUIComponents.Icons.AccountBoxOutlinedIcon />
                    },
                    {
                        label: 'Next',
                        value: 'Next',
                        icon: <MUIComponents.Icons.ArrowForwardIcon />
                    },
                    {
                        label: 'Previous',
                        value: 'Previous',
                        icon: <MUIComponents.Icons.SaveIcon />
                    }
                ]}
                showLabels={true}
                onChange={(e, newValue) => {
                    setFoo(newValue)
                }}
                value={foo}
            /> */
}