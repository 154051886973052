import { atom } from 'recoil'
import { AlertObjectProps } from '../types'
import { Language } from '../types/GQLTypes'

export const fbUserObjectState = atom<any>({
  key: 'fbUserObject',
  default: {},
  dangerouslyAllowMutability: true,
})

export const alertObjState = atom<AlertObjectProps>({
  key: 'alertObj',
  default: {},
  dangerouslyAllowMutability: true,
})

export const languagesState = atom<Language[]>({
  key: 'languages',
  default: [],
})

export const roleState = atom<string>({
  key: 'role',
  default: 'user',
})

export const positionState = atom<string>({
  key: 'position',
  default: '',
})

export const invoiceMergerState = atom<string>({
  key: 'invoiceMerger',
  default: '',
})

export const messageTypeState = atom<string>({
  key: 'messageType',
  default: 'Mededeling',
})

export const startDateState = atom<string>({
  key: 'startDate',
  default: `${new Date().getDate().toString().padStart(2, '0')}/${(new Date().getMonth() + 1).toString().padStart(2, '0')}/${new Date().getFullYear()}`,
})

export const endDateState = atom<string>({
  key: 'endDate',
  default: `${new Date().getDate().toString().padStart(2, '0')}/${(new Date().getMonth() + 1).toString().padStart(2, '0')}/${new Date().getFullYear()}`,
})

export const collectedUserInfoState = atom<boolean>({
  key: 'collectedUserInfo',
  default: false,
})