import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'
import App from './components/app/App'
import './index.css'
import theme from './theme/theme'
import { MUIProviders, MUIFns } from './components/muiComponents'
import reportWebVitals from './reportWebVitals'
import AlertProvider from './providers/AlertProvider'
import ReactGA from "react-ga4";
import { getCookieConsentValue } from "react-cookie-consent";

const cookieConsentValue = getCookieConsentValue()
if (cookieConsentValue == "true") {
  ReactGA.initialize([
    {
      trackingId: "GTM-W7L7C7TD"
    },
    {
      trackingId: "G-R7KW7FWDL4"
    }
  ]);
  ReactGA.set({
    anonymizeIp: true
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <MUIProviders.LocalizationProvider dateAdapter={MUIFns.AdapterDateFns}>
          <AlertProvider>
            <App />
          </AlertProvider>
        </MUIProviders.LocalizationProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()