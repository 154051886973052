import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
// TODO: replace with the mui wrapper (already foreseen in another branch, will do it after that merge)
import Checkbox from '@mui/material/Checkbox'
import styled from 'styled-components'
import { noop, genericFontSize } from '../../utils/utils'

const StyledFormControl = styled(FormControl)`
    margin-top: 16px !important;
    margin-bottom: 8px !important;

    & label.Mui-focused {
        color: ${(props) => props.theme.palette.primary.main};
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.palette.primary.main};
        }
    }
`

interface SelectProps {
    fullWidth?: boolean
    labelContent?: string
    menuItems: { value: string; label: string; icon?: JSX.Element }[]
    errorText?: string
}

export const SingleSelect = ({
    fullWidth = true,
    labelId = 'single-select-label',
    id = 'single-select',
    onChange = noop,
    value = '',
    required = false,
    menuItems = [],
    labelContent = '',
    ...props
}: SelectProps & MuiSelectProps) => (
    <StyledFormControl required={required} fullWidth={fullWidth} error={props.error}>
        <InputLabel style={{ fontSize: genericFontSize }} id={labelId}>
            {labelContent}
        </InputLabel>
        <Select
            label={labelContent}
            labelId={labelId}
            id={id}
            value={value}
            onChange={onChange}
            inputProps={{ style: { fontSize: genericFontSize } }}
            style={{ width: '100%', fontSize: genericFontSize }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            renderValue={(value) => (
                <span>{menuItems.find((x) => x.value === value)?.label as React.ReactNode}</span>
            )}
            {...props}
        >
            {menuItems.map((menuItem) => (
                <MenuItem
                    key={menuItem.label}
                    value={menuItem.value}
                    {...(menuItem.icon && {
                        sx: { display: 'flex', justifyContent: 'space-between' }
                    })}
                >
                    {menuItem.label}
                    {menuItem.icon}
                </MenuItem>
            ))}
        </Select>
        {props.error && props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
    </StyledFormControl>
)

export const MultiSelect = ({
    fullWidth = true,
    labelId = 'multi-select-label',
    id = 'multi-select',
    onChange = noop,
    value = [],
    required = false,
    menuItems = [],
    labelContent = '...',
    ...props
}: SelectProps & MuiSelectProps) => (
    <StyledFormControl required={required} fullWidth={fullWidth} error={props.error}>
        <InputLabel style={{ fontSize: genericFontSize }} id={labelId}>
            {labelContent}
        </InputLabel>
        <Select
            multiple
            labelId={labelId}
            label={labelContent}
            id={id}
            value={value}
            onChange={onChange}
            inputProps={{ style: { fontSize: genericFontSize } }}
            style={{ width: '100%', fontSize: genericFontSize }}
            renderValue={(value) => (
                <span>
                    {
                        menuItems
                            .filter((x) => (value as string[]).includes(x.value))
                            .map((x) => x.label)
                            .join(', ') as React.ReactNode
                    }
                </span>
            )}
            {...props}
        >
            {menuItems.map((menuItem) => (
                <MenuItem
                    key={menuItem.label}
                    value={menuItem.value}
                    {...(menuItem.icon && {
                        sx: { display: 'flex', justifyContent: 'space-between' }
                    })}
                >
                    <Checkbox checked={(value as string).indexOf(menuItem.value) > -1} />
                    {menuItem.label}
                    {menuItem.icon}
                </MenuItem>
            ))}
        </Select>
        {props.error && props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
    </StyledFormControl>
)

// Example of the SingleSelect component with props:
{
    /* <MUIComponents.Select.SingleSelect
                labelId="bikeType"
                id="select-bike-type"
                name="bikeType"
                required
                labelContent="Fiets model"
                error={bikeTypeError}
                value={bikeType?.value}
                menuItems={bikeTypes.map((type) => ({
                    value: type.id ?? '',
                    label: type.name ?? '',
                    icon: <MUIComponents.Icons.AccountBoxOutlinedIcon />
                }))}
                onChange={(e) => {
                    const newBikeType = {
                        value: e.target.value as string,
                        label: bikeTypes.find((type) => type.id === e.target.value)?.name ?? ''
                    }

                    setBikeType(newBikeType)
                    setBikeTypeError(false)
                    setData({
                        ...props.data,
                        bike_type: newBikeType
                    })

                    return
                }}
                {...(bikeTypeError && {
                    errorText: 'Het selecteren van een fiets model is verplicht.'
                })}
            /> */
}

// Example of the MultiSelect component with props:
{
    /* <MUIComponents.Select.MultiSelect
                labelId="type"
                id="select-type"
                name="type"
                required
                labelContent="Type"
                error={statute.error}
                value={statute.value}
                menuItems={statutes}
                onChange={(e) => {
                    if (e.target.value) {
                        setStatute({
                            error: false,
                            value: e.target.value
                        })
                    }

                    return
                }}
                {...(statute.error && {
                    errorText: 'Het invullen van uw werknemers statuut is verplicht.'
                })}
            /> */
}