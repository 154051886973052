import MuiBreadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs'
import { MUIComponents } from '.'

export const Breadcrumbs = ({
    children,
    separator = <MUIComponents.Icons.NavigateNextIcon sx={{ fontSize: 'small' }} />,
    ...props
}: BreadcrumbsProps) => (
    <MuiBreadcrumbs {...props} separator={separator}>
        {children}
    </MuiBreadcrumbs>
)

// Example of this component with props:
{
    /* <Breadcrumbs separator="-" aria-label="breadcrumb">
        {children}
      </Breadcrumbs> */
}

// OR

{
    /* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
    {children}
</Breadcrumbs> */
}

// example of the children prop
// const children = [
//     <MUIComponents.Link
//         underline="hover"
//         key="1"
//         color="inherit"
//         href="/"
//         onClick={() => {
//             return
//         }}
//     >
//         MUI
//     </MUIComponents.Link>,
//     <MUIComponents.Link
//         underline="hover"
//         key="2"
//         color="inherit"
//         href="/material-ui/getting-started/installation/"
//         onClick={() => {
//             return
//         }}
//     >
//         Core
//     </MUIComponents.Link>,
//     <MUIComponents.Typography key="3" color="text.primary">
//         Breadcrumb
//     </MUIComponents.Typography>
// ]