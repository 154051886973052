const mimeTypesList = require('./mimeTypesList.json')

const collectImgPath = (imgName: string): string => {
    if (imgName.charAt(0) === '.')
        imgName = imgName.substring(1)

    return `/32px/${imgName}.png`
}

export const MimeTypeHandler = (mimeType: string): string => {
    //return collectImgPath(mimeTypesList[0][0])
    if (!mimeType || mimeType === '')
        return collectImgPath('_blank')

    const mimeTypeFromList = mimeTypesList.find((typeItem: Array<string>) => {
        return typeItem[2] === mimeType || typeItem[0] === `.${mimeType}`
    })

    if (mimeTypeFromList && mimeTypeFromList[0] && mimeTypeFromList[0] !== '')
        return collectImgPath(mimeTypeFromList[0])

    //Default return
    return collectImgPath('_blank')
}