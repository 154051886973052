import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography'
import React, { ElementType } from 'react'

interface TypographyProps extends MuiTypographyProps {
    // you can declare custom props in here
    component?: ElementType
}

export const Typography = React.forwardRef(
    ({ align = 'center', children, ...props }: TypographyProps, ref: React.Ref<HTMLElement>) => (
        <MuiTypography sx={{ mt: 1 }} align={align} ref={ref} {...props}>
            {children}
        </MuiTypography>
    )
)

// Example of this component with props:
{
    /* <MUIComponents.Typography sx={{ mb: 3 }} variant="h5">
                Alle berekeningen
            </MUIComponents.Typography> */
}