import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton'
import MuiToggleButtonGroup, {
    ToggleButtonGroupProps as MuiToggleButtonGroupProps
} from '@mui/material/ToggleButtonGroup'
import styled from 'styled-components'
import { noop } from '../../utils/utils'

const StyledToggleButton = styled(MuiToggleButton)`
    &.Mui-selected {
        color: ${(props) => props.theme.palette.primary.contrastText} !important;
        background-color: ${(props) => props.theme.palette.primary.main} !important;
    }
`

export const ToggleButton = ({
    // assign default values here
    onClick = noop,
    disabled = false,
    disableRipple = false,
    disableFocusRipple = false,
    size = 'small',
    ...props
}: ToggleButtonProps) => (
    <StyledToggleButton
        onClick={onClick}
        disabled={disabled}
        disableRipple={disableRipple}
        disableFocusRipple={disableFocusRipple}
        size={size}
        {...props}
    />
)

interface ToggleButtonGroupProps extends MuiToggleButtonGroupProps {
    // you can declare custom props in here
    buttons: { value: string; label: string; icon: JSX.Element }[]
}

export const ToggleButtonGroup = ({
    // assign default values here
    onChange = noop,
    disabled = false,
    size = 'small',
    exclusive = false,
    buttons = [],
    orientation = 'horizontal',
    ...props
}: ToggleButtonGroupProps) => (
    <MuiToggleButtonGroup
        disabled={disabled}
        exclusive={exclusive}
        onChange={onChange}
        size={size}
        orientation={orientation}
        {...props}
    >
        {buttons.map((button) => (
            <ToggleButton key={button.value} value={button.value} aria-label={button.label}>
                {button.icon}
            </ToggleButton>
        ))}
    </MuiToggleButtonGroup>
)

// Example of the ToggleButton component with props:
// STATE:
// const [selected, setSelected] = useState(false)
{
    /* <MUIComponents.ToggleButton
                selected={selected}
                onChange={() => {
                    setSelected(!selected)
                }}
                value="select"
                aria-label="left aligned"
            >
                <MUIComponents.Icons.ExpandMoreIcon />
            </MUIComponents.ToggleButton> */
}

// Example of the ToggleButtonGroup component with props:
// ! IMPORTANT: when the exclusive prop is FALSE, the value (in onChange) will become an array -> multiple buttons are allowed to be active
// ! IMPORTANT: when the exclusive prop is TRUE, the value (in onChange) will become a string -> only 1 button is allowed to be active
// STATE:
// const [alignment, setAlignment] = useState(['Add', 'Cancel'])
{
    /* <MUIComponents.ToggleButtonGroup
    buttons={[
        {
            label: 'Calculate',
            value: 'Calculate',
            icon: <MUIComponents.Icons.CalculateIcon />
        },
        { label: 'Add', value: 'Add', icon: <MUIComponents.Icons.AddCircleIcon /> },
        { label: 'Cancel', value: 'Cancel', icon: <MUIComponents.Icons.CancelIcon /> }
    ]}
    aria-label="left aligned"
    value={alignment}
    onChange={(event, newAlignment) => {
        setAlignment(newAlignment)
    }}
/> */
}