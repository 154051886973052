import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import styled from 'styled-components'
import { noop } from '../../utils/utils'
import { ElementType } from 'react'

const StyledLink = styled(MuiLink) <LinkProps>`
    color: ${(props) => props.colorToOverwrite ?? props.theme.palette.primary.main} !important;
    cursor: pointer;
`

interface LinkProps extends MuiLinkProps {
    // you can declare custom props in here
    component?: ElementType
    colorToOverwrite?: string
}

export const Link = ({ href, onClick = noop, variant, children, ...props }: LinkProps) => (
    <StyledLink href={href} onClick={onClick} variant={variant} {...props}>
        {children}
    </StyledLink>
)

// Example of this component with props:
{
    /* <MUIComponents.Link
    underline="hover"
    variant="body2"
    href="/"
    onClick={() => {
        return
    }}
>
    MUI
</MUIComponents.Link> */
}