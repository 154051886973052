import { Button, Divider, SelectChangeEvent } from '@mui/material'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { fbUserObjectState } from '../../../store/store';
import { ButtonMouseEvent, UserMgmtOptions } from '../../../types';
import { MUIComponents } from '../../muiComponents';

const EditDialog = ({ setAnchorEl, user, collectUsers, OPTIONS }: {
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null | undefined>>,
    user: any,
    collectUsers: () => void,
    OPTIONS: UserMgmtOptions
}) => {

    //Recoil
    const fbUserObject = useRecoilValue(fbUserObjectState);

    // States
    const [loading, setLoading] = useState<boolean>(false)
    const [roleValue, setroleValue] = useState<string>('')
    const [teamValue, setTeamValue] = useState<string>('')
    const [positionValue, setPositionValue] = useState<string>('')
    const [invoiceMergerValue, setInvoiceMergerValue] = useState<string>('')

    useEffect(() => {
        setroleValue(user?.role ?? 'user')
        setTeamValue(user?.team ?? '')
        setPositionValue(user?.position ?? '')
        setInvoiceMergerValue(user?.invoiceMerger ?? '')
    }, [])

    const close = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) e.preventDefault()
        setAnchorEl(null)
    }

    const updateUser = async () => {
        try {
            const userUpdateObj = {
                ...user,
                team: teamValue ?? '',
                role: roleValue ?? 'user',
                position: positionValue ?? '',
                invoiceMerger: invoiceMergerValue ?? ''
            }

            await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                action: 'updateUser',
                userInput: userUpdateObj
            }, {
                headers: {
                    uid: fbUserObject?.providerData[0]?.uid ?? '',
                    'access-token': fbUserObject?.accessToken ?? ''
                }
            })
        } catch (err) {
            //TODO: Alert
        }
    }

    const confirm = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e?.preventDefault()

        setLoading(true)
        await updateUser()
        collectUsers()
        setAnchorEl(null)
        setLoading(false)
    }

    const handleSelectChange = (e: SelectChangeEvent<unknown>, prop: string) => {
        switch (prop) {
            case 'role': {
                setroleValue((e?.target?.value as ButtonMouseEvent)?.toString() ?? 'user')
                break;
            }
            case 'team': {
                setTeamValue((e?.target?.value as ButtonMouseEvent)?.toString() ?? '')
                break;
            }
            case 'position': {
                setPositionValue((e?.target?.value as ButtonMouseEvent)?.toString() ?? '')
                break;
            }
            case 'invoiceMerger': {
                setInvoiceMergerValue((e?.target?.value as ButtonMouseEvent)?.toString() ?? '')
                break;
            }
            default:
                break;
        }
    }

    return (
        <div style={{ width: 400, height: 'auto', maxHeight: '50vh', padding: 10, paddingTop: 0, paddingBottom: 0, borderLeft: '5px solid #11545A', overflow: 'auto' }}>
            {loading && <MUIComponents.LinearLoader color="secondary" style={{ marginLeft: -10, marginRight: -10 }} />}
            <p>{user?.email ?? ''}</p>
            <MUIComponents.Select.SingleSelect
                labelContent='Rol'
                disabled={loading}
                onChange={(e) => handleSelectChange(e, 'role')}
                menuItems={OPTIONS.ROLES ?? []}
                size="small"
                value={roleValue}
            />

            <MUIComponents.Select.SingleSelect
                labelContent='Team'
                disabled={loading}
                onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'team')}
                menuItems={OPTIONS.TEAMS ?? []}
                size="small"
                value={teamValue}
            />

            <MUIComponents.Select.SingleSelect
                labelContent='Functie'
                disabled={loading}
                onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'position')}
                menuItems={OPTIONS.POSITIONS ?? []}
                size="small"
                value={positionValue}
            />

            <MUIComponents.Select.SingleSelect
                labelContent='Facturen samenvoegen'
                disabled={loading}
                onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'invoiceMerger')}
                menuItems={OPTIONS.INVOICEMERGER ?? []}
                size="small"
                value={invoiceMergerValue}
            />

            <div style={{ position: 'sticky', bottom: 0, background: 'white', margin: -10, marginTop: 0, height: 50, zIndex: 9000 }}>
                <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                <span style={{ float: 'right', marginBottom: 10, marginTop: -5, marginRight: 25 }}>
                    <Button size="small" style={{ marginRight: 5, color: '#11545A', border: '1px solid #11545A' }} variant="outlined" onClick={(e) => close(e)}>Annuleren</Button>
                    <Button disabled={loading} size="small" style={{ backgroundColor: '#11545A', color: 'white' }} variant="contained" onClick={(e) => confirm(e)}>Bevestigen</Button>
                </span>
            </div>
        </div>
    )
}

export default EditDialog