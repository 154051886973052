import headerLogo from '../../../assets/headerLogo.png';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useRecoilState, useRecoilValue } from 'recoil';
import { fbUserObjectState, roleState } from '../../../store/store';
import { logout } from './logic';
import { useNavigate } from 'react-router-dom';
import { getInitials } from '../../../utils/utils';

const pages = [
  {
    title: 'Home',
    path: '/home'
  },
  {
    title: 'Documenten',
    path: '/search'
  }
];

const Header = () => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [fbUserObject] = useRecoilState(fbUserObjectState);
  const roleStateString = useRecoilValue(roleState);
  const pageTextFontSize = 16;
  const pageTextFontColor = "#005c6c";

  const settings = [{ label: 'Gebruikers', admin: true, action: () => navigate('/user-management') }, { label: 'Uitloggen', admin: false, action: () => logout() }];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (key: string) => {
    //Close menu
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'white', color: 'black', height: '108px', justifyContent: 'center' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            {pages.slice(0, Math.ceil(pages.length / 2)).map((page) => (
              <Button
                key={page.title}
                sx={{ my: 2, color: 'white', display: 'block' }}
                style={{
                  color: pageTextFontColor,
                  fontSize: pageTextFontSize,
                  fontWeight: 'normal',
                  letterSpacing: '1px'
                }}
                onClick={_ => navigate(page.path)}
              >
                {page.title}
              </Button>
            ))}
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex', marginRight: 16, marginLeft: 16 } }}>
            <img className='hoverableDiv' onClick={() => navigate('/home')} src={headerLogo} style={{
              width: 268
            }} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start' }}>
            {pages.slice(Math.ceil(pages.length / 2)).map((page) => (
              <Button
                key={page.title}
                sx={{ my: 2, color: 'white', display: 'block' }}
                style={{
                  color: pageTextFontColor,
                  fontSize: pageTextFontSize,
                  fontWeight: 'normal',
                  letterSpacing: '1px'
                }}
                onClick={_ => navigate(page.path)}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Instellingen openen">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar style={{ backgroundColor: '#11545A', color: "#FFFFFF" }} >{getInitials(fbUserObject?.displayName)}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <>{(!setting.admin || (setting.admin && roleStateString == 'admin')) && <MenuItem key={setting.label} onClick={(e) => { e?.preventDefault(); setting.action() }}>
                  <Typography textAlign="center" style={{ color: '#444444' }}>{setting.label}</Typography>
                </MenuItem>}</>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;