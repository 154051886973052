import { ClickAwayListenerProps } from "@mui/base";

// Purpose: Detect if a click event happened outside of an element. It listens for clicks that occur somewhere in the document.
export const ClickAwayListener = (props: ClickAwayListenerProps) => (
  <ClickAwayListener {...props} />
)

// Example of this component with props:
{
  /* <MUIComponents.ClickAwayListener onClickAway={handleClickAway}>
{chilren components komen hier}
</MUIComponents.ClickAwayListener>  */
}