import MUITooltip, { TooltipProps } from '@mui/material/Tooltip'

export const Tooltip = ({ arrow = true, ...props }: TooltipProps) => (
    <MUITooltip {...props} arrow={arrow}>
        {props.children}
    </MUITooltip>
)

// Example of this component with props:
{
    /* <MUIComponents.Tooltip title={tooltip}>
                <Text error={props.error}>{props.content}</Text>
            </MUIComponents.Tooltip> */
}