import InputAdornment from '@mui/material/InputAdornment'
import MuiTextField, { StandardTextFieldProps, BaseTextFieldProps } from '@mui/material/TextField'
import styled, { css } from 'styled-components'
import { noop, genericFontSize } from '../../utils/utils'

interface NumberFieldProps extends BaseTextFieldProps {
    onChange?: StandardTextFieldProps['onChange']
    InputProps?: StandardTextFieldProps['InputProps']
    priceField?: boolean
    arrows?: boolean
}

const StyledTextField = styled(MuiTextField)<NumberFieldProps>`
    & .MuiOutlinedInput-input {
        margin: 3px;
        text-overflow: ellipsis;
    }

    ${(props) =>
        !props.arrows &&
        css`
            & .MuiOutlinedInput-input::-webkit-outer-spin-button,
            .MuiOutlinedInput-input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        `}

    & label.Mui-focused {
        color: ${(props) =>
            props.error
                ? props.theme.palette.error.main
                : props.color ?? props.theme.palette.primary.main};
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: ${(props) =>
                props.error
                    ? props.theme.palette.error.main
                    : props.color ?? props.theme.palette.primary.main};
        }
    }
`

// TODO: mobile version with keyboard, checken op dev of het goed is
// TODO: Ook nog alle velden updaten waar nodig (alle prijzen etc ...) (vooral bij de calculations)
export const NumberField = ({
    autoFocus = true,
    fullWidth = true,
    onKeyDown = noop,
    onChange = noop,
    margin = 'normal',
    variant = 'outlined',
    InputProps = { style: { fontSize: genericFontSize } },
    InputLabelProps = { style: { fontSize: genericFontSize } },
    error = false,
    priceField = true,
    arrows = true,
    ...props
}: NumberFieldProps) => {
    return (
        <StyledTextField
            fullWidth={fullWidth}
            autoFocus={autoFocus}
            variant={variant}
            margin={margin}
            InputProps={{
                ...(priceField && {
                    startAdornment: <InputAdornment position="start">€</InputAdornment>
                }),
                inputMode: 'numeric',
                ...InputProps
            }}
            type="number"
            error={error}
            arrows={arrows}
            onKeyDown={onKeyDown}
            InputLabelProps={InputLabelProps}
            onChange={(e) => {
                // !IMPORTANT:
                // this will only trigger an update when the value does not contain a dot -> comma will trigger it
                // there is also a restriction foreseen that will only allow 2 decimal numbers after the comma
                // we make use of the REGEX for this
                // !e.target.value => is to make sure that the value can be cleared
                if (
                    (/^\d+(,\d{0,2})?$/.test(e.target.value) || !e.target.value) &&
                    Number(e.target.value) >= 0
                ) {
                    onChange(e)
                }
            }}
            {...props}
        />
    )
}

// Example of this component with props:
// STATE: const [fieldValue, setFieldValue] = React.useState('')
{
    /* <MUIComponents.NumberField
                value={fieldValue}
                onChange={(e) => {
                    setFieldValue(e.target.value)
                }}
                label="Number"
            /> */
}