import styled from 'styled-components'
import MuiFab, { FabProps as MuiFabProps } from '@mui/material/Fab'
import { noop } from '../../utils/utils'

interface FabProps extends MuiFabProps {
    // you can declare custom props in here
    colorToOverwrite?: string
    backgroundColorToOverwrite?: string
}

const StyledFab = styled(MuiFab) <FabProps>`
    color: ${(props) =>
        props.colorToOverwrite ?? props.theme.palette.primary.contrastText} !important;
    background-color: ${(props) =>
        props.backgroundColorToOverwrite ?? props.theme.palette.primary.main} !important;
`

export const Fab = ({
    // assign default values here
    onChange = noop,
    disabled = false,
    disableFocusRipple = false,
    disableRipple = false,
    size = 'small',
    variant = 'circular',
    ...props
}: FabProps) => (
    <StyledFab
        variant={variant}
        size={size}
        disabled={disabled}
        onChange={onChange}
        disableFocusRipple={disableFocusRipple}
        disableRipple={disableRipple}
        {...props}
    />
)

// Example of this component with props:
{
    /* <MUIComponents.Fab variant="extended" aria-label="add">
                <MUIComponents.Icons.AddCircleIcon sx={{ mr: 1 }} />
                Navigate
            </MUIComponents.Fab> */
}