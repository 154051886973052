import MuiToolbar, { ToolbarProps as MuiToolbarProps } from '@mui/material/Toolbar'
import { CSSProperties } from 'styled-components'

interface ToolbarProps extends MuiToolbarProps {
    // you can declare custom props in here
    style?: CSSProperties
}

export const Toolbar = ({ disableGutters = true, style = {}, ...props }: ToolbarProps) => (
    <MuiToolbar disableGutters={disableGutters} style={style} {...props} />
)

// Example of this component with props:
{
    /* <MUIComponents.Toolbar>
                hier komt dan alles van custom fields
            </MUIComponents.Toolbar> */
}