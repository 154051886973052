import { alpha } from '@mui/material'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import styled from 'styled-components'

const StyledLoader = styled(CircularProgress)`
    &.MuiCircularProgress-root {
        color: ${(props) => props.theme.palette.primary.main};
    }
`

const StyledLinearLoader = styled(LinearProgress)`
    &.MuiLinearProgress-root {
        background-color: ${(props) => alpha(props.theme.palette.primary.main, 0.5)} !important;
    }

    .MuiLinearProgress-bar {
        background-color: ${(props) => props.theme.palette.primary.main} !important;
    }
`

export const Loader = (props: CircularProgressProps) => <StyledLoader {...props} />
export const LinearLoader = (props: LinearProgressProps) => <StyledLinearLoader {...props} />

// Example of the Loader component with props:
{
    /* <MUIComponents.Loader color="inherit" /> */
}

// Example of the LinearLoader component with props:
{
    /* <MUIComponents.LinearLoader color="inherit" /> */
}