import { Avatar, Card, CardContent, CardHeader, Grid, IconButton } from '@mui/material';
import { NewsCardValue } from '../../../../types/index';
import SunEditor from 'suneditor-react';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endDateState, fbUserObjectState, messageTypeState, roleState, startDateState } from '../../../../store/store';
import { getAuth } from 'firebase/auth';
import firebaseApp from '../../../../firebase';
import { useRef } from 'react';
import SunEditorCore from "suneditor/src/lib/core";
import * as FrequentlyUsedWebsite from './FrequentlyUsedWebsite';

const WebsiteNewsCard = ({ cardValue }: { cardValue: NewsCardValue }) => {
    const [, setMessageType] = useRecoilState(messageTypeState);
    const [, setStartDateType] = useRecoilState(startDateState);
    const [, setEndDateType] = useRecoilState(endDateState);
    const roleStateString = useRecoilValue(roleState);
    const fbUserObject = useRecoilValue(fbUserObjectState);
    const auth = getAuth(firebaseApp);
    const editor = useRef<SunEditorCore>();
    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor;
    };
    let leftBorderColor = "#11545A";
    switch (cardValue.messageType) {
        case "Waarschuwing":
            leftBorderColor = "#FFC107";
            break;
        case "Mededeling":
            leftBorderColor = "#11545A";
            break;
        case "Evenement":
            leftBorderColor = "#E91E63";
            break;
    }
    const handleDelete = async () => {
        const element = document.getElementById(cardValue?.id + "FullCard");
        element?.remove();
        await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
            action: 'deleteNews',
            news: cardValue
        }, {
            headers: {
                uid: fbUserObject?.providerData[0]?.uid,
                'access-token': fbUserObject?.accessToken ?? ''
            }
        })
    };
    const handleEdit = async () => {
        FrequentlyUsedWebsite.CardValues(cardValue, editor.current);
        setMessageType(cardValue.messageType);
        if (cardValue.endDate != null) {
            setEndDateType(cardValue.endDate);
        }
        if (cardValue.startDate != null) {
            setStartDateType(cardValue.startDate);
        }
    };

    return (
        <Grid item xs={12} sm={12} md={12} id={cardValue?.id + "FullCard"} style={{ paddingTop: '1rem', paddingRight: '1rem', paddingBottom: '1rem', minWidth: "250px" }} >
            <Grid key={cardValue?.id} container textAlign="center" alignItems="center" justifyContent="center" columns={{ xs: 4, sm: 8, md: 12 }} style={{ border: "1px solid rgba(89, 89, 89, 0.5)", borderLeft: '5px solid ' + leftBorderColor, background: 'white', borderRadius: '5px', height: "100%" }}>
                <Card style={{ width: "100%", height: "100%" }}>
                    {(cardValue.messageType === "Evenement" || cardValue.messageType === "Waarschuwing") ? (
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: "#005c6c" }} aria-label="recipe">
                                    {cardValue.name.substring(0, 1)}
                                </Avatar>
                            }
                            action={
                                auth.currentUser?.email === cardValue.email || roleStateString === 'admin' || roleStateString === 'globalnews' ? (
                                    <>
                                        <IconButton aria-label="delete" onClick={handleDelete}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton aria-label="edit" onClick={handleEdit}>
                                            <ModeEditIcon />
                                        </IconButton>
                                    </>
                                ) : null
                            }
                            title={cardValue.name}
                            subheader={cardValue.startDate + " - " + cardValue.endDate}>
                        </CardHeader>
                    ) : (
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: "#005c6c" }} aria-label="recipe">
                                    {cardValue.name.substring(0, 1)}
                                </Avatar>
                            }
                            action={
                                auth.currentUser?.email === cardValue.email || roleStateString === 'admin' || roleStateString === 'globalnews' ? (
                                    <>
                                        <IconButton aria-label="delete" onClick={handleDelete}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton aria-label="edit" onClick={handleEdit}>
                                            <ModeEditIcon />
                                        </IconButton>
                                    </>
                                ) : null
                            }
                            title={cardValue.name}
                            subheader={cardValue.date}>
                        </CardHeader>
                    )}
                    <CardContent style={{ padding: 0 }}>
                        <div style={{ textAlign: 'left' }}>
                            <SunEditor
                                getSunEditorInstance={getSunEditorInstance}
                                defaultValue={cardValue.newsContent}
                                hideToolbar={true}
                                readOnly={true}
                                setOptions={{
                                    resizingBar: false,
                                    height: "auto",
                                    minHeight: "100px",
                                    maxWidth: "100vw",
                                    showPathLabel: false,
                                }}
                            />
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
export default WebsiteNewsCard;