import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { Badge, Box, Button, Divider, IconButton, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { FilterListOff } from '@mui/icons-material'

const DateRangeFilter = ({ setAnchorEl, filterConfig, setFilterConfig }: {
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null | undefined>>,
    filterConfig: any,
    setFilterConfig: React.Dispatch<React.SetStateAction<any | null>>
}) => {

    // States
    const [tabsValue, setTabsValue] = useState<number>(0)
    const [createFromValue, setCreateFromValue] = useState(filterConfig?.created_at?.from ? new Date(filterConfig.created_at.from) : null)
    const [createEndValue, setCreateEndValue] = useState(filterConfig?.created_at?.to ? new Date(filterConfig.created_at.to) : null)
    const [updateFromValue, setUpdateFromValue] = useState(filterConfig?.last_updated?.from ? new Date(filterConfig.last_updated.from) : null)
    const [updateEndValue, setUpdateEndValue] = useState(filterConfig?.last_updated?.to ? new Date(filterConfig.last_updated.to) : null)
    const [shouldConfirm, setShouldConfirm] = useState<boolean>(false)

    useEffect(() => {
        if (shouldConfirm) {
            confirm()
            setShouldConfirm(false)
        }
    }, [shouldConfirm])

    const close = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setCreateFromValue(null)
        setCreateEndValue(null)
        setUpdateFromValue(null)
        setUpdateEndValue(null)
        setAnchorEl(null)
    }

    const confirm = (e?: React.MouseEvent<HTMLButtonElement>) => {
        e?.preventDefault()

        // "2021-09-13T14:33:51+00:00"
        const newFilterConfig = {
            ...filterConfig,
            created_at: {
                from: createFromValue ? new Date(createFromValue.getTime() - (createFromValue.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + 'T00:00:00+00:00' : null,
                to: createEndValue ? new Date(createEndValue.getTime() - (createEndValue.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + 'T00:00:00+00:00' : null
            },
            last_updated: {
                from: updateFromValue ? new Date(updateFromValue.getTime() - (updateFromValue.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + 'T00:00:00+00:00' : null,
                to: updateEndValue ? new Date(updateEndValue.getTime() - (updateEndValue.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + 'T00:00:00+00:00' : null
            }
        }

        //Creation prop clearing
        if (!createFromValue && !createEndValue)
            delete newFilterConfig.created_at
        else {
            if (!createFromValue)
                delete newFilterConfig.created_at.from
            if (!createEndValue)
                delete newFilterConfig.created_at.to
        }

        //Update prop clearing
        if (!updateFromValue && !updateEndValue)
            delete newFilterConfig.last_updated
        else {
            if (!updateFromValue)
                delete newFilterConfig.last_updated.from
            if (!updateEndValue)
                delete newFilterConfig.last_updated.to
        }

        setFilterConfig({ ...newFilterConfig })

        //Close
        setAnchorEl(null)
    }

    const handlePreset = (e: React.MouseEvent<HTMLButtonElement>, source: string) => {
        e.preventDefault()

        switch (source) {
            case 'always': {
                isOnCreateDate ? setCreateFromValue(null) : setUpdateFromValue(null)
                isOnCreateDate ? setCreateEndValue(null) : setUpdateEndValue(null)
                setShouldConfirm(true)
                break;
            }
            case 'current_year': {
                const currentYearDate = new Date()
                const currentYearFirstDate = new Date(currentYearDate.getFullYear(), 0, 1)
                const currentYearLastDate = new Date(currentYearDate.getFullYear(), 11, 31)
                isOnCreateDate ? setCreateFromValue(currentYearFirstDate) : setUpdateFromValue(currentYearFirstDate)
                isOnCreateDate ? setCreateEndValue(currentYearLastDate) : setUpdateEndValue(currentYearLastDate)
                setShouldConfirm(true)
                break;
            }
            case 'current_month': {
                const currentMonthDate = new Date()
                const currentMonthFirstDate = new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth(), 1)
                const currentMonthLastDate = new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth() + 1, 0)
                isOnCreateDate ? setCreateFromValue(currentMonthFirstDate) : setUpdateFromValue(currentMonthFirstDate)
                isOnCreateDate ? setCreateEndValue(currentMonthLastDate) : setUpdateEndValue(currentMonthLastDate)
                setShouldConfirm(true)
                break;
            }
        }
    }

    const handleTabsChange = (e: React.SyntheticEvent, newTab: number) => {
        e.preventDefault()
        setTabsValue(newTab);
    }

    const isOnCreateDate = tabsValue === 0

    const resetFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        setCreateFromValue(null)
        setCreateEndValue(null)
        setUpdateFromValue(null)
        setUpdateEndValue(null)

        setShouldConfirm(true)
    }

    return (
        <div style={{ width: 400, padding: 10, paddingTop: 0, borderLeft: '5px solid #11545A', overflow: 'auto', paddingBottom: 0, marginBottom: -1 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
                <Tabs style={{ height: 10, marginTop: -10 }} value={tabsValue} onChange={handleTabsChange} textColor="inherit" TabIndicatorProps={{ sx: { backgroundColor: "#11545A" } }}>
                    <Tab style={{ width: '50%', marginTop: -5 }} label={<div style={{ display: 'flex' }}><p>Creatie</p><Badge color="success" variant="dot" style={{ visibility: filterConfig?.created_at ? 'visible' : 'hidden', position: "relative", top: 12, left: 2 }} /></div>} />
                    <Tab style={{ width: '50%', marginTop: -5 }} label={<div style={{ display: 'flex' }}><p>Bewerking</p><Badge color="success" variant="dot" style={{ visibility: filterConfig?.last_updated ? 'visible' : 'hidden', position: "relative", top: 12, left: 2 }} /></div>} />
                </Tabs>
            </Box>
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Start datum"
                        value={isOnCreateDate ? createFromValue : updateFromValue}
                        onChange={(newValue: any) => {
                            isOnCreateDate ? setCreateFromValue(newValue) : setUpdateFromValue(newValue)
                        }}
                        slotProps={{ textField: { style: { width: 190, marginRight: 20 } } }}
                        format="dd/MM/yyyy"
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Eind datum"
                        value={isOnCreateDate ? createEndValue : updateEndValue}
                        onChange={(newValue: any) => {
                            isOnCreateDate ? setCreateEndValue(newValue) : setUpdateEndValue(newValue)
                        }}
                        slotProps={{ textField: { style: { width: 190 } } }}
                        format="dd/MM/yyyy"
                    />
                </LocalizationProvider>
            </div>

            <Divider style={{ marginTop: 10, marginBottom: 2, padding: 5 }} />

            <Button onClick={(e) => handlePreset(e, 'always')} style={{ color: '#11545A' }} variant="text">Altijd (standaard)</Button>
            <Divider style={{ marginTop: 2, marginBottom: 2 }} />
            <Button onClick={(e) => handlePreset(e, 'current_year')} style={{ color: '#11545A' }} variant="text">Huidig jaar</Button>
            <Divider style={{ marginTop: 2, marginBottom: 2 }} />
            <Button onClick={(e) => handlePreset(e, 'current_month')} style={{ color: '#11545A' }} variant="text">Huidige maand</Button>

            <div style={{ position: 'sticky', bottom: 0, background: 'white', margin: -10, height: 50, zIndex: 9000 }}>
                <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                <IconButton color="primary" style={{ color: '#11545A', marginTop: -7, marginLeft: 5 }} aria-label="Filters herstellen" onClick={e => resetFilters(e)}>
                    <FilterListOff style={{ height: 20 }} />
                </IconButton>
                <span style={{ float: 'right', marginBottom: 10, marginTop: -5, marginRight: 25 }}>
                    <Button size="small" style={{ marginRight: 5, color: '#11545A', border: '1px solid #11545A' }} variant="outlined" onClick={(e) => close(e)}>Annuleren</Button>
                    <Button size="small" style={{ backgroundColor: '#11545A', color: 'white' }} variant="contained" onClick={(e) => confirm(e)}>Bevestigen</Button>
                </span>
            </div>
        </div>
    )
}

export default DateRangeFilter