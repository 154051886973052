import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip'
import { TooltipProps } from '@mui/material/Tooltip'
import styled from 'styled-components'
import { MUIComponents } from '.'

interface ChipProps extends MuiChipProps {
    // you can declare custom props in here
    tooltip?: { placement?: TooltipProps['placement']; content: TooltipProps['title'] }
}

const StyledChip = styled(MuiChip)`
    background-color: ${(props) => props.theme.palette.primary.main} !important;
    color: ${(props) => props.theme.palette.primary.contrastText} !important;
`

export const Chip = (props: ChipProps) => (
    <MUIComponents.Tooltip
        placement={props.tooltip?.placement ?? 'bottom'}
        title={props.tooltip?.content ?? ''}
    >
        <StyledChip {...props} />
    </MUIComponents.Tooltip>
)

// Example of this component with props:
{
    /* <MUIComponents.Chip
                sx={{
                    margin: '5px !important'
                }}
                label="Foo"
                tooltip={{ content: 'Hellow', placement: 'top' }}
            /> */
}