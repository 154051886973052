import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import firebaseApp from '../../firebase'
import { PrivateRoute } from '../../route/privateRoute'
import { collectedUserInfoState, fbUserObjectState, invoiceMergerState, positionState, roleState } from '../../store/store'
import './App.css'
import Login from '../pages/login/Login'
import Header from '../containers/header/Header'
import Search from '../pages/search/Search'
import Home from '../pages/home/Home'
import UserManagement from '../pages/userManagement/UserManagement'
import axios from 'axios'
import ReactGA from 'react-ga4'
import CookieConsent from 'react-cookie-consent'

const App = () => {
  const [fbUserObject, setFbUserObject] = useRecoilState(fbUserObjectState);
  const [, setRoleState] = useRecoilState(roleState)
  const [, setPositionState] = useRecoilState(positionState)
  const [, setInvoiceMergerState] = useRecoilState(invoiceMergerState)
  const [hasCollectedUserInfo, setCollectedUserInfo] = useRecoilState(collectedUserInfoState)

  useEffect(() => {
    const auth = getAuth(firebaseApp)

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setFbUserObject({ ...user, accessToken: await user.getIdToken() })
      } else {
        setFbUserObject({})
        setCollectedUserInfo(false)
      }
    })

    onIdTokenChanged(auth, async (user) => {
      if (user) {
        setFbUserObject({ ...user, accessToken: await user.getIdToken() })
      }
    })

    setInterval(async () => {
      const user = auth?.currentUser
      if (user)
        setFbUserObject({ ...user, accessToken: await user?.getIdToken(true) })
    }, 3300000)

  }, []);

  useEffect(() => {
    //TODO: should check if this still makes sense when switching user on same device
    if (fbUserObject?.uid && !hasCollectedUserInfo) {
      handleUser()
    }
  }, [fbUserObject])

  const handleUser = async () => {
    try {
      const userRes = await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
        action: 'collectUser',
      }, {
        headers: {
          uid: fbUserObject?.providerData[0]?.uid,
          'access-token': fbUserObject?.accessToken ?? ''
        }
      })

      const currentUser = userRes?.data?.user

      setRoleState(currentUser?.role ?? 'user')
      setPositionState(currentUser?.position ?? '')
      setInvoiceMergerState(currentUser?.invoiceMerger ?? '')
      setCollectedUserInfo(true)
    } catch (err) {
      //TODO: alert
    }
  }
  return (
    <div className="App">
      <CookieConsent
        enableDeclineButton
        flipButtons
        buttonText="Aanvaarden"
        declineButtonText="Weigeren"
        overlay={true}
        style={{ fontSize: "20px", textAlign: "left", alignItems: "center" }}
        buttonStyle={{ backgroundColor: "#005c6c", fontSize: "20px", color: "white", padding: "0.5em" }}
        declineButtonStyle={{ fontSize: "20px", padding: "0.5em" }}
        onAccept={() => {
          ReactGA.initialize([
            {
              trackingId: "GTM-W7L7C7TD"
            },
            {
              trackingId: "G-R7KW7FWDL4"
            }
          ]);
          ReactGA.set({
            anonymizeIp: true
          })
        }}
      >
        <div style={{ fontSize: "18px" }}>
          Wij gebruiken cookies om uw ervaring op onze website te verbeteren. Essentiële cookies worden automatisch geplaatst voor de basisfunctionaliteit van de website. Optionele cookies worden gebruikt voor analyse. U heeft de controle:
          <ul>
            <li><strong>Aanvaarden:</strong> Om alle cookies te accepteren.</li>
            <li><strong>Weigeren:</strong> Om alleen essentiële cookies te gebruiken.</li>
          </ul>
          Bezoek ons <a style={{ color: "#ffffff" }} href='https://fourfive.be/privacy-policy/'>Privacy beleid</a> voor meer informatie over hoe we uw gegevens gebruiken en beschermen.
        </div>
      </CookieConsent>
      <BrowserRouter>
        {fbUserObject?.uid && hasCollectedUserInfo && <Header />}
        <Routes>
          <Route path='/' element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path='/login' element={<Login />} />
          <Route path='/home' element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path='/search' element={<PrivateRoute><Search /></PrivateRoute>} />
          <Route path='/user-management' element={<PrivateRoute><UserManagement /></PrivateRoute>} />
          <Route path='*' element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;