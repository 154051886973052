import { IconProps as MuiIconProps } from '@mui/material/Icon'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined'
import ContactMailIcon from '@mui/icons-material/ContactMailOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import KeyIcon from '@mui/icons-material/Key'
import CancelIcon from '@mui/icons-material/Cancel'
import InfoIcon from '@mui/icons-material/Info'
import EmailIcon from '@mui/icons-material/Email'
import CalculateIcon from '@mui/icons-material/Calculate'
import DeleteIcon from '@mui/icons-material/Delete'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports'
import CheckroomIcon from '@mui/icons-material/Checkroom'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import NextPlanIcon from '@mui/icons-material/NextPlan'
import EuroIcon from '@mui/icons-material/Euro'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SaveIcon from '@mui/icons-material/Save'
import FilterListIcon from '@mui/icons-material/FilterList'
import FlagIcon from '@mui/icons-material/Flag'
import EngineeringIcon from '@mui/icons-material/Engineering'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import StarIcon from '@mui/icons-material/Star'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import AddIcon from '@mui/icons-material/Add'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import HandshakeIcon from '@mui/icons-material/Handshake'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import BugReportIcon from '@mui/icons-material/BugReport'
import AttachmentIcon from '@mui/icons-material/Attachment'
import DownloadIcon from '@mui/icons-material/Download'
import DownloadingIcon from '@mui/icons-material/Downloading'
import PreviewIcon from '@mui/icons-material/Preview'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import RestoreIcon from '@mui/icons-material/Restore'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import FolderIcon from '@mui/icons-material/Folder'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import EditIcon from '@mui/icons-material/Edit'
import LaunchIcon from '@mui/icons-material/Launch'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import TitleIcon from '@mui/icons-material/Title'
import ArticleIcon from '@mui/icons-material/Article'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import PedalBikeIcon from '@mui/icons-material/PedalBike'
import SearchIcon from '@mui/icons-material/Search'
import MenuIcon from '@mui/icons-material/Menu'
import AdbIcon from '@mui/icons-material/Adb'
import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PersonIcon from '@mui/icons-material/Person'
import ModeIcon from '@mui/icons-material/Mode'
import BadgeIcon from '@mui/icons-material/Badge'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DescriptionIcon from '@mui/icons-material/Description'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import BusinessIcon from '@mui/icons-material/Business'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckIcon from '@mui/icons-material/Check'

interface IconProps {
    // you can declare custom props in here
    sx?: MuiIconProps['sx']
    color?: MuiIconProps['color']
    style?: MuiIconProps['style']
}

export default {
    InfoOutlinedIcon: (props: IconProps) => <InfoOutlinedIcon {...props} />,
    BusinessIcon: (props: IconProps) => <BusinessIcon {...props} />,
    AccessAlarmIcon: (props: IconProps) => <AccessAlarmIcon {...props} />,
    VisibilityIcon: (props: IconProps) => <VisibilityIcon {...props} />,
    VisibilityOffIcon: (props: IconProps) => <VisibilityOffIcon {...props} />,
    AccountCircleIcon: (props: IconProps) => <AccountCircleIcon {...props} />,
    ContactMailIcon: (props: IconProps) => <ContactMailIcon {...props} />,
    LockOutlinedIcon: (props: IconProps) => <LockOutlinedIcon {...props} />,
    LoginOutlinedIcon: (props: IconProps) => <LoginOutlinedIcon {...props} />,
    AccountBoxOutlinedIcon: (props: IconProps) => <AccountBoxOutlinedIcon {...props} />,
    CheckCircleIcon: (props: IconProps) => <CheckCircleIcon {...props} />,
    HowToRegIcon: (props: IconProps) => <HowToRegIcon {...props} />,
    KeyIcon: (props: IconProps) => <KeyIcon {...props} />,
    CancelIcon: (props: IconProps) => <CancelIcon {...props} />,
    InfoIcon: (props: IconProps) => <InfoIcon {...props} />,
    EmailIcon: (props: IconProps) => <EmailIcon {...props} />,
    CalculateIcon: (props: IconProps) => <CalculateIcon {...props} />,
    DeleteIcon: (props: IconProps) => <DeleteIcon {...props} />,
    SportsMotorsportsIcon: (props: IconProps) => <SportsMotorsportsIcon {...props} />,
    CheckroomIcon: (props: IconProps) => <CheckroomIcon {...props} />,
    AddCircleIcon: (props: IconProps) => <AddCircleIcon {...props} />,
    DirectionsBikeIcon: (props: IconProps) => <DirectionsBikeIcon {...props} />,
    NextPlanIcon: (props: IconProps) => <NextPlanIcon {...props} />,
    EuroIcon: (props: IconProps) => <EuroIcon {...props} />,
    SaveIcon: (props: IconProps) => <SaveIcon {...props} />,
    ArrowBackIcon: (props: IconProps) => <ArrowBackIcon {...props} />,
    ArrowForwardIcon: (props: IconProps) => <ArrowForwardIcon {...props} />,
    FilterListIcon: (props: IconProps) => <FilterListIcon {...props} />,
    FlagIcon: (props: IconProps) => <FlagIcon {...props} />,
    EngineeringIcon: (props: IconProps) => <EngineeringIcon {...props} />,
    ExpandMoreIcon: (props: IconProps) => <ExpandMoreIcon {...props} />,
    MoreVertIcon: (props: IconProps) => <MoreVertIcon {...props} />,
    NavigateNextIcon: (props: IconProps) => <NavigateNextIcon {...props} />,
    StarIcon: (props: IconProps) => <StarIcon {...props} />,
    SentimentVeryDissatisfiedIcon: (props: IconProps) => (
        <SentimentVeryDissatisfiedIcon {...props} />
    ),
    SentimentDissatisfiedIcon: (props: IconProps) => <SentimentDissatisfiedIcon {...props} />,
    SentimentSatisfiedIcon: (props: IconProps) => <SentimentSatisfiedIcon {...props} />,
    SentimentSatisfiedAltIcon: (props: IconProps) => <SentimentSatisfiedAltIcon {...props} />,
    SentimentVerySatisfiedIcon: (props: IconProps) => <SentimentVerySatisfiedIcon {...props} />,
    SpeedDialIcon: (props: IconProps) => <SpeedDialIcon {...props} />,
    ArrowRightIcon: (props: IconProps) => <ArrowRightIcon {...props} />,
    ThumbUpAltIcon: (props: IconProps) => <ThumbUpAltIcon {...props} />,
    ThumbDownAltIcon: (props: IconProps) => <ThumbDownAltIcon {...props} />,
    AddIcon: (props: IconProps) => <AddIcon {...props} />,
    DriveFileRenameOutlineIcon: (props: IconProps) => <DriveFileRenameOutlineIcon {...props} />,
    AccessTimeIcon: (props: IconProps) => <AccessTimeIcon {...props} />,
    LeaderboardIcon: (props: IconProps) => <LeaderboardIcon {...props} />,
    HighlightOffIcon: (props: IconProps) => <HighlightOffIcon {...props} />,
    DateRangeIcon: (props: IconProps) => <DateRangeIcon {...props} />,
    ArrowDropDownIcon: (props: IconProps) => <ArrowDropDownIcon {...props} />,
    DoneIcon: (props: IconProps) => <DoneIcon {...props} />,
    DoneAllIcon: (props: IconProps) => <DoneAllIcon {...props} />,
    LocalShippingIcon: (props: IconProps) => <LocalShippingIcon {...props} />,
    HandshakeIcon: (props: IconProps) => <HandshakeIcon {...props} />,
    ScheduleSendIcon: (props: IconProps) => <ScheduleSendIcon {...props} />,
    DoNotDisturbOnIcon: (props: IconProps) => <DoNotDisturbOnIcon {...props} />,
    BugReportIcon: (props: IconProps) => <BugReportIcon {...props} />,
    AttachmentIcon: (props: IconProps) => <AttachmentIcon {...props} />,
    DownloadIcon: (props: IconProps) => <DownloadIcon {...props} />,
    DownloadingIcon: (props: IconProps) => <DownloadingIcon {...props} />,
    PreviewIcon: (props: IconProps) => <PreviewIcon {...props} />,
    BrokenImageIcon: (props: IconProps) => <BrokenImageIcon {...props} />,
    ImportExportIcon: (props: IconProps) => <ImportExportIcon {...props} />,
    TransferWithinAStationIcon: (props: IconProps) => <TransferWithinAStationIcon {...props} />,
    RestoreIcon: (props: IconProps) => <RestoreIcon {...props} />,
    TravelExploreIcon: (props: IconProps) => <TravelExploreIcon {...props} />,
    FolderIcon: (props: IconProps) => <FolderIcon {...props} />,
    DoNotDisturbIcon: (props: IconProps) => <DoNotDisturbIcon {...props} />,
    Edit: (props: IconProps) => <EditIcon {...props} />,
    LaunchIcon: (props: IconProps) => <LaunchIcon {...props} />,
    LocalOfferIcon: (props: IconProps) => <LocalOfferIcon {...props} />,
    TitleIcon: (props: IconProps) => <TitleIcon {...props} />,
    ArticleIcon: (props: IconProps) => <ArticleIcon {...props} />,
    FormatQuoteIcon: (props: IconProps) => <FormatQuoteIcon {...props} />,
    PedalBikeIcon: (props: IconProps) => <PedalBikeIcon {...props} />,
    SearchIcon: (props: IconProps) => <SearchIcon {...props} />,
    MenuIcon: (props: IconProps) => <MenuIcon {...props} />,
    AdbIcon: (props: IconProps) => <AdbIcon {...props} />,
    CloseIcon: (props: IconProps) => <CloseIcon {...props} />,
    LogoutIcon: (props: IconProps) => <LogoutIcon {...props} />,
    DashboardIcon: (props: IconProps) => <DashboardIcon {...props} />,
    PersonIcon: (props: IconProps) => <PersonIcon {...props} />,
    ModeIcon: (props: IconProps) => <ModeIcon {...props} />,
    BadgeIcon: (props: IconProps) => <BadgeIcon {...props} />,
    InsertDriveFileIcon: (props: IconProps) => <InsertDriveFileIcon {...props} />,
    DescriptionIcon: (props: IconProps) => <DescriptionIcon {...props} />,
    KeyboardArrowDownIcon: (props: IconProps) => <KeyboardArrowDownIcon {...props} />,
    KeyboardArrowUpIcon: (props: IconProps) => <KeyboardArrowUpIcon {...props} />,
    TextFieldsIcon: (props: IconProps) => <TextFieldsIcon {...props} />,
    RefreshIcon: (props: IconProps) => <RefreshIcon {...props} />,
    EditIcon: (props: IconProps) => <EditIcon {...props} />,
    CheckIcon: (props: IconProps) => <CheckIcon {...props} />
}

// Example of this component with props:
{
    /* <MUIComponents.Icons.NavigateNextIcon sx={{ fontSize: 'small' }} /> */
}