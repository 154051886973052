import axios from "axios"
import React from "react"
import { removeEmptyFromObject } from "../../../utils/utils"
import ReactGA from 'react-ga4'

interface SearchComponentBusinessContext {
    localSortConfig: any,
    searchValue: string,
    setActiveSearchValue: React.Dispatch<React.SetStateAction<string>>,
    fbUserObject: any,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    loading: boolean,
    setHasSearched: React.Dispatch<React.SetStateAction<boolean>>,
    DEFAULT_PAGINATION: any,
    pagination: any,
    setPagination: React.Dispatch<React.SetStateAction<any>>,
    setSearchResults: React.Dispatch<any>,
    setLocalSortConfig: React.Dispatch<any>,
    searchResults: any,
    setAnchorEl: React.Dispatch<HTMLButtonElement | null>,
    filterConfig: any,
    setTagSuggestions: (tagStringArray: Array<string>) => void,
    titleOnlyChecked: boolean,
    bodyOnlyChecked: boolean
}

export class SearchComponentBusiness {
    context: SearchComponentBusinessContext

    constructor(context: SearchComponentBusinessContext) {
        this.context = context
    }

    getElasticSortObject() {
        if (this.context.localSortConfig && this.context.localSortConfig.sortBy && this.context.localSortConfig.sortBy !== '')
            return { [this.context.localSortConfig.sortBy]: this.context.localSortConfig.direction }
        else return null
    }

    getElasticFilterObject(): any {
        const filterArr = []
        let includeDoNotUse = false;
        for (const key in this.context.filterConfig) {
            if (key === 'ff_do_not_use' && this.context.filterConfig[key] === true) {
                includeDoNotUse = true
            } else {
                if (key !== 'ff_do_not_use')
                    filterArr.push({ [key]: this.context.filterConfig[key] })
            }
        }

        if ((!filterArr || filterArr?.length <= 0) && includeDoNotUse)
            return null

        const returnObj: any = {
            all: filterArr
        }

        if (!includeDoNotUse)
            returnObj['none'] = [{ 'ff_do_not_use': '1' }]
        if (!filterArr || filterArr?.length <= 0)
            delete returnObj.all

        return returnObj
    }

    resetPagination() {
        this.context.setPagination(this.context.DEFAULT_PAGINATION)
    }

    async onSearch(fromInput = false) {
        if (fromInput) {
            this.resetPagination()
            this.resetAndRefresh()
            return
        }

        if (this.context.fbUserObject?.uid && this.context.fbUserObject.uid !== '') {
            this.context.setActiveSearchValue(this.context.searchValue)
            this.context.setLoading(true);
            this.context.setHasSearched(true)

            const body = {
                action: 'query',
                queryString: this?.context?.searchValue ?? '',
                paging: {
                    current: this.context.pagination?.page ?? 1,
                    size: this.context.pagination?.size ?? 15
                },
                sort: this.getElasticSortObject(),
                filters: this.getElasticFilterObject(),
                titleOnly: this?.context?.titleOnlyChecked ?? false,
                bodyOnly: this?.context?.bodyOnlyChecked ?? false
            }

            try {
                const res = await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', removeEmptyFromObject(body), {
                    headers: {
                        uid: this?.context?.fbUserObject?.uid ?? '',
                        'access-token': this?.context?.fbUserObject?.accessToken ?? '',
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    }
                })
                this.context.setSearchResults(res.data);
            } catch (err) {
                //TODO: messaging system
                console.log(err)
                alert("Kon de zoekactie niet uitvoeren, probeer opnieuw")
            } finally {
                this.context.setLoading(false);
            }
        }
    }

    async onClickToSharePoint(incommingUrl: string, isDoc: boolean) {
        if (incommingUrl && incommingUrl !== '') {
            if (!isDoc) {
                window.open(incommingUrl, '_blank')
                return;
            }

            const baseUrl = incommingUrl?.split('sourcedoc')[0]

            const params = new URLSearchParams(new URL(incommingUrl).search)
            params.delete('action')
            params.delete('mobileredirect')
            params.delete('file')
            params.append('action', "view")
            params.append('wdAccPdf', "0")
            params.append('wdEmbedFS', "1")


            window.open(baseUrl + params.toString(), '_blank')
        }
    }

    async handleSort(colTitle: string) {
        if (colTitle === this.context.localSortConfig.sortBy) {
            if (this.context.localSortConfig.direction === 'asc')
                this.context.setLocalSortConfig({ ...this.context.localSortConfig, direction: 'desc' })
            else
                this.context.setLocalSortConfig({ sortBy: '', direction: 'asc' })
        } else {
            this.context.setLocalSortConfig({ sortBy: colTitle, direction: 'asc' })
        }
    }

    async handleThumbClick(e: React.MouseEvent<HTMLButtonElement>, elasticFile: any) {
        e?.preventDefault()
        try {
            this.context.setLoading(true);
            let likesArr = [...elasticFile?.ff_like?.raw ?? []]

            const hasLiked = !likesArr.includes(this.context?.fbUserObject?.uid)
            if (hasLiked)
                likesArr.push(this.context?.fbUserObject?.uid)
            else
                likesArr = likesArr.filter((like: string) => like != this.context?.fbUserObject?.uid)

            await this.updateDocument({
                id: elasticFile.id.raw,
                ff_like: likesArr
            })

            //Temp delay for elastic procession assurance
            await new Promise(r => setTimeout(r, 500));
            await this.resetAndRefresh(false)

            //GA
            this.submitGAEvent('Meta', 'Like', 'Search')
        } catch (err) {
            //TODO: messaging system
            console.log(err)
            alert("Kon de like actie niet verwerken, probeer opnieuw")
        } finally {
            this.context.setLoading(false);
        }
    }

    async resetAndRefresh(shouldReset = true) {
        if (shouldReset) this.context.setSearchResults({})
        await this.onSearch()
    }

    getThumbButtonColor(active: boolean, forIcon = false) {
        if (forIcon)
            return active ? 'rgba(20, 88, 100, 0.5)' : 'lightgrey'
        return active ? 'rgb(223, 240, 239)' : 'rgb(211,211,211,0.15)'
    }

    checkShouldShowHeaderValue(title: string): string {
        return (!this.context.loading && this.context.searchResults?.results?.length > 0) ? title : ''
    }

    handleAnchorClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        this.context.setAnchorEl(e.currentTarget)
    }

    getActionToolTipText(elasticFile: any, prop: string) {
        if (elasticFile?.[prop]?.timestamp)
            return `${new Date(elasticFile?.[prop]?.timestamp).toDateString()}: ${elasticFile?.[prop]?.action_taker}`
        else return ''
    }

    async handleStakeHolderChange(newValue: string, fileId: string) {
        const currentRes = { ...this?.context?.searchResults }
        currentRes.results.map((currentFile: any) => {
            if (currentFile?.id?.raw === fileId) {
                currentFile['stakeHolderAction'] = currentFile?.stakeHolderAction ?? { value: newValue }
                currentFile['stakeHolderAction']['value'] = newValue;
            }
        })
        this.context.setSearchResults(currentRes)

        try {
            await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                action: 'submitStakeHolder',
                document_id: fileId,
                value: newValue,
                user_info: {
                    display_name: this.context.fbUserObject.displayName,
                    mail: this.context.fbUserObject.email
                }
            }, {
                headers: {
                    uid: this?.context?.fbUserObject?.uid ?? '',
                    'access-token': this?.context?.fbUserObject?.accessToken ?? ''
                }
            })
        } catch (err) {
            //TODO: messaging system
            console.log(err)
            alert("Kon de belanghebbende niet bewaren, probeer opnieuw")
            this.resetAndRefresh()
        }
    }

    async updateDocument(documentInput: any) {
        try {
            await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                action: 'updateDocument',
                documentInput
            }, {
                headers: {
                    uid: this?.context?.fbUserObject?.providerData[0]?.uid ?? '',
                    'access-token': this?.context?.fbUserObject?.accessToken ?? ''
                }
            })
        } catch (err) {
            //TODO: messaging system
            console.log(err)
            alert("Kon het document niet bewerken, probeer opnieuw")
            this.resetAndRefresh()
        }
    }

    async submitNewTags(tagsInput: Array<string>) {
        try {
            await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                action: 'submitTags',
                tagsInput
            }, {
                headers: {
                    uid: this?.context?.fbUserObject?.uid,
                    'access-token': this?.context?.fbUserObject?.accessToken ?? ''
                }
            })
        } catch (err) {
            //TODO: messaging system
            console.log(err)
            alert("Kon de nieuwe tags niet doorvoeren, probeer opnieuw")
            this.resetAndRefresh()
        }
    }

    async deleteTagOption(tagToDelete: string) {
        try {
            await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                action: 'deleteTagOption',
                tagToDelete
            }, {
                headers: {
                    uid: this?.context?.fbUserObject?.providerData[0]?.uid ?? '',
                    'access-token': this?.context?.fbUserObject?.accessToken ?? ''
                }
            })
        } catch (err) {
            //TODO: messaging system
            console.log(err)
            alert("Kon de tag niet verwijderen, probeer opnieuw")
            this.resetAndRefresh()
        }
    }

    checkIfGlobalFilterIsActive() {
        const status =
            (this.context?.filterConfig?.extension && this.context?.filterConfig?.extension?.length > 0) ||
            (this.context?.filterConfig?.ff_team && this.context?.filterConfig?.ff_team !== '') ||
            (this.context?.filterConfig?.ff_partner && this.context?.filterConfig?.ff_partner !== '') ||
            (this.context?.filterConfig?.ff_point_of_view && this.context?.filterConfig?.ff_point_of_view !== '') ||
            (this.context?.filterConfig?.ff_business && this.context?.filterConfig?.ff_business !== '') ||
            (this.context?.filterConfig?.ff_state && this.context?.filterConfig?.ff_state !== '') ||
            (this.context?.filterConfig?.ff_source && this.context?.filterConfig?.ff_source !== '') ||
            (this.context?.filterConfig?.ff_taal && this.context?.filterConfig?.ff_taal !== '') ||
            (this.context?.filterConfig?.ff_do_not_use && this.context?.filterConfig?.ff_do_not_use === true) ||
            (this.context?.filterConfig?.ff_tags && this.context?.filterConfig?.ff_tags?.length > 0)

        return status
    }

    async collectTagSuggestions() {
        try {
            const tags = await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                action: 'collectTagOptions'
            }, {
                headers: {
                    uid: this?.context?.fbUserObject?.uid ?? '',
                    'access-token': this?.context?.fbUserObject?.accessToken ?? ''
                }
            })

            const tagStringArray: string[] = tags.data.map((tag: any) => tag?.title)
            this.context.setTagSuggestions(tagStringArray)

        } catch (err) {
            //TODO: messaging system
            console.log(err)
            alert("Kon de tags niet ophalen, probeer opnieuw")
        }
    }

    handleEponaClick(e: React.MouseEvent<HTMLButtonElement>, elasticFile: any) {
        e?.preventDefault()
        // Should be:     https://fourfive.sharepoint.com/sites/dms01/485/files/Documents/202112_Principeovereenkomst%20CEO/SPA
        const BASE_URL = "https://fourfive.sharepoint.com/sites/dms01/"
        let path = elasticFile?.path?.raw
        const fileName = elasticFile?.title?.raw
        if (path && path !== '') {
            const dirSrc = path.split(fileName)[0]
            const nr = dirSrc.split('/sites/')[1].split('/')[0]
            path = BASE_URL + nr + '/files' + dirSrc.split(`/sites/${nr}`)[1].replace(/\/$/, "").replaceAll(' ', '%20')
            window.open(path, '_blank')
        }
        //GA
        this.submitGAEvent('Interaction', 'Open epona', 'search')
    }

    collectHighlightValues(searchString: string) {
        const EXCLUDES = ["AND", "OR", "NOT", "+", "-"]
        const searchStr = searchString?.replaceAll('"', '') ?? ""
        let resultArr = searchStr.split(' ')
        resultArr = resultArr.filter((stringPart: string) => {
            return !EXCLUDES.includes(stringPart)
        })

        return resultArr
    }

    collectPaginationCount(pageCount: number) {
        return pageCount > 100 ? 100 : pageCount
    }

    collectSnippetPart(fullContent: string, snippet: string) {
        if (snippet && snippet !== '')
            return snippet
        else if (fullContent && fullContent !== '')
            return fullContent?.slice(0, 1000)
        else
            return 'Geen inhoud beschikbaar.'
    }

    submitGAEvent(cetegory: string, action: string, label: string) {
        try {
            ReactGA.event({
                category: cetegory,
                action: action,
                label: label
            })
        } catch (err) {
            console.log(err)
        }
    }
}