import { getAuth, signOut } from "firebase/auth";
import firebaseApp from "../../../firebase";

export const logout = () => {
    const auth = getAuth(firebaseApp);
    signOut(auth).then(res => {
        //console.log(res);
    }).catch(err => {
        //TODO: messaging system
        //console.log(err);
    });
};