import { TooltipProps } from "../components/muiComponents/types"
import { ReactElement } from "react"
import { MUIComponents } from "../components/muiComponents"

export const WrapInToolTip = (children: ReactElement, title: string, placement: TooltipProps["placement"]): ReactElement => {
    return (
        <MUIComponents.Tooltip title={title} arrow placement={placement}>
            <span>
                {children}
            </span>
        </MUIComponents.Tooltip>
    )
}