import MuiRating, { RatingProps as MuiRatingProps } from '@mui/material/Rating'
import { useState } from 'react'
import styled from 'styled-components'
import { MUIComponents } from '.'
import { noop } from '../../utils/utils'

const StyledRating = styled(MuiRating)`
    color: ${(props) => props.theme.palette.primary.main} !important;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)} !important;
`

const CustomStyledRating = styled(MuiRating)`
    & .MuiRating-iconEmpty .MuiSvgIcon-root {
        color: ${(props) => props.theme.palette.action.disabled} !important;
    }
`

// ! Important: Er zit een bug in MUI bij de rating component, als we de precision op 0.5 bv zetten
// reageert de UI niet bij het selecteren van het volle icoontje, vandaar laten we die maar altijd op 1 staan!!!
// dus de onChange prop reageert dan niet ...
// Als je meerdere wilt hebben kan je het nog altijd met de max prop doen
export const Rating = ({
    size = 'medium',
    max = 5,
    precision = 1,
    icon = (
        <MUIComponents.Icons.StarIcon sx={{ '& .MuiSvgIcon-root': { pointerEvents: 'auto' } }} />
    ),
    emptyIcon = (
        <MUIComponents.Icons.StarIcon sx={{ '& .MuiSvgIcon-root': { pointerEvents: 'auto' } }} />
    ),
    onChange = noop,
    ...props
}: MuiRatingProps) => (
    <StyledRating
        name="unique-rating"
        size={size}
        max={max}
        precision={precision}
        icon={icon}
        onChange={onChange}
        {...props}
    />
)

// you can declare custom props in here
export interface CustomRatingProps extends MuiRatingProps {
    // You can customise the pagination items like this
    customIcons: { [key: string]: { icon: JSX.Element; label: string } }
    size?: MuiRatingProps['size']
    defaultValue?: MuiRatingProps['defaultValue']
}

export const CustomRating = ({
    size = 'medium',
    customIcons = {},
    defaultValue,
    onChange = noop,
    ...props
}: CustomRatingProps) => (
    <CustomStyledRating
        defaultValue={defaultValue}
        IconContainerComponent={(props) => {
            const { value, ...other } = props

            return <span {...other}>{customIcons[value].icon}</span>
        }}
        size={size}
        getLabelText={(value) => customIcons[value].label}
        highlightSelectedOnly
        name="highlight-selected-only"
        onChange={onChange}
        {...props}
    />
)

// you can declare custom props in here
export interface HoverRatingProps extends MuiRatingProps {
    // You can customise the pagination items like this
    labels: { [key: number]: string }
}

export const HoverRating = ({
    size = 'medium',
    icon = (
        <MUIComponents.Icons.StarIcon sx={{ '& .MuiSvgIcon-root': { pointerEvents: 'auto' } }} />
    ),
    emptyIcon = (
        <MUIComponents.Icons.StarIcon sx={{ '& .MuiSvgIcon-root': { pointerEvents: 'auto' } }} />
    ),
    value,
    labels = {},
    onChange = noop,
    ...props
}: HoverRatingProps) => {
    const [hover, setHover] = useState(-1)

    return (
        <MUIComponents.Styling.Box
            sx={{
                width: 200,
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <StyledRating
                name="hover-feedback"
                value={value}
                precision={1}
                getLabelText={(value) => {
                    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`
                }}
                onChange={onChange}
                onChangeActive={(e, newHover) => {
                    setHover(newHover)
                }}
                icon={icon}
                emptyIcon={emptyIcon}
                {...props}
            />
            {value && (
                <MUIComponents.Styling.Box sx={{ ml: 2 }}>
                    {labels[hover !== -1 ? hover : value]}
                </MUIComponents.Styling.Box>
            )}
        </MUIComponents.Styling.Box>
    )
}

// Example of the Rating component with props:
{
    /* <MUIComponents.Rating
                onChange={(e, newValue) => {
                    setFoo(newValue)
                }}
                value={foo}
            /> */
}

// Example of the CustomRating component with props:
{
    /* <MUIComponents.CustomRating
                customIcons={{
                    1: {
                        icon: <SentimentVeryDissatisfiedIcon color="error" />,
                        label: 'Very Dissatisfied'
                    },
                    2: {
                        icon: <SentimentDissatisfiedIcon color="error" />,
                        label: 'Dissatisfied'
                    },
                    3: {
                        icon: <SentimentSatisfiedIcon color="warning" />,
                        label: 'Neutral'
                    },
                    4: {
                        icon: <SentimentSatisfiedAltIcon color="success" />,
                        label: 'Satisfied'
                    },
                    5: {
                        icon: <SentimentVerySatisfiedIcon color="success" />,
                        label: 'Very Satisfied'
                    }
                }}
                onChange={(e, newValue) => {
                    setFoo(newValue)
                }}
                value={foo}
            /> */
}

// Example of the HoverRating component with props:
{
    /* <MUIComponents.HoverRating
                onChange={(e, newValue) => {
                    setFoo(newValue)
                }}
                value={foo}
                labels={{
                    1: 'Useless',
                    2: 'Poor',
                    3: 'Ok',
                    4: 'Good',
                    5: 'Excellent'
                }}
                icon={<MUIComponents.Icons.CancelIcon />}
                emptyIcon={<MUIComponents.Icons.CancelIcon />}
            /> */
}