import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import styled from 'styled-components'
import { MUIComponents } from '.'
import { noop } from '../../utils/utils'

const getTabComponent = ({
    tab,
    component,
    onClick
}: {
    tab: TabProps
    component: 'Label' | 'IconWithLabel' | 'Icon'
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, fileId?: string) => void
}) => {
    const defaultProps = (link = true) => ({
        key: tab.id,
        id: `Panel-${tab.id}`,
        'aria-controls': tab.ariaControls,
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onClick(e, tab.fileId)
        },
        ...(link && { wrapped: tab.wrapped, disabled: tab.disabled })
    })

    switch (component) {
        case 'Label':
            return <StyledTab label={tab.label} {...defaultProps()} />
        case 'Icon':
            return <StyledTab icon={tab.icon} {...defaultProps()} />
        case 'IconWithLabel':
            return <StyledTab label={tab.label} icon={tab.icon} {...defaultProps()} />
        default:
            return <StyledTab label={tab.label} {...defaultProps()} />
    }
}

const StyledTabs = styled(MuiTabs)`
    & .MuiTabs-indicator {
        background-color: ${(props) => props.theme.palette.primary.main} !important;
    }
`

const StyledTab = styled(MuiTab)`
    width: 200px;
    border-right: 1px solid !important;
    border-image: ${(props) =>
        `linear-gradient(transparent 10%, ${props.theme.palette.divider} 10% 90%, transparent 90%) 0 1 0 0 / 1px`} !important;

    &.Mui-selected {
        color: ${(props) => props.theme.palette.primary.contrastText} !important;
    }

    &.MuiTab-root {
        background-color: ${(props) => props.theme.palette.secondary.main} !important;
        display: flex;
        flex-direction: column;
    }
`

interface TabPanelProps {
    value: number | string
    index: number
    children: JSX.Element | string
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
    >
        {value === index && children}
    </div>
)

interface TabProps {
    children?: JSX.Element | string
    label: string | JSX.Element
    id: string
    ariaControls: string
    wrapped?: boolean
    disabled?: boolean
    icon?: JSX.Element
    fileId?: string
}

// TODO: added loading prop
export const Tabs = ({
    value,
    onChange,
    allTabs = [],
    centered = false,
    variant = 'scrollable',
    orientation = 'horizontal',
    component = 'Label' as any,
    loading = false,
    onClick = noop,
    ...props
}: Omit<MuiTabsProps, 'onClick'> & {
    allTabs: TabProps[]
    component?: 'Label' | 'IconWithLabel' | 'Icon'
    loading?: boolean
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, fileId?: string) => void
}) => (
    <MUIComponents.Styling.Box sx={{ width: '100%' }}>
        <MUIComponents.Styling.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <StyledTabs
                value={value}
                onChange={onChange}
                aria-label="tabs"
                centered={centered}
                variant={variant}
                allowScrollButtonsMobile
                scrollButtons="auto"
                orientation={orientation}
                {...props}
            >
                {allTabs.map((tab) =>
                    loading ? (
                        <MUIComponents.Skeleton key={tab.id} width="100%" height="40px" />
                    ) : (
                        getTabComponent({
                            component,
                            tab,
                            onClick
                        })
                    )
                )}
            </StyledTabs>
        </MUIComponents.Styling.Box>
        {allTabs.map((tab) =>
            tab?.children ? (
                <TabPanel key={tab.id} value={value} index={Number(tab.id)}>
                    {tab.children}
                </TabPanel>
            ) : undefined
        )}
    </MUIComponents.Styling.Box>
)

// ! IMPORTANT: first item in array need to start with id = "0"
// ! The onChange is going to give the parameters event and newValue to this function and newValue is 0 when the first element is active

// There are 3 possible tabs: 'Label' | 'IconWithLabel' | 'Icon'
// Just change the prop component with one of the possible tabs
// EXAMPLE of this component with props
// STATE: const [value, setValue] = useState(0)
{
    /* <MUIComponents.Tabs
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                value={value}
                allTabs={[
                    {
                        label: 'New Arrivals in the Longest Text of Nonfiction that should appear in the next line',
                        children: <div>Item One</div>,
                        id: '0',
                        ariaControls: 'Panel-0',
                        wrapped: true,
                        disabled: false,
                        icon: <MUIComponents.Icons.StarIcon />
                    },
                    {
                        label: 'Item 2',
                        children: <div>Item Two</div>,
                        id: '1',
                        ariaControls: 'Panel-1',
                        wrapped: false,
                        icon: <MUIComponents.Icons.ArrowForwardIcon />
                    },
                    {
                        label: 'Item 3',
                        children: <div>Item Three</div>,
                        id: '2',
                        ariaControls: 'Panel-2',
                        wrapped: false,
                        disabled: false,
                        icon: <MUIComponents.Icons.AccountCircleIcon />
                    }
                ]}
                component="Label"
            /> */
}