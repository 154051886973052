import InputAdornment from '@mui/material/InputAdornment'
import { InputBaseProps } from '@mui/material/InputBase'
import MuiTextField, { StandardTextFieldProps, BaseTextFieldProps } from '@mui/material/TextField'
import styled from 'styled-components'
import { noop, genericFontSize } from '../../utils/utils'

const StyledTextField = styled(MuiTextField)`
    & .MuiOutlinedInput-input {
        margin: 3px;
        text-overflow: ellipsis;
    }

    & label.Mui-focused {
        color: ${(props) =>
        props.error
            ? props.theme.palette.error.main
            : props.color ?? props.theme.palette.primary.main};
    }

    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: ${(props) =>
        props.error
            ? props.theme.palette.error.main
            : props.color ?? props.theme.palette.primary.main};
        }
    }
`

interface TextFieldProps extends BaseTextFieldProps {
    onChange?: StandardTextFieldProps['onChange']
    InputProps?: StandardTextFieldProps['InputProps']
    startAdornment?: InputBaseProps['startAdornment']
    euroIcon?: boolean
}

export const TextField = ({
    autoFocus = true,
    fullWidth = true,
    onBlur = noop,
    onClick = noop,
    onKeyDown = noop,
    onChange = noop,
    type = 'text',
    margin = 'normal',
    required = true,
    InputProps = { style: { fontSize: genericFontSize } },
    InputLabelProps = { style: { fontSize: genericFontSize } },
    error = false,
    startAdornment = undefined,
    euroIcon = false,
    ...props
}: TextFieldProps) => (
    <StyledTextField
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        type={type}
        onBlur={onBlur}
        onClick={onClick}
        onChange={onChange}
        variant="outlined"
        margin={margin}
        required={required}
        InputProps={{
            startAdornment: euroIcon ? (
                <InputAdornment position="start">€</InputAdornment>
            ) : (
                startAdornment
            ),
            ...InputProps
        }}
        InputLabelProps={InputLabelProps}
        error={error}
        onKeyDown={onKeyDown}
        {...props}
    />
)

// Example of this component with props:
{
    /* <MUIComponents.TextFields.TextField
                            id="filterModal"
                            label={`Filter on ${filters[activeFilter as string]?.label ?? '...'}`}
                            name="filterValue"
                            size="small"
                            autoFocus={false}
                            required={false}
                            margin="dense"
                            value={filters[activeFilter as string]?.value}
                            InputProps={{ style: { fontSize: 15 } }}
                            InputLabelProps={{ style: { fontSize: 15 } }}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setFilters({
                                        ...filters,
                                        [activeFilter as string]: {
                                            ...filters[activeFilter as string],
                                            value: e.target.value
                                        }
                                    })
                                }
                            }}
                            onKeyDown={(e) => {
                                if (['Enter', 'NumpadEnter'].includes(e.code || e.key)) {
                                    setFilters({
                                        ...filters,
                                        [activeFilter as string]: {
                                            ...filters[activeFilter as string],
                                            filtering: true
                                        }
                                    })

                                    setIsComponentVisible(false)
                                }
                            }}
                        /> */
}