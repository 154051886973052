import { Alert, AlertTitle, Badge, Chip, Divider, IconButton, Input, InputAdornment, Pagination, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { alertObjState, fbUserObjectState } from '../../../store/store'
import { MimeTypeHandler } from '../../../utils/MimeTypeHandler'
import { formatDateString } from '../../../utils/utils'
import { SearchComponentBusiness } from './SearchComponentBusiness'
import AddIcon from '@mui/icons-material/Add'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import Highlighter from 'react-highlight-words'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import FilterListIcon from '@mui/icons-material/FilterList'
import DateRangeIcon from '@mui/icons-material/DateRange'
import DateRangeFilter from './subComponents/dateRangerFilter'
import GlobalFilter from './subComponents/globalFilter'
import TagSelector from './subComponents/tagSelector'
import ConfirmationDialog from './subComponents/confirmationDialog'
import { AlertObjectProps } from '../../../types'
import { MUIComponents } from '../../muiComponents'
import MetaDataModal from './subComponents/MetaDataModal'
import { useSearchParams } from 'react-router-dom'
import BodyModal from './subComponents/BodyModal'
import { WrapInToolTip } from '../../../utils/ToolTipWrap'
import styled from 'styled-components'
import ReactGA from 'react-ga4'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import LikeToolTipContent from './subComponents/LikeToolTipContent'
import { getCookieConsentValue } from "react-cookie-consent";

const highLightStyle = {
    background: 'rgba(20, 88, 100, 0.1)',
    color: '#595959',
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5
}

const DEFAULT_PAGINATION = {
    page: 1,
    size: 15
}

const Search = () => {
    //Hooks
    const [searchParams] = useSearchParams()

    //Global states
    const fbUserObject = useRecoilValue(fbUserObjectState)
    //TODO: pass to business logic & apply to requests
    const [_, setAlertObject] = useRecoilState<AlertObjectProps>(alertObjState)

    //States
    const [searchValue, setSearchValue] = useState<string>(searchParams.get('value') ?? '')
    const [activeSearchValue, setActiveSearchValue] = useState<string>('')
    const [hasSearched, setHasSearched] = useState<boolean>(false)
    const [searchResults, setSearchResults] = useState({} as any)
    const [loading, setLoading] = useState<boolean>(false)
    const [pagination, setPagination] = useState<any>(DEFAULT_PAGINATION)
    const [localSortConfig, setLocalSortConfig] = useState({
        sortBy: '',
        direction: 'asc' as any
    })
    const defaultFilter = {
        ff_do_not_use: false
    }
    const [filterConfig, setFilterConfig] = useState<any>(defaultFilter)
    const [tagSuggestions, setTagSuggestions] = useState<string[]>([])
    const [scrolledIntoTable, setScrolledIntoTable] = useState<boolean>(false)
    const [titleOnlyChecked, setTitleOnlyChecked] = useState<boolean>(false)
    const [bodyOnlyChecked, setBodyOnlyChecked] = useState<boolean>(false)
    const [shouldRefresh, setShouldRefresh] = useState<boolean>(false)

    //Popups
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>()
    const [selectedAnchorFile, setSelectedAnchorFile] = useState<any>()
    const [confirmationCallback, setConfirmationCallback] =
        useState<(e: React.MouseEvent<HTMLButtonElement>) => void | null>()
    const [showMetaModal, setShowMetaModal] = useState<boolean>(false)
    const [showBodyModal, setShowBodyModal] = useState<boolean>(false)

    //Business
    const searchComponentBusiness = new SearchComponentBusiness({
        localSortConfig,
        searchValue,
        setActiveSearchValue,
        fbUserObject,
        setLoading,
        loading,
        setHasSearched,
        DEFAULT_PAGINATION,
        pagination,
        setPagination,
        setSearchResults,
        setLocalSortConfig,
        searchResults,
        setAnchorEl,
        filterConfig,
        setTagSuggestions,
        titleOnlyChecked,
        bodyOnlyChecked
    })

    useEffect(() => {
        if (shouldRefresh) {
            searchComponentBusiness.onSearch(true)
            setShouldRefresh(false)
        }
    }, [shouldRefresh])

    useEffect(() => {
        searchComponentBusiness.resetAndRefresh()
    }, [pagination, localSortConfig])

    useEffect(() => {
        searchComponentBusiness.onSearch(true)
    }, [filterConfig, titleOnlyChecked, bodyOnlyChecked])


    const scrollHandler = () => {
        setScrolledIntoTable(window.scrollY > 300)
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)

        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [scrollHandler])

    useEffect(() => {
        //Google analytics page viewer
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pagination])

    const searchIconButton = (
        <MUIComponents.Badge
            badgeContent={searchResults?.meta?.page?.total_results ?? 0}
            color="primary"
        >
            <IconButton aria-label="search" onClick={() => searchComponentBusiness.onSearch(true)}>
                <SearchIcon style={{ strokeWidth: 1, stroke: 'grey' }} />
            </IconButton>
        </MUIComponents.Badge>
    )

    const searchOnlySwitches = (
        <>
            {WrapInToolTip(
                <MUIComponents.Buttons.IconButton
                    onClick={() => handleOnlyChanges(!titleOnlyChecked, 'title')}
                    style={{
                        marginLeft: -5,
                        margin: 0,
                        strokeWidth: titleOnlyChecked ? 1 : 0,
                        stroke: 'rgba(20, 88, 100, 0.5)',
                        color: titleOnlyChecked ? 'rgba(20, 88, 100, 0.5)' : 'lightgrey'
                    }}
                >
                    <MUIComponents.Icons.TitleIcon />
                </MUIComponents.Buttons.IconButton>,
                'Enkel op titel zoeken',
                'bottom'
            )}
            {WrapInToolTip(
                <MUIComponents.Buttons.IconButton
                    onClick={() => handleOnlyChanges(!bodyOnlyChecked, 'body')}
                    style={{
                        marginLeft: -10,
                        strokeWidth: bodyOnlyChecked ? 1 : 0,
                        stroke: 'rgba(20, 88, 100, 0.5)',
                        color: bodyOnlyChecked ? 'rgba(20, 88, 100, 0.5)' : 'lightgrey'
                    }}
                >
                    <MUIComponents.Icons.ArticleIcon />
                </MUIComponents.Buttons.IconButton>,
                'Enkel op inhoud zoeken',
                'bottom'
            )}
            |
            {WrapInToolTip(
                <MUIComponents.Buttons.IconButton
                    onClick={() => handleOnlyChanges(false, 'exact')}
                    style={{
                        marginLeft: -5,
                        strokeWidth: searchValue.includes('"') ? 1 : 0,
                        stroke: 'rgba(20, 88, 100, 0.5)',
                        color: searchValue.includes('"') ? 'rgba(20, 88, 100, 0.5)' : 'lightgrey'
                    }}
                >
                    <MUIComponents.Icons.FormatQuoteIcon />
                </MUIComponents.Buttons.IconButton>,
                'Exact match',
                'bottom'
            )}
            {WrapInToolTip(
                <MUIComponents.Buttons.IconButton
                    onClick={() => handleOnlyChanges(false, 'AND')}
                    style={{
                        marginLeft: -10,
                        strokeWidth: 0,
                        stroke: 'rgba(20, 88, 100, 0.5)',
                        color: searchValue.includes('AND') ? 'rgba(20, 88, 100, 0.5)' : 'lightgrey',
                        width: 38,
                        height: 38
                    }}
                >
                    <span style={{ fontSize: 20, marginTop: 2, fontWeight: 'bold' }}>&</span>
                </MUIComponents.Buttons.IconButton>,
                'Combineren via AND',
                'bottom'
            )}
            |
        </>
    )

    const snippetContent = (elasticFile: any) => {
        const iconStyle: React.CSSProperties = {
            fontSize: 15,
            position: 'relative',
            top: 5,
            marginRight: 1,
            marginLeft: 1,
            color: 'rgba(20, 88, 100, 0.5)'
        }

        return (
            <Paper
                elevation={2}
                square
                style={{
                    borderLeft:
                        (elasticFile?.ff_do_not_use?.raw ?? '0') === '1'
                            ? 'solid #11545A'
                            : '2px solid #11545A',
                    paddingLeft: 7,
                    paddingBottom: 5,
                    paddingTop: 5
                }}
            >
                <p style={{ paddingRight: 5, paddingLeft: 3 }}>
                    {elasticFile?.body?.snippet && elasticFile?.body?.snippet != '' ?
                        <SnippetDiv dangerouslySetInnerHTML={{ __html: elasticFile?.body?.snippet ?? '' }} />
                        :
                        <Highlighter
                            searchWords={
                                activeSearchValue && activeSearchValue !== '' && !titleOnlyChecked
                                    ? searchComponentBusiness.collectHighlightValues(activeSearchValue)
                                    : []
                            }
                            autoEscape={true}
                            textToHighlight={searchComponentBusiness.collectSnippetPart(elasticFile?.body?.raw ?? '', elasticFile?.body?.snippet ?? '')}
                            highlightStyle={highLightStyle}
                            style={{ color: '#444444' }}
                        />
                    }
                </p>
                <Divider style={{ marginTop: 10, marginBottom: -5 }} />
                <div style={{ display: 'inline-block', width: '80%', color: '#595959' }}>
                    <p style={{ fontSize: 10, marginBottom: -7 }}>
                        <AddIcon style={{ ...iconStyle, marginLeft: -3 }} />{' '}
                        {elasticFile?.created_by?.raw ?? ''} <AccessTimeIcon style={iconStyle} />{' '}
                        {formatDateString(elasticFile?.created_at?.raw ?? '')}
                    </p>
                    <p style={{ fontSize: 10 }}>
                        <DriveFileRenameOutlineIcon style={{ ...iconStyle, marginLeft: -3 }} />{' '}
                        {elasticFile?.updated_by?.raw ?? ''} <AccessTimeIcon style={iconStyle} />{' '}
                        {formatDateString(elasticFile?.last_updated?.raw ?? '')}
                    </p>
                </div>
                <div
                    style={{ display: 'inline-block', width: '20%', position: 'relative', top: 10 }}
                >
                    <img
                        style={{
                            position: 'relative',
                            top: 5,
                            width: 30,
                            marginRight: 3,
                            float: 'right'
                        }}
                        src={MimeTypeHandler(elasticFile?.mime_type?.raw ?? '')}
                    />
                    <MUIComponents.Buttons.IconButton
                        onClick={() => {
                            setSelectedAnchorFile(elasticFile)
                            setShowMetaModal(true)
                        }}
                        style={{
                            color: 'lightgrey',
                            background: 'rgb(211,211,211,0.15)',
                            marginRight: 5,
                            float: 'right'
                        }}
                    >
                        <MUIComponents.Icons.Edit />
                    </MUIComponents.Buttons.IconButton>
                </div>
            </Paper>
        )
    }

    const getArraySize = (array: string[]): number => {
        const copy = [...array]
        const index = copy.indexOf("0")
        if (index && index >= 0) {
            copy.splice(index, 1)
        }

        return copy.length
    }

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgb(226, 234, 236)',
            color: 'black',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid rgb(17, 84, 90)',
        },
    }));

    const iconButtonsContent = (elasticFile: any) => {
        const currentUserhasLiked =
            Array.isArray(elasticFile?.ff_like?.raw) &&
            elasticFile?.ff_like?.raw?.includes(fbUserObject?.providerData[0]?.uid)

        return (
            <div style={{ float: 'right', position: 'relative', top: 30 }}>
                {(elasticFile?.ff_do_not_use?.raw ?? '0') === '1' &&
                    WrapInToolTip(
                        <MUIComponents.Buttons.IconButton
                            disabled={true}
                            style={{
                                color: 'red',
                                background: 'rgb(255, 0, 0, 0.05)',
                                marginRight: 10
                            }}
                        >
                            <MUIComponents.Icons.DoNotDisturbIcon />
                        </MUIComponents.Buttons.IconButton>,
                        'Do not use!',
                        'bottom'
                    )}

                <MUIComponents.Buttons.IconButton
                    onClick={(e) => searchComponentBusiness.handleEponaClick(e, elasticFile)}
                    style={{
                        color: 'lightgrey',
                        background: 'rgb(211,211,211,0.15)',
                        marginRight: 10
                    }}
                >
                    <MUIComponents.Icons.FolderIcon />
                </MUIComponents.Buttons.IconButton>

                < MUIComponents.Badge
                    invisible={Array.isArray(elasticFile?.ff_like?.raw) ? getArraySize(elasticFile.ff_like.raw) <= 0 : true}
                    badgeContent={
                        <HtmlTooltip
                            title={<LikeToolTipContent likesArr={elasticFile?.ff_like?.raw ?? []} fbUserObject={fbUserObject} />}
                        >
                            <p>{Array.isArray(elasticFile?.ff_like?.raw)
                                ? getArraySize(elasticFile.ff_like.raw)
                                : 0}</p>
                        </HtmlTooltip >
                    }
                    color="primary"
                >
                    <MUIComponents.Buttons.IconButton
                        disabled={loading}
                        onClick={(e) => searchComponentBusiness.handleThumbClick(e, elasticFile)}
                        style={{
                            color: searchComponentBusiness.getThumbButtonColor(
                                currentUserhasLiked,
                                true
                            ),
                            background:
                                searchComponentBusiness.getThumbButtonColor(currentUserhasLiked)
                        }}
                    >
                        <MUIComponents.Icons.ThumbUpAltIcon />
                    </MUIComponents.Buttons.IconButton>
                </MUIComponents.Badge >
            </div >
        )
    }

    const documentCellContent = (elasticFile: any) => {
        return (
            <div style={{ marginTop: -25 }}>
                <div style={{ display: 'block', width: '100%' }}>
                    <>
                        <span>
                            <p
                                className="tableTitle, hoverableDiv"
                                onClick={
                                    () => {
                                        setShowBodyModal(true)
                                        setSelectedAnchorFile(elasticFile)
                                        //GA
                                        searchComponentBusiness.submitGAEvent('Interaction', 'See preview', 'Search')
                                    } /*searchComponentBusiness.onClickToSharePoint(elasticFile?.url?.raw ?? "")*/
                                }
                                style={{
                                    fontWeight: 'bold',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block'
                                }}
                                data-text={elasticFile?.title?.raw ?? ''}
                            >
                                <Highlighter
                                    searchWords={
                                        activeSearchValue && activeSearchValue !== '' && !bodyOnlyChecked
                                            ? searchComponentBusiness.collectHighlightValues(activeSearchValue)
                                            : []
                                    }
                                    autoEscape={true}
                                    textToHighlight={String(elasticFile?.title?.raw ?? '')}
                                    highlightStyle={highLightStyle}
                                    style={{ color: '#595959' }}
                                />
                            </p>

                            {WrapInToolTip(
                                <MUIComponents.Buttons.IconButton
                                    onClick={() => searchComponentBusiness.onClickToSharePoint(elasticFile?.url?.raw ?? '', (elasticFile?.extension?.raw ?? '') === "docx")}
                                    style={{
                                        color: 'lightgrey',
                                        background: 'rgb(211,211,211,0.15)',
                                        marginLeft: 5,
                                        marginTop: -35
                                    }}
                                >
                                    <MUIComponents.Icons.LaunchIcon style={{ width: 15, height: 15 }} />
                                </MUIComponents.Buttons.IconButton>,
                                'Extern openen',
                                'bottom'
                            )}
                        </span>

                        {iconButtonsContent(elasticFile)}
                    </>
                </div>

                <div>
                    <p
                        style={{
                            display: 'inline-block',
                            color: '#595959',
                            fontSize: 11,
                            fontWeight: 'normal',
                            marginTop: -10
                        }}
                    >
                        <LeaderboardIcon
                            style={{
                                color: 'rgba(20, 88, 100, 0.5)',
                                marginRight: 5,
                                fontSize: 15,
                                position: 'relative',
                                top: 2
                            }}
                        />
                        {Math.round((elasticFile?._meta?.score ?? 0) * 100) / 100}
                    </p>
                    {elasticFile?.ff_tags?.raw?.map((tag: string) => {
                        return WrapInToolTip(
                            <span style={{ display: 'inline-block', marginLeft: 5 }}>
                                <Chip
                                    style={{
                                        backgroundColor: '#11545A',
                                        color: 'white',
                                        height: 17
                                    }}
                                    label={
                                        tag
                                    } /*onDelete={(e: React.MouseEvent<HTMLButtonElement>) => {e.currentTarget.id = 'ConfirmDialog'; searchComponentBusiness.handleAnchorClick(e); setConfirmationCallback((e: React.MouseEvent<HTMLButtonElement>) => (e: React.MouseEvent<HTMLButtonElement>) => searchComponentBusiness.handleTagDeletion(elasticFile, tag, e))}}*/ /*deleteIcon={<HighlightOffIcon style={{height: 15, margin: 0, padding: 0, marginLeft: -10}} />}*/
                                />
                            </span>,
                            searchComponentBusiness.getActionToolTipText(elasticFile, 'tagsAction'),
                            'bottom'
                        )
                    })}
                    <MUIComponents.Buttons.IconButton
                        id="TagSelector"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            setSelectedAnchorFile(elasticFile)
                            searchComponentBusiness.handleAnchorClick(e)
                        }}
                        style={{
                            color: 'lightgrey',
                            background: 'rgb(211,211,211,0.15)',
                            marginRight: 10,
                            padding: 5,
                            marginLeft: 2
                        }}
                    >
                        <MUIComponents.Icons.LocalOfferIcon
                            style={{ width: 20, height: 20, color: 'lightgray' }}
                        />
                    </MUIComponents.Buttons.IconButton>
                </div>
                {snippetContent(elasticFile)}
            </div>
        )
    }

    const handleOnlyChanges = (checked: boolean, prop: string) => {
        if (prop === 'title') {
            setTitleOnlyChecked(checked)
            if (bodyOnlyChecked && checked) setBodyOnlyChecked(false)
        }
        if (prop === 'body') {
            setBodyOnlyChecked(checked)
            if (titleOnlyChecked && checked) setTitleOnlyChecked(false)
        }
        if (prop === 'exact') {
            //Exlude Match
            if (searchValue.includes('"')) {
                setSearchValue(searchValue.replaceAll('"', '').replace(/\s\s+/g, ' '))
                setShouldRefresh(true)
                return
            }

            // Include Match
            setSearchValue(`"${searchValue}"`)
            setShouldRefresh(true)
        }
        if (prop === 'AND') {
            //Exlude AND
            if (searchValue.includes('AND')) {
                setSearchValue(searchValue.replaceAll('AND', '').replace(/\s\s+/g, ' '))
                setShouldRefresh(true)
                return
            }

            // Include AND
            const arr = searchValue.split(' ')
            let newQueryString = ''
            arr.forEach(term => {
                let partToAdd = term
                if (newQueryString !== '')
                    partToAdd = `${newQueryString} AND ${term}`

                newQueryString = partToAdd
            })
            setSearchValue(newQueryString)
            setShouldRefresh(true)
        }
    }

    return (
        <div style={{ paddingTop: '50px', backgroundColor: "#E2EAEC", width: "100%", minHeight: "100vh", height: "100%", paddingBottom: "50px" }}>
            <Input
                id="search-bar"
                onChange={(e) => {
                    setSearchValue(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                        searchComponentBusiness.onSearch(true)
                        if (getCookieConsentValue() == "true") {
                            ReactGA.event({
                                category: 'Search',
                                action: 'Search',
                                label: searchValue
                            })
                        }
                    }
                }}
                value={searchValue}
                size="small"
                disableUnderline={true}
                placeholder="Zoek documenten..."
                endAdornment={
                    <InputAdornment position="end">
                        {searchOnlySwitches}
                        {searchIconButton}
                    </InputAdornment>
                }
                autoComplete="off"
                style={{
                    width: 600,
                    height: 45,
                    padding: '5px 10px 0px 15px',
                    borderRadius: 2,
                    background: 'white',
                    color: '#444444'
                }}
            />
            <IconButton
                id="DateRange"
                style={{ padding: 7, marginLeft: 10, color: "white" }}
                component="span"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    searchComponentBusiness.handleAnchorClick(e)
                }
            >
                <Badge
                    color="success"
                    variant="dot"
                    style={{
                        visibility:
                            filterConfig?.created_at || filterConfig?.last_updated
                                ? 'visible'
                                : 'hidden'
                    }}
                >
                    <DateRangeIcon
                        style={{
                            width: 25,
                            height: 25,
                            color: '#11545A',
                            visibility: 'visible'
                        }}
                    />
                </Badge>
            </IconButton >
            <IconButton
                id="GlobalFilters"
                style={{ padding: 7, marginLeft: 5 }}
                component="span"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    searchComponentBusiness.handleAnchorClick(e)
                }
            >
                <Badge
                    color="success"
                    variant="dot"
                    style={{
                        visibility: searchComponentBusiness.checkIfGlobalFilterIsActive()
                            ? 'visible'
                            : 'hidden'
                    }}
                >
                    <FilterListIcon
                        style={{
                            width: 25,
                            height: 25,
                            color: '#11545A',
                            visibility: 'visible'
                        }}
                    />
                </Badge>
            </IconButton>

            {(searchComponentBusiness.checkIfGlobalFilterIsActive() || (filterConfig?.created_at || filterConfig?.last_updated)) &&
                <>
                    <span style={{ marginLeft: 5, color: '#11545A' }}>|</span>
                    {WrapInToolTip(< IconButton disabled={loading} color="primary" style={{ color: '#11545A', marginLeft: 5 }} aria-label="Filters herstellen" onClick={e => setFilterConfig(defaultFilter)}>
                        <FilterListOffIcon style={{ height: 25, width: 25 }} />
                    </IconButton>
                        , 'Filters globaal herstellen', 'bottom')}
                </>
            }

            {/* <div style={{position: 'relative', left: -40, width: 600, margin: 'auto', marginBottom: -20, marginTop: -10}}>
                <p style={{textAlign: 'center'}}>
                        <MUIComponents.Checkbox checked={titleOnlyChecked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckChange(e, 'title')} />
                        {`Enkel zoeken op titel`}
                        
                        <MUIComponents.Checkbox style={{marginLeft: 25}} checked={bodyOnlyChecked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckChange(e, 'body')} />
                        {`Enkel zoeken op inhoud`}
                </p>
        </div> */}
            {/* Begin search content below search*/}
            {
                hasSearched ? (
                    <div>
                        <Pagination
                            count={searchComponentBusiness.collectPaginationCount(searchResults?.meta?.page?.total_pages ?? 0)}
                            page={pagination.page}
                            onChange={(e, page) => setPagination({ ...pagination, page })}
                            style={{
                                width: 'fit-content',
                                margin: 'auto',
                                marginBottom: 0,
                                marginTop: 25
                            }}
                            shape="rounded"
                        />
                        <TableContainer>
                            <Table
                                aria-label="simple table"
                                style={{
                                    marginTop: 25,
                                    marginBottom: 10,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '75%',
                                    minWidth: 650,
                                    maxWidth: 1500,
                                    background: 'white',
                                    borderRadius: 2
                                }}
                            >
                                <TableHead style={{ background: '#11545A' }}>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                borderTopLeftRadius: 2,
                                                borderTopRightRadius: 2,
                                                color: 'white',
                                                textAlign: 'left',
                                                minWidth: '20vw',
                                                maxWidth: '20vw',
                                                fontFamily: 'BasierCircle, Arial, sans-serif'
                                            }}
                                        >
                                            Resultaten
                                        </TableCell>
                                        {/*<TableCell style={{color: 'white', textAlign: 'center'}} align="right">{searchComponentBusiness.checkShouldShowHeaderValue('+/-')}</TableCell>
                                    <TableCell style={{borderTopRightRadius: 25, color: 'white', textAlign: 'center'}} align="right">{searchComponentBusiness.checkShouldShowHeaderValue('Belanghebbende')}</TableCell>*/}
                                    </TableRow>
                                    {loading && (
                                        <MUIComponents.LinearLoader
                                            style={{
                                                width: '75%',
                                                minWidth: 650,
                                                maxWidth: 1500,
                                                position: 'absolute'
                                            }}
                                            color="secondary"
                                        />
                                    )}
                                </TableHead>
                                <TableBody>
                                    {searchResults?.results && searchResults?.results?.length > 0 ? (
                                        (searchResults?.results ?? []).map(
                                            (elasticFile: any, index: number) => (
                                                <TableRow
                                                    key={elasticFile?.title?.raw ?? '' + `${index}`}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': {
                                                            border: 0
                                                        }
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{
                                                            minWidth: '20vw',
                                                            maxWidth: '20vw',
                                                            marginTop: -500
                                                        }}
                                                    >
                                                        {documentCellContent(elasticFile)}
                                                    </TableCell>
                                                    {/*<TableCell align="right" style={{width: 15}}>{wrapInToolTip(<div onClick={(e) => searchComponentBusiness.handleThumbClick(e, 'up', elasticFile?.customThumbAction?.thumb_state, elasticFile?.id?.raw)}><ThumbUpAltIcon className="hoverableDiv" style={{color: searchComponentBusiness.getThumbButtonColor(elasticFile?.customThumbAction?.thumb_state === 'up'), marginBottom: 1}} /></div>, searchComponentBusiness.getActionToolTipText(elasticFile, 'customThumbAction'), 'top')}{wrapInToolTip(<div onClick={(e) => searchComponentBusiness.handleThumbClick(e, 'down', elasticFile?.customThumbAction?.thumb_state, elasticFile?.id?.raw)}><ThumbDownAltIcon className="hoverableDiv" style={{color: searchComponentBusiness.getThumbButtonColor(elasticFile?.customThumbAction?.thumb_state === 'down')}} /></div>, searchComponentBusiness.getActionToolTipText(elasticFile, 'customThumbAction'), 'bottom')}</TableCell>
                                <TableCell align="right" style={{width: 150, maxWidth: 150 }}>
                                    {stakeHolderContent(elasticFile)}
                                </TableCell>*/}
                                                </TableRow>
                                            )
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} style={{ height: 150 }}>
                                                <Alert
                                                    severity="info"
                                                    icon={<MUIComponents.Icons.InfoOutlinedIcon sx={{ color: "white" }} />}
                                                    style={{
                                                        textAlign: 'left',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        marginTop: -50,
                                                        color: 'white',
                                                        backgroundColor: "rgb(110, 152, 158)"
                                                    }}
                                                >
                                                    <AlertTitle>
                                                        {loading ? 'Zoeken...' : 'Geen resultaten'}
                                                    </AlertTitle>
                                                    {loading
                                                        ? 'Bezig met zoeken naar bestanden'
                                                        : 'Geen zoekresultaten gevonden onder de huidige term'}
                                                </Alert>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            count={searchComponentBusiness.collectPaginationCount(searchResults?.meta?.page?.total_pages ?? 0)}
                            page={pagination.page}
                            onChange={(e, page) => setPagination({ ...pagination, page })}
                            style={{
                                width: 'fit-content',
                                margin: 'auto',
                                marginBottom: 25,
                                marginTop: 15
                            }}
                            shape="rounded"
                        />
                    </div>
                ) : (
                    <>
                        <Typography variant="subtitle1" style={{ marginTop: 100, marginBottom: 10 }}>
                            Documenten
                        </Typography>
                        <Divider
                            style={{
                                width: '10vw',
                                maxWidth: 275,
                                display: 'block',
                                margin: 'auto',
                                marginBottom: 5
                            }}
                        />
                        <img
                            style={{ width: '25vw', maxWidth: 400, display: 'block', margin: 'auto' }}
                            src="/headerLogo.png"
                        />
                    </>
                )
            }
            {/* <p style={{ textAlign: 'left' }}>{JSON.stringify(searchResults?.results?.[0] ?? {}, null, 4)}</p> */}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                {anchorEl?.id == 'ConfirmDialog' && (
                    <ConfirmationDialog setAnchorEl={setAnchorEl} callback={confirmationCallback} />
                )}
                {anchorEl?.id == 'DateRange' && (
                    <DateRangeFilter
                        setAnchorEl={setAnchorEl}
                        filterConfig={filterConfig}
                        setFilterConfig={setFilterConfig}
                    />
                )}
                {anchorEl?.id == 'GlobalFilters' && (
                    <GlobalFilter
                        setAnchorEl={setAnchorEl}
                        filterConfig={filterConfig}
                        setFilterConfig={setFilterConfig}
                        tagSuggestions={tagSuggestions}
                        collectSuggestions={() => searchComponentBusiness.collectTagSuggestions()}
                    />
                )}
                {anchorEl?.id == 'TagSelector' && (
                    <TagSelector
                        setAnchorEl={setAnchorEl}
                        updateDocument={async (documentInput: any) => {
                            await searchComponentBusiness.updateDocument(documentInput)
                            //GA
                            searchComponentBusiness.submitGAEvent('Meta', 'Tag', 'search')
                        }
                        }
                        selectedFile={selectedAnchorFile}
                        submitNewTags={(tagsInput: Array<string>) =>
                            searchComponentBusiness.submitNewTags(tagsInput)
                        }
                        refresh={(shouldReset: boolean) =>
                            searchComponentBusiness.resetAndRefresh(shouldReset)
                        }
                        tagSuggestions={tagSuggestions}
                        setTagSuggestions={(tags: Array<string>) => setTagSuggestions(tags)}
                        collectSuggestions={() => searchComponentBusiness.collectTagSuggestions()}
                        deleteTagOption={(tagToDelete: string) => searchComponentBusiness.deleteTagOption(tagToDelete)}
                    />
                )}
                {/* anchorEl?.id == "StakeHolderSelector" && <StakeHolderSelector setAnchorEl={setAnchorEl} handleStakeHolderChange={(newValue: string, fileId: string) => searchComponentBusiness.handleStakeHolderChange(newValue, fileId)} selectedFile={selectedAnchorFile} /> */}
            </Popover>

            {
                showMetaModal && (
                    <MetaDataModal
                        setShowMetaModal={setShowMetaModal}
                        elasticfile={selectedAnchorFile}
                        refresh={(shouldReset: boolean) =>
                            searchComponentBusiness.resetAndRefresh(shouldReset)
                        }
                        updateDocument={async (documentInput: any) => {
                            await searchComponentBusiness.updateDocument(documentInput)
                            //GA
                            searchComponentBusiness.submitGAEvent('Meta', 'Edit', 'Search')
                        }
                        }
                    />
                )
            }
            {
                showBodyModal && (
                    <BodyModal
                        setShowBodyModal={setShowBodyModal}
                        elasticFile={selectedAnchorFile}
                        launch={(url: string, isDoc: boolean) => searchComponentBusiness.onClickToSharePoint(url, isDoc)}
                    />
                )
            }

            {
                scrolledIntoTable && loading && (
                    <MUIComponents.LinearLoader
                        style={{ width: '100%', height: 5, position: 'fixed', top: 0, left: 0 }}
                        color="secondary"
                    />
                )
            }
        </div >
    )
}

export default Search

const SnippetDiv = styled.div`
    em {
        font-style: normal;
        background: rgba(20, 88, 100, 0.1);
        color: #595959;
        padding: 1;
        paddingLeft: 5;
        paddingRight: 5;
    }
    
    color: '#444444';
`