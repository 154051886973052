import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CSSProperties } from 'styled-components'
import { MUIComponents } from '.'

interface DialogProps extends MuiDialogProps {
    // you can declare custom props in here
    cancelClick?: React.MouseEventHandler<HTMLButtonElement>
    confirmClick?: React.MouseEventHandler<HTMLButtonElement>
    title: string
    content?: string
    cancelButtonText?: string
    confirmButtonText?: string
    contentComponent?: JSX.Element
    actionsContent?: JSX.Element
    contentStyle?: CSSProperties
    actionsStyle?: CSSProperties
}

export const Dialog = ({
    open,
    onClose,
    cancelClick,
    confirmClick,
    title,
    content,
    cancelButtonText,
    confirmButtonText,
    fullScreen,
    contentStyle = {},
    actionsStyle = {},
    contentComponent,
    actionsContent,
    children,
    ...props
}: DialogProps) => {
    const theme = useTheme()
    const fullScreenResponsive = fullScreen ? useMediaQuery(theme.breakpoints.down('md')) : false

    const dialogActions = () => {
        if (actionsContent) return actionsContent

        if (!cancelButtonText && !confirmButtonText) return undefined

        return (
            <>
                {cancelButtonText && (
                    <MUIComponents.Buttons.Button onClick={cancelClick}>
                        {cancelButtonText}
                    </MUIComponents.Buttons.Button>
                )}

                {confirmButtonText && (
                    <MUIComponents.Buttons.Button onClick={confirmClick}>
                        {confirmButtonText}
                    </MUIComponents.Buttons.Button>
                )}
            </>
        )
    }

    return (
        <MuiDialog
            fullScreen={fullScreenResponsive}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...props}
        >
            {children ? (
                children
            ) : (
                <>
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ fontFamily: contentStyle?.['fontFamily'] }}
                    >
                        {title}
                    </DialogTitle>
                    <DialogContent sx={contentStyle}>
                        {contentComponent}
                        {content && (
                            <DialogContentText id="alert-dialog-description">
                                {content}
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions sx={actionsStyle}>{dialogActions()}</DialogActions>
                </>
            )}
        </MuiDialog>
    )
}

// Example of this component with props:
{
    /* <MUIComponents.Dialog
                open={passwordForgotDialogVisible}
                actionsContent={forgotPasswordActionsContent}
                onClose={() => {
                    setPasswordForgotDialogVisible(false)
                }}
                title="E-mail adres om de email voor je wachtwoord te veranderen te laten verzenden"
                contentComponent={
                    <MUIComponents.TextFields.TextField
                        id="email"
                        label="E-mailadres"
                        name="email"
                        autoFocus={false}
                        onBlur={(e) => {
                            const isEmailValid = validateEmail(e.target.value)

                            if (!isEmailValid) {
                                setPasswordEmailError({ error: true, valid: isEmailValid })
                            } else {
                                setPasswordEmailError({
                                    error: false,
                                    valid: isEmailValid
                                })
                                setPasswordEmail(e.target.value)
                            }
                        }}
                        error={passwordEmailError.error}
                        {...(passwordEmailError.error && {
                            helperText: !passwordEmailError.valid
                                ? 'Het ingevulde E-mailadres is geen geldig E-mailadres.'
                                : 'Het invullen van uw E-mailadres is verplicht.'
                        })}
                    />
                }
                fullScreen
            /> */
}