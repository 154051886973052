import MostUsedWebsites from './subComponents/MostUsedWebsites'
import FrequentlyUsedWebsite from './subComponents/FrequentlyUsedWebsite'
import SearchFieldHomepage from './subComponents/SearchFieldHomepage'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

const Home = () => {

    useEffect(() => {
        //Google analytics page viewer
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
    }, [])

    return (
        <>
            <div style={{ backgroundColor: '#E2EAEC', display: 'grid', placeItems: 'center', width: "100%" }}>
                <div style={{ width: "100%", paddingBottom: "1em", paddingTop: "0em", backgroundColor: "#79a2a8" }} >
                    <SearchFieldHomepage />
                </div>
                <div style={{ width: "80%" }}>
                    <div style={{ minHeight: "20vh" }}>
                        <MostUsedWebsites />
                    </div>
                    <div style={{ minHeight: "70vh" }} >
                        <FrequentlyUsedWebsite />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home

//backgroundColor: "#11545A",

//<div style={{ backgroundImage: `url(${backgroundHome})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: 'center' }}></div> // Fullpage

// style={{ background: 'rgba(20, 88, 100, 0.5)', paddingBottom: "1rem", width: "100%", minHeight: "8vh" }} // petrol searchfield

// backgroundImage: `url(${backgroundHome})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: 'center',