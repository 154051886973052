import { initializeApp } from 'firebase/app';

const handleJson = (jsonConfig: string) => {
    const parsed = JSON.parse(jsonConfig);

    return {
        apiKey: parsed.REACT_APP_LAYWER_PORTAL_FIREBASE_KEY,
        authDomain: parsed.REACT_APP_LAWYER_PORTAL_FIREBASE_DOMAIN,
        databaseURL: parsed.REACT_APP_LAWYER_PORTAL_FIREBASE_DATABASE,
        projectId: parsed.REACT_APP_LAWYER_PORTAL_FIREBASE_PROJECT_ID,
        storageBucket: parsed.REACT_APP_LAWYER_PORTAL_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: parsed.REACT_APP_LAWYER_PORTAL_FIREBASE_SENDER_ID,
        appId: parsed.REACT_APP_LAWYER_PORTAL_FIREBASE_APP_ID,
    };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const firebaseApp = initializeApp(handleJson(process.env.REACT_APP_LAYWER_PORTAL_FIREBASE.toString('utf8')));

export default firebaseApp;