import { Button, Divider, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Chip, Autocomplete, TextField } from '@mui/material'
import { useEffect, useState } from 'react';
import { MimeTypeHandler } from '../../../../utils/MimeTypeHandler';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { MUIComponents } from '../../../muiComponents';

const GlobalFilter = ({ setAnchorEl, filterConfig, setFilterConfig, tagSuggestions, collectSuggestions }: {
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null | undefined>>,
    filterConfig: any,
    setFilterConfig: React.Dispatch<React.SetStateAction<any | null>>,
    tagSuggestions: Array<string>,
    collectSuggestions: () => Promise<void>
}) => {

    // Constants
    const DOC_TYPES = ['docx', 'pdf', 'xlsx']
    const TEXTFIELD_FILTERS = ['ff_taal', 'ff_team', 'ff_partner', 'ff_point_of_view', 'ff_business', 'ff_state', 'ff_source'/*, 'ff_dossier'*/]
    //TODO: datastore
    const OPTIONS = {
        //TODO: this also exists within MetaDataModal => Place somewhere and reuse!
        ff_taal: [{ label: "Geen filter", value: "" }, { value: "NL", label: "Nederlands" }, { value: "EN", label: "Engels" }, { value: "FR", label: "Frans" }, { value: "DE", label: "Duits" }],
        ff_team: [{ label: "Geen filter", value: "" }, { label: "M&A", value: "M&A" }, { label: "Commercial", value: "Commercial" }, { label: "Tech", value: "Tech" }, { label: "Corporate", value: "Corporate" }, { label: "Litige", value: "Litige" }],
        ff_partner: [{ label: "Geen filter", value: "" }, { label: "Anneleen", value: "Anneleen" }, { label: "Dimitri", value: "Dimitri" }, { label: "Tessa", value: "Tessa" }, { label: "Maxime", value: "Maxime" }],
        ff_point_of_view: [{ label: "Geen filter", value: "" }, { label: "Supplier", value: "Supplier" }, { label: "Vendor", value: "Vendor" }, { label: "Founder", value: "Founder" }, { label: "Investor", value: "Investor" }, { label: "Buyer", value: "Buyer" }, { label: "Seller", value: "Seller" }, { label: "Processor", value: "Processor" }, { label: "Controller", value: "Controller" }],
        ff_business: [{ label: "Geen filter", value: "" }, { label: "B2B", value: "B2B" }, { label: "B2C", value: "B2C" }],
        ff_state: [{ label: "Geen filter", value: "" }, { label: "Draft", value: "Draft" }, { label: "Markup", value: "Markup" }, { label: "Execution Copy", value: "Execution Copy" }, { label: "Template", value: "Template" },],
        ff_source: [{ label: "Geen filter", value: "" }, { label: "Four & Five", value: "Four & Five" }, { label: "3rd Party", value: "3rd Party" }]
    }

    // States
    const [loading, setLoading] = useState<boolean>(false)
    const [needsConfirmation, setNeedsConfirmation] = useState<boolean>(false)
    const [filterValues, setFilterValues] = useState<any>({})
    const [tagsInput, setTagsInput] = useState<string>('')

    useEffect(() => {
        setFilterValues({
            extension: [...filterConfig?.extension ?? []],
            ff_team: filterConfig?.ff_team ?? '',
            ff_partner: filterConfig?.ff_partner ?? '',
            ff_point_of_view: filterConfig?.ff_point_of_view ?? '',
            ff_business: filterConfig?.ff_business ?? '',
            ff_state: filterConfig?.ff_state ?? '',
            ff_source: filterConfig?.ff_source ?? '',
            //ff_dossier: filterConfig?.ff_dossier ?? '',
            ff_taal: filterConfig?.ff_taal ?? '',
            ff_do_not_use: filterConfig?.ff_do_not_use,
            ff_tags: [...filterConfig?.ff_tags ?? []],
            //ff_like: filterConfig?.ff_like
        })
    }, [])

    useEffect(() => {
        if (!tagSuggestions || tagSuggestions.length <= 0) {
            setLoading(true)
            suggestionHandler()
        }
    }, [])

    useEffect(() => {
        if (needsConfirmation) {
            setNeedsConfirmation(false)
            confirm()
        }
    }, [needsConfirmation])

    //Collect tag suggestions
    const suggestionHandler = async () => {
        await collectSuggestions()
        setLoading(false)
    }

    const close = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) e.preventDefault()
        setAnchorEl(null)
    }

    const confirm = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) e.preventDefault()

        const newFilterConfig = { ...filterValues }

        Object.keys(newFilterConfig).forEach((key, index) => {
            if (!newFilterConfig[key] || (Array.isArray(newFilterConfig[key]) && !newFilterConfig[key].length) || newFilterConfig[key] === null || newFilterConfig[key] === '')
                delete newFilterConfig[key]
        })

        setFilterConfig(newFilterConfig);

        setAnchorEl(null)
    }

    const resetFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setFilterValues({})

        setNeedsConfirmation(true)
    }

    const handleMultipleSelectChange = (e: SelectChangeEvent<any>, prop: string) => {
        e?.preventDefault()
        const {
            target: { value },
        } = e;

        changePropValueHandler(prop, typeof value === 'string' ? value.split(',') : value)
    }

    const handleAutoSelectChange = (_: object, value: any, prop: string) => {
        changePropValueHandler(prop, typeof value === 'string' ? value.split(',') : value)
    }

    const handleSelectChange = (e: SelectChangeEvent<unknown>, prop: string) => {
        changePropValueHandler(prop, e.target.value)
    }

    const changePropValueHandler = (prop: string, value: any) => {
        setFilterValues({
            ...filterValues,
            [prop]: value
        })
    }

    const handleTagsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e?.preventDefault()

        const input = e?.target?.value
        setTagsInput(input?.toLowerCase())
    }

    const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, prop: string) => {
        const checked: any = e?.target?.checked ?? false
        changePropValueHandler(prop, checked)
    }

    const handleActiveTagDelete = (_: React.MouseEvent<HTMLButtonElement>, tag: string) => {
        console.log("setting")
        const activeTags = filterValues?.ff_tags ?? []
        setFilterValues({
            ...filterValues,
            ff_tags: activeTags.filter((loopTag: string) => loopTag !== tag)
        })
    }

    return (
        <div style={{ width: 400, height: 'auto', maxHeight: '50vh', padding: 10, paddingTop: 0, paddingBottom: 0, borderLeft: '5px solid #11545A', overflow: 'auto' }}>
            {loading && <MUIComponents.LinearLoader color="secondary" style={{ marginLeft: -10, marginRight: -10 }} />}

            <FormControl sx={{ m: 1, width: 375, marginTop: 1 }} disabled={loading}>
                <Autocomplete
                    id="tags-outlined"
                    multiple
                    value={filterValues?.ff_tags ?? []}
                    options={tagSuggestions.sort()}
                    getOptionLabel={(option: string) => option}
                    filterSelectedOptions
                    noOptionsText="Geen filter suggesties"
                    disabled={loading}
                    size='medium'
                    renderInput={(params) => {
                        return (<TextField
                            {...params}
                            value={tagsInput ?? ''}
                            onChange={handleTagsInputChange}
                            label="Tags"
                            InputLabelProps={{ style: { fontSize: 15 } }}
                        />)
                    }}
                    renderTags={(tagValue: string[]) =>
                        tagValue.map((value: string, index: number) => (
                            <Chip
                                key={`${index}_${value}`}
                                label={value}
                                onDelete={(e) => handleActiveTagDelete(e, value)}
                                disabled={loading}
                                sx={{
                                    '& .MuiChip-deleteIcon': {
                                        color: 'lightgray',
                                    },
                                    '& .MuiChip-deleteIcon: hover': {
                                        color: 'rgb(223, 240, 239)',
                                    }
                                }}
                                style={{
                                    marginLeft: 2,
                                    marginTop: 2,
                                    backgroundColor: '#11545A',
                                    color: 'white'
                                }}
                            />
                        ))
                    }
                    onChange={(_: object, value: any) => handleAutoSelectChange(_, value, 'ff_tags')}
                />
            </FormControl>

            <FormControl sx={{ ml: 1, width: 375 }} disabled={loading}>
                <MUIComponents.Select.SingleSelect
                    labelContent='Status'
                    disabled={loading}
                    onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_state')}
                    menuItems={OPTIONS.ff_state ?? []}
                    size="small"
                    value={filterValues?.['ff_state'] ?? ''}
                />
            </FormControl>

            <FormControl sx={{ ml: 1, width: 375 }} disabled={loading}>
                <MUIComponents.Select.SingleSelect
                    labelContent='Team'
                    disabled={loading}
                    onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_team')}
                    menuItems={OPTIONS.ff_team ?? []}
                    size="small"
                    value={filterValues?.['ff_team'] ?? ''}
                />
            </FormControl>

            <FormControl sx={{ ml: 1, width: 375 }} disabled={loading}>
                <MUIComponents.Select.SingleSelect
                    labelContent='Partner'
                    disabled={loading}
                    onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_partner')}
                    menuItems={OPTIONS.ff_partner ?? []}
                    size="small"
                    value={filterValues?.['ff_partner'] ?? ''}
                />
            </FormControl>

            <FormControl sx={{ ml: 1, width: 375 }} disabled={loading}>
                <MUIComponents.Select.SingleSelect
                    labelContent='Point of view'
                    disabled={loading}
                    onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_point_of_view')}
                    menuItems={OPTIONS.ff_point_of_view ?? []}
                    size="small"
                    value={filterValues?.['ff_point_of_view'] ?? ''}
                />
            </FormControl>

            <FormControl sx={{ ml: 1, width: 375 }} disabled={loading}>
                <MUIComponents.Select.SingleSelect
                    labelContent='Taal'
                    disabled={loading}
                    onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_taal')}
                    menuItems={OPTIONS.ff_taal ?? []}
                    size="small"
                    value={filterValues?.['ff_taal'] ?? ''}
                />
            </FormControl>

            <FormControl sx={{ ml: 1, width: 375 }} disabled={loading}>
                <MUIComponents.Select.SingleSelect
                    labelContent='Bron'
                    disabled={loading}
                    onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_source')}
                    menuItems={OPTIONS.ff_source ?? []}
                    size="small"
                    value={filterValues?.['ff_source'] ?? ''}
                />
            </FormControl>

            <FormControl sx={{ ml: 1, width: 375 }} disabled={loading}>
                <MUIComponents.Select.SingleSelect
                    labelContent='Business'
                    disabled={loading}
                    onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, 'ff_business')}
                    menuItems={OPTIONS.ff_business ?? []}
                    size="small"
                    value={filterValues?.['ff_business'] ?? ''}
                />
            </FormControl>

            <FormControl sx={{ m: 1, width: 375, marginTop: 2 }} disabled={loading}>
                <InputLabel style={{ fontSize: 15 }} id="extension-label">Extensie</InputLabel>
                <Select
                    defaultValue={filterValues?.extension ?? []}
                    value={filterValues?.extension ?? []}
                    label="Extensie"
                    onChange={(e: SelectChangeEvent<any>) => handleMultipleSelectChange(e, 'extension')}
                    multiple
                    size='medium'
                    input={<OutlinedInput label="Name" />}
                >
                    {DOC_TYPES.map(docType => {
                        return <MenuItem key={docType} value={docType}><img style={{ float: 'right', position: 'relative', top: 5, width: 30, marginRight: 3 }} src={MimeTypeHandler(docType ?? '')} /> {docType}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <FormControl sx={{ ml: 1, width: 375, marginTop: -1.5, marginBottom: -2, marginLeft: -0.5 }}>
                <p>
                    <MUIComponents.Checkbox checked={filterValues?.ff_do_not_use} onChange={(e) => handleCheckBoxChange(e, 'ff_do_not_use')} />
                    {`Inclusief "do not use"`}
                </p>
            </FormControl>

            { /* <FormControl sx={{ m: 1, width: 375, marginTop: 0 }}>
                <TrippleBooleanInput
                    buttonTexts={["Likes", "Geen filter likes", "Beide"]}
                    currentValue={filterValues?.ff_like ?? null}
                    changePropValueHandler={(value: string | null) => changePropValueHandler('ff_like', value)}
                    disabled={true}
                />
                * Like filter onder onderhoud
                </FormControl> */ }

            { /* <FormControl sx={{ m: 1, width: 375, marginTop: 0 }}>
                <TrippleBooleanInput
                    buttonTexts={["Do not use", "Use", "Beide"]}
                    currentValue={filterValues?.ff_do_not_use ?? null}
                    changePropValueHandler={(value: string | null) => changePropValueHandler('ff_do_not_use', value)}
                    disabled={loading}
                />
            </FormControl> */ }

            {/* TEXTFIELD_FILTERS?.map((filterProp: string) =>
                <FormControl sx={{ m: 1, width: 375, marginTop: 0 }} disabled={loading} key={filterProp}>
                    <MUIComponents.Select.SingleSelect
                        disabled={loading}
                        onChange={(e: SelectChangeEvent<unknown>) => handleSelectChange(e, filterProp)}
                        labelContent={filterProp}
                        menuItems={OPTIONS[filterProp] as {label: string, value: string}[] ?? []}
                        size="small"
                        value={filterValues?.[filterProp] ?? ''}
                    />
                </FormControl>
            ) */}

            <div style={{ position: 'sticky', bottom: 0, background: 'white', margin: -10, height: 50, zIndex: 9000 }}>
                <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                <IconButton disabled={loading} color="primary" style={{ color: '#11545A', marginTop: -7, marginLeft: 5 }} aria-label="Filters herstellen" onClick={e => resetFilters(e)}>
                    <FilterListOffIcon style={{ height: 20 }} />
                </IconButton>
                <span style={{ float: 'right', marginBottom: 10, marginTop: -5, marginRight: 25 }}>
                    <Button size="small" style={{ marginRight: 5, color: '#11545A', border: '1px solid #11545A' }} variant="outlined" onClick={(e) => close(e)}>Annuleren</Button>
                    <Button disabled={loading} size="small" style={{ backgroundColor: '#11545A', color: 'white' }} variant="contained" onClick={(e) => confirm(e)}>Bevestigen</Button>
                </span>
            </div>
        </div>
    )
}

export default GlobalFilter