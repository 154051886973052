import React from 'react'
import MuiAutocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete'
import styled from 'styled-components'

import { genericFontSize, noop } from '../../utils/utils'
import { MUIComponents } from '.'

const StyledAutocomplete = styled(MuiAutocomplete)``

type GenericAutoComplete = MuiAutocompleteProps<
    Record<string, string>,
    boolean,
    boolean,
    boolean,
    React.ElementType
>

interface AutocompleteProps extends Omit<GenericAutoComplete, 'renderInput'> {
    // you can declare custom props in here
    id: string
    label: string
    error?: boolean
    errorText?: string
    required?: boolean
}

export const AutoComplete = ({
    onChange = noop,
    required = false,
    ...props
}: AutocompleteProps) => (
    <StyledAutocomplete
        value={props.value}
        id={`autocomplete-${props.id}`}
        sx={{ minWidth: 200 }}
        options={props.options}
        getOptionLabel={(option) => (option as Record<string, string>).label}
        renderInput={(params) => (
            <MUIComponents.TextFields.TextField
                {...params}
                autoFocus={false}
                required={required}
                label={props.label}
                InputProps={{ ...params.InputProps, style: { fontSize: genericFontSize } }}
                InputLabelProps={{
                    ...params.InputLabelProps,
                    style: { fontSize: genericFontSize }
                }}
                inputProps={{
                    ...params.inputProps,
                    style: { fontSize: genericFontSize },
                    autoComplete: 'new-password' // disable autocomplete and autofill
                }}
                error={props.error}
                {...(props.error && {
                    helperText: props.errorText
                })}
            />
        )}
        onChange={
            onChange as
            | ((
                event: React.SyntheticEvent<Element, Event>,
                value: unknown,
                reason: AutocompleteChangeReason,
                details?: AutocompleteChangeDetails<unknown> | undefined
            ) => void)
            | undefined
        }
    />
)

// Example of this component with props:
{
    /* <MUIComponents.AutoComplete
    error={bikeBrandError}
    errorText="Het selecteren van een fiets merk is verplicht."
    value={bikeBrand}
    label="Fiets merk"
    options={bikeBrands.map((brand) => ({
        value: brand.id ?? '',
        label: brand.name ?? ''
    }))}
    id="select-bike-brand"
    onChange={(e, newValue) => {
        setBikeBrand(newValue as { value: string; label: string })
        setBikeBrandError(!newValue)
        setData({
            ...props.data,
            bike_brand: newValue as { value: string; label: string }
        })

        return
    }}
/> */
}