import { MUIComponents } from '../components/muiComponents'
import { Maybe } from '../types/GQLTypes'

export const formatDateString = (dateString: string): string => {
    const dt = new Date(dateString)

    return `${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt
            .getHours()
            .toString()
            .padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
}

export const removeEmptyFromObject = (obj: { [key: string]: unknown }) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

export const noop = (): undefined => undefined

export const validateEmail = (email: string) => {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    return pattern.test(email)
}

export const genericFontSize = '20px'

export const generateRandomPassword = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const passwordLength = 12 // minimum is 8 characters
    let password = ''
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber + 1)
    }
    return password
}

export const convertCodeBasedMenuItems = (dataArr: { id: string; code: string; name: string }[]) =>
    dataArr.map((item) => ({ label: item.name, value: item.id }))

type ErrorWithMessage = {
    message: string
}

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage =>
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
    if (isErrorWithMessage(maybeError)) return maybeError

    try {
        return new Error(JSON.stringify(maybeError))
    } catch {
        // fallback in case there's an error stringifying the maybeError
        // like with circular references for example.
        return new Error(String(maybeError))
    }
}

export const getErrorMessage = (error: unknown) => toErrorWithMessage(error).message

export const removeEmptyValuesFromArray = (values: unknown[]) => values.filter((x) => x)

export const getValuesFromObjectByKey = (values: Record<string, unknown>, keyToFind: string) =>
    Object.entries(values).map(([key, value]) => (key.startsWith(keyToFind) ? value : undefined))

export const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
}

export const getComponent = (componentType: Maybe<string> | undefined, props: any) => {
    switch (componentType) {
        case 'TEXT':
            return MUIComponents.TextFields.TextField({
                ...props,
                required: true,
                autoFocus: false
            })
        case 'NUMBER':
            return MUIComponents.TextFields.TextField({
                ...props,
                required: true,
                autoFocus: false
            })
        case 'LIST':
            return MUIComponents.Select.SingleSelect(props)
        default:
            return MUIComponents.TextFields.TextField({
                ...props,
                required: true,
                autoFocus: false
            })
    }
}

export const getInitials = (fullName: string) => {
    const allNames = fullName.trim().split(' ')

    const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`
        }
        return acc
    }, '')

    return initials
}

export const ConditionalRender = ({
    condition,
    children
}: {
    condition: boolean
    children: JSX.Element
}) => (condition ? children : null)

export const isInThePast = (date: Date) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date < today
}
export const checkIfValueIncludesSearchValue = (value: string | undefined, searchValue: string) =>
    value?.toString().toLowerCase().includes(searchValue)

export const getAmountByTwoDecimals = (
    value: Maybe<string> | Maybe<number> | undefined
): number | string => {
    if (!value || value === 'NaN') return '/'

    return Number(value)?.toFixed(2)
}