import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton'
import MuiLoadingButton, {
    LoadingButtonProps as MuiLoadingButtonProps
} from '@mui/lab/LoadingButton'
import styled, { css, CSSProperties } from 'styled-components'
import { TooltipProps } from '@mui/material/Tooltip'
import { MUIComponents } from '.'

const StyledButton = styled(MuiButton)`
    ${(props) =>
        props.variant === 'text' &&
        props.color === undefined &&
        css`
            color: ${(props) => props.theme.palette.primary.main + '!important'};
        `}

    ${(props) =>
        props.variant === 'contained' &&
        props.color === undefined &&
        css`
            background-color: ${(props) => props.theme.palette.primary.main + '!important'};
        `}

    &.Mui-disabled {
        pointer-events: unset !important;
        cursor: not-allowed !important;
    }
`

interface ButtonProps extends MuiButtonProps {
    // you can declare custom props in here
    tooltip?: { placement?: TooltipProps['placement']; content: TooltipProps['title'] }
}

export const Button = ({
    // assign default values here
    disabled = false,
    fullWidth = false,
    size = 'medium',
    variant = 'contained',
    tooltip = undefined,
    ...props
}: ButtonProps) => (
    <MUIComponents.Tooltip
        placement={tooltip?.placement ?? 'bottom'}
        title={tooltip?.content ?? ''}
    >
        <StyledButton
            disabled={disabled}
            size={size}
            variant={variant}
            fullWidth={fullWidth}
            {...props}
        />
    </MUIComponents.Tooltip>
)

const StyledIconButton = styled(MuiIconButton)`
    background-color: ${(props) => props.theme.palette.primary.main + '!important'};
    color: ${(props) => props.theme.palette.primary.contrastText + '!important'};
`

interface IconButtonProps extends MuiIconButtonProps {
    // you can declare custom props in here
    tooltip?: { placement?: TooltipProps['placement']; content: TooltipProps['title'] }
    themeColor?: boolean
    style?: CSSProperties
}

export const IconButton = ({
    // assign default values here
    disabled = false,
    size = 'medium',
    tooltip = undefined,
    themeColor = false,
    style = {},
    ...props
}: IconButtonProps) => (
    <MUIComponents.Tooltip
        placement={tooltip?.placement ?? 'bottom'}
        title={tooltip?.content ?? ''}
    >
        {themeColor ? (
            <StyledIconButton
                style={style}
                disabled={disabled}
                size={size}
                {...props}
            ></StyledIconButton>
        ) : (
            <MuiIconButton style={style} disabled={disabled} size={size} {...props}></MuiIconButton>
        )}
    </MUIComponents.Tooltip>
)

interface LoadingButtonProps extends MuiLoadingButtonProps {
    // you can declare custom props in here
    tooltip?: { placement?: TooltipProps['placement']; content: TooltipProps['title'] }
    icon?: React.ReactNode
    content: string
}

const StyledLoadingButton = styled(MuiLoadingButton)`
    ${(props) =>
        props.variant === 'text' &&
        props.color === undefined &&
        css`
            color: ${(props) => props.theme.palette.primary.main + '!important'};
        `}

    ${(props) =>
        props.variant === 'contained' &&
        props.color === undefined &&
        !props.loading &&
        !props.disabled &&
        css`
            background-color: ${(props) => props.theme.palette.primary.main + '!important'};
        `}
`

export const LoadingButton = ({
    loading = false,
    loadingPosition = 'start',
    icon,
    variant = 'contained',
    tooltip = undefined,
    ...props
}: LoadingButtonProps) => (
    <MUIComponents.Tooltip
        placement={tooltip?.placement ?? 'bottom'}
        title={tooltip?.content ?? ''}
    >
        <StyledLoadingButton
            loading={loading}
            loadingPosition={loadingPosition}
            startIcon={icon}
            variant={variant}
            {...props}
        >
            {props.content}
        </StyledLoadingButton>
    </MUIComponents.Tooltip>
)

// Example of the IconButton component with props:
{
    /* <MUIComponents.Buttons.IconButton
                aria-label="toggle password visibility"
                onClick={(e) => {
                    return
                }}
                onMouseDown={(e) => {
                    e.preventDefault()
                }}
                edge="end"
                tooltip={{ placement: 'top', content: 'WOHOO' }}
            >
                <MUIComponents.Icons.visibilityOff />
            </MUIComponents.Buttons.IconButton> */
}

// Example of the Button component with props:
{
    /* <MUIComponents.Buttons.Button
                id="Clothes"
                variant="text"
                fullWidth
                onClick={() => {
                    console.log('clicked')
                }}
                tooltip={{ placement: 'top', content: 'WOHOO' }}
            >
                Next
            </MUIComponents.Buttons.Button> */
}

// ! IMPORTANT: If you pass the optional tooltip as a prop, it will not be shown when the loading state is active
// Example of the LoadingButton component with props:
{
    /* <MUIComponents.Buttons.LoadingButton
                id="login"
                type="submit"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                content="Login"
                loading={false}
                icon={<MUIComponents.Icons.LoginOutlinedIcon />}
                onClick={() => {
                    console.log('clicked')
                }}
                tooltip={{ placement: 'top', content: 'WOHOO' }}
            /> */
}