import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { IconButton, Input } from '@mui/material';

const SearchFieldHomepage = () => {
    const [searchInput, setSearchInput] = useState<string>("")
    const navigateTo = useNavigate();

    const handleSearchInput = (value: string) => {
        setSearchInput(value)
    }

    const searchDocuments = () => {
        if (searchInput) {
            navigateTo(`/search?value=${searchInput}`)
            setSearchInput("")
        }
    }

    const searchIconButton = (
        <IconButton aria-label="search" onClick={() => searchDocuments()}>
            <SearchIcon style={{ strokeWidth: 1, stroke: 'grey' }} />
        </IconButton>
    )

    return (
        <div style={{ display: 'grid', placeItems: 'center', width: "80%", margin: 'auto' }}>
            <div style={{ maxWidth: "1920px", maxHeight: "20vh", minWidth: "60%" }}>
                <Stack
                    direction={{ xs: 'column', sm: 'column', md: "row" }}
                    justifyContent="center"
                    spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <Input
                        id="search-bar"
                        onChange={(e) => {
                            handleSearchInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key == 'Enter') searchDocuments();
                        }}
                        value={null}
                        size="small"
                        disableUnderline={true}
                        placeholder="Zoek documenten..."
                        endAdornment={<InputAdornment position="end">{searchIconButton}</InputAdornment>}
                        autoComplete='off'
                        style={{ width: 600, height: 45, padding: '5px 10px 0px 15px', borderRadius: 2, background: 'white', marginTop: 20, marginBottom: 3, color: '#444444' }}
                    />
                </Stack>
            </div>
        </div>
    )
}

export default SearchFieldHomepage