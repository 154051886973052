import { Popover } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { fbUserObjectState, roleState } from "../../../store/store";
import { SelectOption, UserMgmtOptions } from "../../../types";
import { MUIComponents } from "../../muiComponents";
import EditDialog from "./EditDialog";

const UserManagement = () => {
    const navigate = useNavigate();

    // Constants   
    const OPTIONS: UserMgmtOptions = {
        ROLES: [{ value: "user", label: "Gebruiker" }, { value: "news", label: "Nieuwsbeheerder" }, {value: "globalnews", label: "Globale nieuwsbeheerder"}, { value: "admin", label: "Beheerder" }],
        TEAMS: [{ label: "Geen", value: "" }, { label: "M&A", value: "M&A" }, { label: "Commercial", value: "Commercial" }, { label: "Tech", value: "Tech" }, { label: "Corporate", value: "Corporate" }, { label: "Litige", value: "Litige" }],
        POSITIONS: [{ value: "vennoot", label: "Vennoot" }, { value: "senior", label: "Senior" }, { value: "junior", label: "Junior" }],
        INVOICEMERGER: [{ value: "yes", label: "Ja" }, { value: "no", label: "Nee" }]
    }

    //Recoil
    const fbUserObject = useRecoilValue(fbUserObjectState);
    const roleStateString = useRecoilValue(roleState);

    //TODO: type for users
    const [users, setUsers] = useState<any>([])
    const [selectedUser, setSelectedUser] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>()

    useEffect(() => {
        collectUsers()

        if (!roleStateString || roleStateString !== 'admin')
            navigate('/home')
    }, [])

    const collectUsers = async () => {
        try {
            setLoading(true)
            const collectedUsers: any = await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                action: 'collectUsers'
            }, {
                headers: {
                    uid: fbUserObject?.providerData[0]?.uid ?? '',
                    'access-token': fbUserObject?.accessToken ?? ''
                }
            })

            setUsers(collectedUsers.data)
        } catch (err) {
            //TODO: err modal
        } finally {
            setLoading(false)
        }
    }

    const handleEditButton = (e: React.MouseEvent<HTMLButtonElement>, user: any) => {
        e?.preventDefault()

        setAnchorEl(e.currentTarget)
        setSelectedUser(user)
    }

    const getOptionLabel = (options: SelectOption[], value: string) => {
        return options.find(role => role?.value === value)?.label ?? ''
    }

    return (
        <>
            <div style={{ backgroundColor: "#E2EAEC", minHeight: "100vh" }}>
                <div style={{ width: "100%", height: 85, margin: 0, padding: 0, paddingBottom: "0em", paddingTop: "0em", backgroundColor: "#79a2a8" }}>
                    <h1 style={{ margin: 0, padding: 0, color: 'white', position: 'relative', transform: 'translateY(-50%)', top: '50%' }}>Gebruikersbeheer</h1>
                </div>

                <MUIComponents.Table
                    showPagination={false}
                    loading={loading}
                    sx={{
                        "& th": {
                            backgroundColor: "#11545A"
                        }
                    }}
                    alert={{
                        title: "Zoeken...",
                        details: <p>Bezig met zoeken naar bestanden</p>,
                        stylingElements: {
                            icon: { fontSize: '15px' },
                            message: { fontSize: '12px' },
                            title: { fontSize: '15px' }
                        },
                        icon: <MUIComponents.Icons.InfoOutlinedIcon />,
                        style: {
                            color: 'white',
                            backgroundColor: "rgb(110, 152, 158)",
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            transform: 'unset',
                            position: 'unset'
                        }
                    }}
                    linearLoaderColor="secondary"
                    hover="true"
                    selection={false}
                    style={{
                        marginTop: 50,
                        marginBottom: 150,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '60%',
                        minWidth: 650,
                        maxWidth: 1500,
                        background: 'white',
                        borderRadius: 2
                    }}
                    columns={[
                        {
                            dataKey: 'fullName',
                        },
                        {
                            dataKey: 'email',
                        },
                        {
                            dataKey: 'team',
                        },
                        {
                            dataKey: 'role',
                            customComponent: user => {
                                return (
                                    <p>{getOptionLabel(OPTIONS.ROLES, user?.role?.toString())}</p>
                                )
                            }
                        },
                        {
                            dataKey: 'position',
                            customComponent: user => {
                                return (
                                    <p>{getOptionLabel(OPTIONS.POSITIONS, user?.position?.toString())}</p>
                                )
                            }
                        },
                        {
                            dataKey: 'invoiceMerger',
                            customComponent: user => {
                                return (
                                    <p>{getOptionLabel(OPTIONS.INVOICEMERGER, user?.invoiceMerger?.toString())}</p>
                                )
                            }
                        },
                        {
                            dataKey: "",
                            customComponent: user => (
                                <MUIComponents.Buttons.IconButton id="EditDialog" onClick={(e) => handleEditButton(e, user)} style={{ marginLeft: -5, margin: 0, color: 'lightgrey', background: 'rgb(211,211,211,0.15)' }}><MUIComponents.Icons.Edit /></MUIComponents.Buttons.IconButton>
                            )
                        }
                    ]}
                    tableHeaders={[
                        { title: 'Naam', filtering: { filter: false, filterKey: 'fullName' } },
                        { title: 'E-mail', filtering: { filter: false, filterKey: 'email' } },
                        { title: 'Team', filtering: { filter: false, filterKey: 'team' } },
                        { title: 'Rol', filtering: { filter: false, filterKey: 'role' } },
                        { title: 'Functie', filtering: { filter: false, filterKey: 'position' } },
                        { title: 'Facturen samenvoegen', filtering: { filter: false, filterKey: 'invoiceMerger' } },
                        { title: '', filtering: { filter: false, filterKey: '' } },
                    ]}
                    rows={users}
                />

                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    {anchorEl?.id == "EditDialog" && <EditDialog setAnchorEl={setAnchorEl} user={selectedUser} collectUsers={collectUsers} OPTIONS={OPTIONS} />}
                </Popover>

            </div>
        </>
    );
}

export default UserManagement;