import { Avatar, Grid } from '@mui/material';
import { CardValue } from '../../../../types/index'

const WebsiteCard = ({ cardValue }: { cardValue: CardValue }) => {
    return (
        <Grid item xs={4} sm={4} md={4} style={{ paddingTop: '1rem', paddingRight: '1rem', paddingBottom: '1rem', minWidth: "250px" }} >
            <a href={cardValue?.link} style={{ textDecoration: "none", color: "black" }} target="_blank" rel="noreferrer">
                <Grid key={cardValue?.id} container textAlign="center" alignItems="center" justifyContent="center" columns={{ xs: 4, sm: 8, md: 12 }} style={{ border: "1px solid rgba(89, 89, 89, 0.5)", borderLeft: '5px solid #11545A', background: 'white', borderRadius: '5px' }}>
                    <Grid item xs={4} sm={8} md={3} textAlign="center" alignItems="center" justifyContent="center" style={{ maxHeight: "80px", height: '80px', width: "80px", maxWidth: "80px" }}>
                        {
                            cardValue.logo.length > 2 ? <>
                                <img src={cardValue.logo} alt="faviconLogo" style={{ height: "80%", width: "80%", textAlign: "center", alignItems: "center", justifyContent: "center", marginTop: "12%" }} />
                            </> : <>
                                <Avatar style={{ textAlign: "center", alignItems: "center", justifyContent: "center", backgroundColor: '#11545A', height: "70%", width: "70%", marginTop: "15%", marginRight: "auto", marginLeft: "auto" }}>{cardValue.logo}</Avatar>
                            </>
                        }
                    </Grid>
                    <Grid item xs={4} sm={8} md={9} textAlign="center" alignItems="center" justifyContent="center" minWidth="265px" >
                        <h5 style={{ color: '#444444' }}>{cardValue?.name}</h5>
                    </Grid>
                </Grid>
            </a>
        </Grid>
    )
}

export default WebsiteCard