import MuiButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup'
import styled from 'styled-components'

const StyledButtonGroup = styled(MuiButtonGroup)`
    &.MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
        border-color: ${(props) =>
        props.variant === 'contained' ? 'white' : props.theme.palette.primary.main};
    }
`

export const ButtonGroup = ({ variant, ...props }: ButtonGroupProps) => (
    <StyledButtonGroup variant={variant} {...props}>
        {props.children}
    </StyledButtonGroup>
)

// Example of this component with props:
// Important: when you assign the variant="contained", make sure that the children buttons has the same variant as in the example below
{
    /* <MUIComponents.ButtonGroup variant="contained" aria-label="outlined button group">
                <MUIComponents.Buttons.Button variant="contained">One</MUIComponents.Buttons.Button>
                <MUIComponents.Buttons.Button variant="contained">Two</MUIComponents.Buttons.Button>
                <MUIComponents.Buttons.Button variant="contained">
                    Three
                </MUIComponents.Buttons.Button>
            </MUIComponents.ButtonGroup> */
}

// Example of this component with props:
// Important: when you assign the variant="text", make sure that the children buttons has the same variant as in the example below
{
    /* <MUIComponents.ButtonGroup variant="text" aria-label="outlined button group">
    <MUIComponents.Buttons.Button variant="text">One</MUIComponents.Buttons.Button>
    <MUIComponents.Buttons.Button variant="text">Two</MUIComponents.Buttons.Button>
    <MUIComponents.Buttons.Button variant="text">Three</MUIComponents.Buttons.Button>
</MUIComponents.ButtonGroup> */
}