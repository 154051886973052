import { DesktopDatePicker as MuiDesktopDatePicker, DesktopDatePickerProps as MuiDesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker'
import { noop } from '../../utils/utils'
import { BaseTextFieldProps } from './types'

interface DatepickerProps extends Omit<MuiDesktopDatePickerProps<Date>, 'slotProps'> {
    // you can declare custom props in here
    slotProps?: MuiDesktopDatePickerProps<Date>['slotProps']
    required?: boolean
    color?: BaseTextFieldProps['color']
}

export const DatePicker = ({
    onChange = noop,
    value,
    autoFocus = false,
    slotProps = { textField: { size: "small", autoFocus: autoFocus, color: "primary", InputProps: {}, InputLabelProps: {} } },
    format = 'dd/MM/yyyy',
    required = false,
    ...props
}: DatepickerProps) => (
    <MuiDesktopDatePicker
        label={`${props?.label ?? ''} ${required ? '*' : ''}`}
        onChange={onChange}
        value={value}
        slotProps={slotProps}
        format={format}
        autoFocus={autoFocus}
        {...props}
    />
)

// Example of this component with props:
{
    /* <MUIComponents.DatePicker
                            label="Geboortedatum"
                            onChange={(date) => {
                                if (date) {
                                    setEmployeeData({...employeeData, date_of_birth: Date.parse(date.toDateString()).toString()})
                                }
                            }}
                            value={new Date(Number(employeeData?.date_of_birth ?? new Date().getTime()))}
                            disabled={loading || disabled}
                            required={requiredFields.includes('date_of_birth')}
                        /> */
}